import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    errors: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        position: "relative",
        color: theme.palette.error.main,
    },
}));

export default ({ errors, fields }) => {
    const classes = useStyles();

    let _noField = errors && errors._noField;
    if (_noField && !Array.isArray(_noField)) {
        _noField = [_noField];
    }

    return _noField && Array.isArray(_noField) ? (
        <Box className={classes.errors}>
            {_noField && Array.isArray(_noField)
                ? _noField.map((e, key) => {
                      return <div key={key}>{e}</div>;
                  })
                : null}
        </Box>
    ) : null;
};

import React, { useContext, useEffect, useState } from "react";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Switch from "@material-ui/core/Switch";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import InputAdornment from "@material-ui/core/InputAdornment";
import PasswordField from "lib/components/PasswordField";

import Alert from "lib/components/Alert";
import Dialog from "@material-ui/core/Dialog";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ValueField from "lib/components/ValueField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { CurrencySelector } from "components";
import Checkbox from "@material-ui/core/Checkbox";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import LanguageIcon from "@material-ui/icons/Language";
import LockIcon from "@material-ui/icons/Lock";
import DescriptionIcon from "@material-ui/icons/Description";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import PaymentIcon from "@material-ui/icons/Payment";
import PieChartIcon from "@material-ui/icons/PieChart";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import AdjustIcon from "@material-ui/icons/Adjust";
import PaletteIcon from "@material-ui/icons/Palette";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import BusinessIcon from "@material-ui/icons/Business";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";

import {
    Table,
    TableHeader,
    TableHeaderActions,
    TableActions,
    TableBody,
    TableRow,
    TableCell,
    TableFormCell,
    TablePagination,
    useInMemoryPaging,
} from "lib/components/Table";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import { SettingsContext } from "lib/settings";
import { SecurityContext } from "lib/security";

import { withRouter } from "react-router-dom";

import { formatDate, formatNum, parseNum, formatAmount, dateToString } from "lib/format";
import validator from "lib/validator";
import icons from "icons";
import { MasterDetailRoute, Master, Detail, EmptyDetail, MasterDetailContext } from "lib/components/MasterDetail";
import { EmptyInfoPanel, ErrorInfoPanel } from "lib/components/panels";
import { LayoutInfo, Layout, LayoutHeader, DesktopMenu, MobileMenu, ScrollView } from "lib/components/layout";
import { withLoadingIndicator } from "lib/components/LoadingIndicator";

import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { parseErrors } from "lib/backend";

import { changePassword, filterExpenses, saveExpense, deleteExpense, filterIncomes, saveIncome, deleteIncome } from "./actions";

import {
    Page,
    Section,
    Jumbotron,
    PublicMenu,
    PublicSection,
    Copyright,
    Logo,
    ContactForm,
    Footer,
    FooterCopyright,
    FooterSocialSection,
    FooterSection,
    FooterLinks,
    FooterLink,
    FooterLogo,
} from "lib/components/public";

const outerSpacing = 4;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(4) * 2,
        height: theme.spacing(4) * 2,
    },
}));

const SettingsItem = withRouter(({ history, match, path = "settings", title, description, href, icon }) => {
    const classes = useStyles();
    return (
        <ListItem button={!!href} onClick={!!href ? () => history.push(`${path}${href}`) : null} divider>
            <Box style={{ flex: "0 0 auto" }} p={3}>
                <ListItemAvatar>
                    <Avatar className={classes.large}>{icon}</Avatar>
                </ListItemAvatar>
            </Box>
            <Box style={{ flex: "1 1 auto", overflow: "hidden" }} p={2}>
                <Typography variant="h6" noWrap display="block" gutterBottom={!!description}>
                    {title}
                </Typography>
                {description ? (
                    <Typography variant="subtitle1" display="block" color="textSecondary">
                        {description}
                    </Typography>
                ) : null}
            </Box>
            {href ? (
                <ListItemSecondaryAction>
                    <ChevronRightIcon />
                </ListItemSecondaryAction>
            ) : null}
        </ListItem>
    );
});

const SettingsList = withRouter(({ options, history, ...props }) => {
    const masterDetailCtx = useContext(MasterDetailContext);
    return (
        <React.Fragment>
            <LayoutHeader title="Nastavení" />
            <Divider />
            <Box width={1} height={1} position="relative" display="flex" flexDirection="column">
                <Box flexGrow={1}>
                    <ScrollView vertical>
                        {options.map((row, idx) => (
                            <List disablePadding key={idx}>
                                <ListItem
                                    key={idx}
                                    button
                                    onClick={() => masterDetailCtx.showDetail(row.path)}
                                    divider
                                    style={row.path == masterDetailCtx.detailId ? { backgroundColor: "#FFFFFF" } : null}>
                                    <ListItemAvatar>
                                        <Avatar>{row.icon || (row.name && row.name.charAt(0)) || ""}</Avatar>
                                    </ListItemAvatar>
                                    <Box style={{ flex: "1 1 auto", alignSelf: "", overflow: "hidden" }}>
                                        <Typography noWrap display="block">
                                            {row.name}
                                        </Typography>
                                        <Typography variant="caption" display="block" color="textSecondary">
                                            {row.subtitle}
                                        </Typography>
                                    </Box>
                                </ListItem>
                            </List>
                        ))}
                    </ScrollView>
                </Box>
            </Box>
        </React.Fragment>
    );
});

const SettingsPage = (props) => {
    const masterDetailCtx = useContext(MasterDetailContext);
    const { options, showLoader, hideLoader, isLoading, doSubmit, title, path, children } = props;

    return (
        <React.Fragment>
            <LayoutHeader title={title}>
                <DesktopMenu>
                    <Button variant="contained" onClick={() => masterDetailCtx.showList()} disabled={isLoading}>
                        Zpět
                    </Button>
                    {!!props.doSubmit && (
                        <Button onClick={doSubmit} variant="contained" color="secondary" disabled={isLoading}>
                            Uložit
                        </Button>
                    )}
                </DesktopMenu>
                <MobileMenu>
                    {!!props.doSubmit && (
                        <IconButton onClick={doSubmit}>
                            <SaveIcon />
                        </IconButton>
                    )}
                </MobileMenu>
            </LayoutHeader>
            <Box p={3}>{children}</Box>
        </React.Fragment>
    );
};

const PasswordSettings = withLoadingIndicator((props) => {
    const { enqueueSnackbar } = useSnackbar();
    const { logout } = useContext(SecurityContext);

    const handleSubmit = async ({ oldPassword, newPassword, passwordCheck }) => {
        try {
            props.showLoader();
            await changePassword(oldPassword, newPassword, passwordCheck);
            enqueueSnackbar("Heslo bylo změněno", { variant: "success" });
            props.hideLoader();
            logout();
        } catch (e) {
            props.hideLoader();
            enqueueSnackbar("Heslo nelze změnit", { variant: "error" });
            console.error(e);
        }
    };
    const disabled = props.isLoading,
        readOnly = false;

    return (
        <Detail detailIdPath={props.path}>
            <Formik
                initialValues={{}}
                enableReinitialize={true}
                validate={(values) => {
                    let errors = {};
                    if (!values.oldPassword) {
                        errors.oldPassword = "Povinné pole";
                    }
                    if (!values.newPassword) {
                        errors.newPassword = "Povinné pole";
                    }
                    if (!values.passwordCheck) {
                        errors.passwordCheck = "Povinné pole";
                    }

                    if (values.oldPassword && values.newPassword && values.passwordCheck) {
                        if (values.oldPassword == values.newPassword) {
                            errors.newPassword = errors.oldPassword = "Hesla se shodují";
                        } else if (values.newPassword != values.passwordCheck) {
                            errors.newPassword = errors.passwordCheck = "Hesla se neshodují";
                        }
                    }

                    return errors;
                }}
                validateOnChange={false}
                onSubmit={handleSubmit}>
                {({ submitForm, values, errors, handleChange }) => {
                    return (
                        <SettingsPage title="Heslo" path={props.path} doSubmit={submitForm}>
                            <Grid container alignItems="stretch" spacing={outerSpacing}>
                                <Grid item xs={12} sm={6}>
                                    <Box mb={4}>
                                        <Paper>
                                            <Box p={outerSpacing}>
                                                <PasswordField
                                                    label="Stávající heslo"
                                                    name="oldPassword"
                                                    value={values.oldPassword || ""}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    margin="normal"
                                                    InputProps={{ readOnly }}
                                                    disabled={disabled}
                                                    helperText={errors.oldPassword}
                                                    error={!!errors.oldPassword}
                                                />
                                                <PasswordField
                                                    label="Nové heslo"
                                                    name="newPassword"
                                                    value={values.newPassword || ""}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    type="password"
                                                    margin="normal"
                                                    InputProps={{ readOnly }}
                                                    disabled={disabled}
                                                    helperText={errors.newPassword}
                                                    error={!!errors.newPassword}
                                                />
                                                <PasswordField
                                                    label="Potvrzení nového hesla"
                                                    name="passwordCheck"
                                                    value={values.passwordCheck || ""}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    type="password"
                                                    margin="normal"
                                                    InputProps={{ readOnly }}
                                                    disabled={disabled}
                                                    helperText={errors.passwordCheck}
                                                    error={!!errors.passwordCheck}
                                                />
                                            </Box>
                                        </Paper>
                                    </Box>
                                </Grid>
                            </Grid>
                        </SettingsPage>
                    );
                }}
            </Formik>
        </Detail>
    );
});

/////////////////////////// EXPENSES ///////////////////////////

const ExpenseRow = ({ item, onEdit, onDelete, disabled }) => {
    return (
        <TableRow>
            <TableCell item xs={12} md={4}>
                <Typography display="block">{item.name}</Typography>
                {!!item.description && (
                    <Typography display="block" color="textSecondary">
                        {item.description}
                    </Typography>
                )}
            </TableCell>
            <TableCell item xs={12} md={1} center>
                {item.unit}
            </TableCell>
            <TableCell item xs={12} md={2} right>
                <Typography display="block">
                    {formatAmount(item.unitPrice, "Kč", "right")}
                    {item.unit ? "/" + item.unit : null}
                </Typography>
            </TableCell>

            <TableCell item xs={12} md={3} right>
                {!!item.billable ? "Ano" : "Ne"}
                <Typography display="block" color="textSecondary">
                    {formatAmount(item.billableUnitPrice || "0", "Kč", "right")}
                    {item.unit ? "/" + item.unit : null}
                </Typography>
            </TableCell>

            <TableActions xs={12} md={2}>
                <IconButton onClick={onEdit} disabled={disabled}>
                    <CreateIcon fontSize="small" />
                </IconButton>
                <IconButton onClick={onDelete} disabled={disabled}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </TableActions>
        </TableRow>
    );
};

const ExpenseRowForm = ({ item, onSubmit, onCancel, showProject, showEmployee, disabled, readOnly, showPrice }) => {
    return (
        <TableRow>
            <Formik
                initialValues={
                    item
                        ? {
                              ...item,
                              unitPrice: formatNum(item.unitPrice || 0),
                              billableUnitPrice: formatNum(item.billableUnitPrice || 0),
                          }
                        : {}
                }
                enableReinitialize={true}
                validate={(values) => {
                    let errors = {};

                    if (!values.name) {
                        errors.name = "Povinné pole";
                    }
                    if (!values.unit) {
                        errors.unit = "Povinné pole";
                    }
                    if (!values.unitPrice) {
                        errors.unitPrice = "Povinné pole";
                    } else if (!validator.decimal(values.unitPrice)) {
                        errors.unitPrice = "Chybný formát čísla";
                    }
                    if (values.billable) {
                        if (!values.billableUnitPrice) {
                            errors.billableUnitPrice = "Povinné pole";
                        } else if (!validator.decimal(values.billableUnitPrice)) {
                            errors.billableUnitPrice = "Chybný formát čísla";
                        }
                    }
                    return errors;
                }}
                validateOnChange={false}
                onSubmit={(values, formikBag) => {
                    onSubmit &&
                        onSubmit(
                            {
                                ...values,
                                unitPrice: parseNum(values.unitPrice),
                                billableUnitPrice: parseNum(values.billableUnitPrice),
                            },
                            formikBag
                        );
                }}>
                {({ values, setFieldValue, setValues, errors, handleChange, handleBlur, submitForm }) => {
                    return (
                        <React.Fragment>
                            <TableFormCell item xs={12} md={4}>
                                <TextField
                                    label="Název"
                                    name="name"
                                    value={values.name || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    InputProps={{ readOnly }}
                                    disabled={disabled}
                                    helperText={errors.name}
                                    error={!!errors.name}
                                />
                                <TextField
                                    label="Popis"
                                    name="description"
                                    value={values.description || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    InputProps={{ readOnly }}
                                    disabled={disabled}
                                    helperText={errors.description}
                                    error={!!errors.description}
                                />
                            </TableFormCell>
                            <TableFormCell item xs={12} md={1} center>
                                <TextField
                                    label="Jednotka"
                                    name="unit"
                                    value={values.unit || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    disabled={disabled}
                                    helperText={errors.unit}
                                    error={!!errors.unitt}
                                />
                            </TableFormCell>
                            <TableFormCell xs={12} md={2}>
                                <TextField
                                    label="Jednotková cena"
                                    name="unitPrice"
                                    value={values.unitPrice || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly,
                                        endAdornment: <InputAdornment position="end">Kč{values.unit ? "/" + values.unit : null}</InputAdornment>,
                                        inputProps: { style: { textAlign: "right" } },
                                    }}
                                    disabled={disabled}
                                    helperText={errors.unitPrice}
                                    error={!!errors.unitPrice}
                                />
                            </TableFormCell>
                            <TableFormCell xs={12} md={3} right>
                                <Switch
                                    color="secondary"
                                    checked={values.billable || false}
                                    onChange={handleChange}
                                    name="billable"
                                    value={true}
                                    disabled={disabled}
                                />
                                {!!values.billable && (
                                    <TextField
                                        label="Fakturovaná cena za jednotku"
                                        name="billableUnitPrice"
                                        value={values.billableUnitPrice || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        margin="dense"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">Kč{values.unit ? "/" + values.unit : null}</InputAdornment>,
                                            inputProps: { style: { textAlign: "right" } },
                                        }}
                                        disabled={disabled}
                                        helperText={errors.billableUnitPrice}
                                        error={!!errors.billableUnitPrice}
                                    />
                                )}
                            </TableFormCell>
                            <TableActions item xs={12} md={2}>
                                <IconButton onClick={submitForm} disabled={disabled}>
                                    <CheckIcon fontSize="small" />
                                </IconButton>
                                <IconButton onClick={onCancel} disabled={disabled}>
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            </TableActions>
                        </React.Fragment>
                    );
                }}
            </Formik>
        </TableRow>
    );
};

const ExpenseSettings = withLoadingIndicator(({ showLoader, hideLoader, isLoading, path }) => {
    const [data, setData] = useState({});
    const [formIndex, setFormIndex] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const filterList = async (filter = "", page = 0, size = 10) => {
        try {
            showLoader();
            let data = await filterExpenses(filter, page, size);
            setFormIndex(false);
            setData(data);
        } catch (e) {
            enqueueSnackbar("Nelze načíst data", { variant: "error" });
            setData({});
        } finally {
            hideLoader();
        }
    };

    const handleSubmit = async (values, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        showLoader();
        try {
            values = { ...values, unitPrice: parseNum(values.unitPrice) };
            let c = await saveExpense(values);
            enqueueSnackbar("Uloženo", { variant: "success" });
            filterList("", data.page, data.size);
        } catch (err) {
            setErrors(parseErrors(err));
            enqueueSnackbar("Záznam nelze uložit", { variant: "error" });
        }
        setSubmitting(false);
        hideLoader();
    };

    const deleteItem = (state) =>
        Alert.alert("Smazat šablonu výdajů", `Chcete smazat šablonu výdajů ${state && state.name} ?`, [
            { text: "Ne" },
            {
                text: "Ano",
                onPress: async () => {
                    try {
                        showLoader();
                        await deleteExpense(state.id);
                        filterList("", data.page, data.size);
                        enqueueSnackbar("Smazáno", { variant: "success" });
                    } catch (e) {
                        enqueueSnackbar("Záznam nelze smazat", { variant: "error" });
                    }
                    hideLoader();
                },
            },
        ]);

    const disabled = isLoading,
        readOnly = false;

    useEffect(() => {
        filterList();
    }, []);

    console.log(data);

    return (
        <Detail detailIdPath={path}>
            <SettingsPage title="Šablony výdajů" path={path}>
                <TableHeader container>
                    <TableCell item xs={12} md={4}>
                        Název
                        <Typography color="textSecondary">Popis</Typography>
                    </TableCell>
                    <TableCell item xs={12} md={1} center>
                        Jednotka
                    </TableCell>
                    <TableCell item xs={12} md={2} right>
                        Jednotková cena
                    </TableCell>
                    <TableCell item xs={12} md={3} right>
                        Fakturovat klientovi
                        <Typography color="textSecondary">Fakturovaná cena za jednotku</Typography>
                    </TableCell>
                    <TableHeaderActions item xs={12} md={2}>
                        <IconButton onClick={() => setFormIndex(-1)} disabled={disabled}>
                            <AddIcon fontSize="small" />
                        </IconButton>
                    </TableHeaderActions>
                </TableHeader>
                <TableBody>
                    {formIndex === -1 ? <ExpenseRowForm item={{}} onCancel={() => setFormIndex(false)} onSubmit={handleSubmit} disabled={disabled} /> : null}
                    {data &&
                        Array.isArray(data.content) &&
                        data.content.map((item, key) =>
                            key === formIndex ? (
                                <ExpenseRowForm item={item} key={key} onCancel={() => setFormIndex(false)} onSubmit={handleSubmit} disabled={disabled} />
                            ) : (
                                <ExpenseRow item={item} key={key} onEdit={() => setFormIndex(key)} disabled={disabled} onDelete={() => deleteItem(item)} />
                            )
                        )}
                </TableBody>
                <TablePagination
                    disabled={disabled}
                    pagingInfo={{ pagesCount: data.totalPages, page: data.number, size: data.size }}
                    onChangePage={(page, size) => filterList("", page, size)}
                />
            </SettingsPage>
        </Detail>
    );
});

/////////////////////////// INCOMES ///////////////////////////

const IncomeRow = ({ item, onEdit, onDelete, disabled }) => {
    return (
        <TableRow>
            <TableCell item xs={12} md={7}>
                <Typography display="block">{item.name}</Typography>
                {!!item.description && (
                    <Typography display="block" color="textSecondary">
                        {item.description}
                    </Typography>
                )}
            </TableCell>
            <TableCell item xs={12} md={1} center>
                {item.unit}
            </TableCell>
            <TableCell item xs={12} md={2} right>
                <Typography display="block">
                    {formatAmount(item.unitPrice, "Kč", "right")}
                    {item.unit ? "/" + item.unit : null}
                </Typography>
            </TableCell>

            <TableActions xs={12} md={2}>
                <IconButton onClick={onEdit} disabled={disabled}>
                    <CreateIcon fontSize="small" />
                </IconButton>
                <IconButton onClick={onDelete} disabled={disabled}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </TableActions>
        </TableRow>
    );
};

const IncomeRowForm = ({ item, onSubmit, onCancel, showProject, showEmployee, disabled, readOnly, showPrice }) => {
    return (
        <TableRow>
            <Formik
                initialValues={
                    item
                        ? {
                              ...item,
                              unitPrice: formatNum(item.unitPrice || 0),
                          }
                        : {}
                }
                enableReinitialize={true}
                validate={(values) => {
                    let errors = {};

                    if (!values.name) {
                        errors.name = "Povinné pole";
                    }
                    if (!values.unit) {
                        errors.unit = "Povinné pole";
                    }
                    if (!values.unitPrice) {
                        errors.unitPrice = "Povinné pole";
                    } else if (!validator.decimal(values.unitPrice)) {
                        errors.unitPrice = "Chybný formát čísla";
                    }
                    return errors;
                }}
                validateOnChange={false}
                onSubmit={(values, formikBag) => {
                    onSubmit &&
                        onSubmit(
                            {
                                ...values,
                                unitPrice: parseNum(values.unitPrice),
                            },
                            formikBag
                        );
                }}>
                {({ values, setFieldValue, setValues, errors, handleChange, handleBlur, submitForm }) => {
                    return (
                        <React.Fragment>
                            <TableFormCell item xs={12} md={7}>
                                <TextField
                                    label="Název"
                                    name="name"
                                    value={values.name || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    InputProps={{ readOnly }}
                                    disabled={disabled}
                                    helperText={errors.name}
                                    error={!!errors.name}
                                />
                                <TextField
                                    label="Popis"
                                    name="description"
                                    value={values.description || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    InputProps={{ readOnly }}
                                    disabled={disabled}
                                    helperText={errors.description}
                                    error={!!errors.description}
                                />
                            </TableFormCell>
                            <TableFormCell item xs={12} md={1} center>
                                <TextField
                                    label="Jednotka"
                                    name="unit"
                                    value={values.unit || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    disabled={disabled}
                                    helperText={errors.unit}
                                    error={!!errors.unit}
                                />
                            </TableFormCell>
                            <TableFormCell xs={12} md={2}>
                                <TextField
                                    label="Jednotková cena"
                                    name="unitPrice"
                                    value={values.unitPrice || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly,
                                        endAdornment: <InputAdornment position="end">Kč{values.unit ? "/" + values.unit : null}</InputAdornment>,
                                        inputProps: { style: { textAlign: "right" } },
                                    }}
                                    disabled={disabled}
                                    helperText={errors.unitPrice}
                                    error={!!errors.unitPrice}
                                />
                            </TableFormCell>
                            <TableActions item xs={12} md={2}>
                                <IconButton onClick={submitForm} disabled={disabled}>
                                    <CheckIcon fontSize="small" />
                                </IconButton>
                                <IconButton onClick={onCancel} disabled={disabled}>
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            </TableActions>
                        </React.Fragment>
                    );
                }}
            </Formik>
        </TableRow>
    );
};

const IncomeSettings = withLoadingIndicator(({ showLoader, hideLoader, isLoading, path }) => {
    const [data, setData] = useState({});
    const [formIndex, setFormIndex] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const filterList = async (filter = "", page = 0, size = 10) => {
        try {
            showLoader();
            let data = await filterIncomes(filter, page, size);
            setFormIndex(false);
            setData(data);
        } catch (e) {
            enqueueSnackbar("Nelze načíst data", { variant: "error" });
            setData({});
        } finally {
            hideLoader();
        }
    };

    const handleSubmit = async (values, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        showLoader();
        try {
            values = { ...values, unitPrice: parseNum(values.unitPrice) };
            let c = await saveIncome(values);
            enqueueSnackbar("Uloženo", { variant: "success" });
            filterList("", data.page, data.size);
        } catch (err) {
            setErrors(parseErrors(err));
            enqueueSnackbar("Záznam nelze uložit", { variant: "error" });
        }
        setSubmitting(false);
        hideLoader();
    };

    const deleteItem = (state) =>
        Alert.alert("Smazat šablonu příjmů", `Chcete smazat šablonu příjmů ${state && state.name} ?`, [
            { text: "Ne" },
            {
                text: "Ano",
                onPress: async () => {
                    try {
                        showLoader();
                        await deleteIncome(state.id);
                        filterList("", data.page, data.size);
                        enqueueSnackbar("Smazáno", { variant: "success" });
                    } catch (e) {
                        enqueueSnackbar("Záznam nelze smazat", { variant: "error" });
                    }
                    hideLoader();
                },
            },
        ]);

    const disabled = isLoading,
        readOnly = false;

    useEffect(() => {
        filterList();
    }, []);

    return (
        <Detail detailIdPath={path}>
            <SettingsPage title="Šablony příjmů" path={path}>
                <TableHeader container>
                    <TableCell item xs={12} md={7}>
                        Název
                        <Typography color="textSecondary">Popis</Typography>
                    </TableCell>
                    <TableCell item xs={12} md={1} center>
                        Jednotka
                    </TableCell>
                    <TableCell item xs={12} md={2} right>
                        Jednotková cena
                    </TableCell>
                    <TableHeaderActions item xs={12} md={2}>
                        <IconButton onClick={() => setFormIndex(-1)} disabled={disabled}>
                            <AddIcon fontSize="small" />
                        </IconButton>
                    </TableHeaderActions>
                </TableHeader>
                <TableBody>
                    {formIndex === -1 ? <IncomeRowForm item={{}} onCancel={() => setFormIndex(false)} onSubmit={handleSubmit} disabled={disabled} /> : null}
                    {data &&
                        Array.isArray(data.content) &&
                        data.content.map((item, key) =>
                            key === formIndex ? (
                                <IncomeRowForm item={item} key={key} onCancel={() => setFormIndex(false)} onSubmit={handleSubmit} disabled={disabled} />
                            ) : (
                                <IncomeRow item={item} key={key} onEdit={() => setFormIndex(key)} disabled={disabled} onDelete={() => deleteItem(item)} />
                            )
                        )}
                </TableBody>
                <TablePagination
                    disabled={disabled}
                    pagingInfo={{ pagesCount: data.totalPages, page: data.number, size: data.size }}
                    onChangePage={(page, size) => filterExpenses("", page, size)}
                />
            </SettingsPage>
        </Detail>
    );
});

export default withRouter(({ history, ...props }) => {
    const basePath = "/settings";

    const options = [
        {
            name: "Šablony výdajů",
            path: "expenses",
            icon: <AllInboxIcon />,
        },
        {
            name: "Šablony příjmů",
            path: "income",
            icon: <TrendingUpIcon />,
        },
        {
            name: "Informace o společnosti",
            path: "business",
            icon: <BusinessIcon />,
        },
        {
            name: "Heslo",
            path: "password",
            icon: <LockIcon />,
        },
        {
            name: "Zálohování",
            path: "backup",
            icon: <SettingsBackupRestoreIcon />,
        },
    ];

    return (
        <MasterDetailRoute path={basePath}>
            <Master>
                <SettingsList options={options} />
            </Master>
            <PasswordSettings path="password" />
            <ExpenseSettings path="expenses" />
            <IncomeSettings path="income" />
            <EmptyDetail>
                <EmptyInfoPanel icon={null} text="Vyberte položku nastavení" disabled={props.isLoading} />
            </EmptyDetail>
        </MasterDetailRoute>
    );
});

export const PublicDocRoute = ({ ...props }) => {
    return (
        <Page withMenu>
            <PublicMenu menuItems={[]} actions={[]} fixed />
        </Page>
    );
};

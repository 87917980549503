import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export const AlertContainer = (props) => {
    const [state, setState] = useState(false);

    useEffect(() => {
        global._showAlert = showAlert;
    }, []);

    const showAlert = (title, content, buttons, options) => {
        setState({ title, content, buttons, options });
    };

    const hideAlert = () => {
        setState(false);
    };

    const onButtonPressed = (action) => async () => {
        if (typeof action === "function") {
            try {
                await action();
                hideAlert();
            } catch (e) {
                hideAlert();
                throw e;
            }
        } else {
            hideAlert();
        }
    };

    return (
        <React.Fragment>
            {props.children}

            <Dialog open={!!state} onClose={hideAlert} fullWidth maxWidth="sm" {...(state.options || {})}>
                <DialogTitle id="alert-dialog-title">{state.title || ""}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{state.content}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {!Array.isArray(state.buttons)
                        ? null
                        : state.buttons.map((button, key) => (
                              <Button key={key} type="button" onClick={onButtonPressed(button.onPress)}>
                                  {button.text}
                              </Button>
                          ))}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default {
    alert: (title, content, buttons, opts) => {
        if (typeof global._showAlert !== "function") {
            if (process.env.NODE_ENV !== "production") {
                console.error("Cannot display alert. Chceck whether Alert.Component is included in the layout.");
            }
            return;
        }
        global._showAlert(title, content, buttons, opts);
    },
};

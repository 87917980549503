import React, { useState, useEffect } from "react";

import Modal from "lib/components/Modal";
import QRCode from "react-qr-code";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import step0Image from "./app.png";

//<QRCode value="Hello, World!" />

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue((value) => value + 1); // update the state to force render
}

//contain
const useStep0Styles = makeStyles((theme) => ({
    image: {
        width: "100%",
        height: "100%",
        backgroundImage: `url(${step0Image})`,
        backgroundPosition: "50% 50%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
    },
}));

const Step0 = ({ handleChange }) => {
    const classes = useStep0Styles();
    return (
        <Box p={3}>
            <Grid container spacing={3}>
                <Grid md={6} xs={12} item>
                    <Box pb={2}>
                        <Typography display="block" variant="body1" gutterBottom={true}>
                            Připravili jsme pro vás mobilní aplikaci, jejímž prostřednictvím můžete vykazovat.
                        </Typography>
                    </Box>
                    <Box pb={2}>
                        <Typography display="block" variant="body1" gutterBottom={true}>
                            Aplikace funguje také v offline módu, takže můžete vyplňovat výkazy i tehdy, nemáte-li k dispozici připojení k internetu. Po
                            obnovení spojení se pak data synchronizují
                        </Typography>
                    </Box>
                    <FormControlLabel
                        control={<Checkbox checked={localStorage.getItem("_hide_mobileApp_promo")} onChange={handleChange} name="checkedA" />}
                        label="Nezobrazovat po přihlášení"
                    />
                </Grid>
                <Grid md={6} xs={12} item>
                    <Box className={classes.image} />
                </Grid>
            </Grid>
        </Box>
    );
};

const useStepStyles = makeStyles((theme) => ({
    code: {
        width: "100%",
        height: "100%",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    mobileLinkImage: {
        height: 42,
        width: "auto",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

const Step1 = () => {
    const classes = useStepStyles();
    return (
        <Box p={3}>
            <Grid container spacing={3}>
                <Grid md={6} xs={12} item>
                    <Box pb={2}>
                        <Typography display="block" variant="body1" gutterBottom={true}>
                            Aplikaci si stáhnete prostřednictvím následujícího odkazu a nebo naskenováním QR kódu.
                        </Typography>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.janhrabal.joreport.timetracking"
                            target="_blank"
                            className={classes.mobileLink}>
                            <img className={classes.mobileLinkImage} src={require("./android.png")} />
                        </a>
                    </Box>
                    <Box pb={2}>
                        <Typography display="block" variant="body1" gutterBottom={true}>
                            Po instalaci klikněte na tlačítko "Pokračovat" v tomto průvodci
                        </Typography>
                    </Box>
                </Grid>
                <Grid md={6} xs={12} item>
                    <Box className={classes.code}>
                        <QRCode value="https://play.google.com/store/apps/details?id=com.janhrabal.joreport.timetracking" size="200" />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

const getLoginCode = () => {
    const s = localStorage.getItem("_login_info_");
    if (!s) {
        return;
    }
    const obj = JSON.parse(s);
    if (!obj.token || obj.token.length < 8) {
        return;
    }
    const base64 = obj.token.substr(6, obj.token.length - 2);
    const parts = atob(base64).split(":");
    if (!parts || !parts.length > 1) {
        return;
    }

    return parts;
};

const Step2 = () => {
    const classes = useStepStyles();
    const parts = getLoginCode();

    return (
        <Box p={3}>
            <Grid container spacing={3}>
                <Grid md={6} xs={12} item>
                    <Box pb={2}>
                        <Typography display="block" variant="body1" gutterBottom={true}>
                            Nyní se přihlaste pomocí svého uživatelského jména a hesla...
                        </Typography>
                    </Box>
                    <Box pb={2}>
                        <Typography display="block" variant="body1" gutterBottom={true}>
                            ... a nebo klikněte na tlačítko "Přihlásit se pomocí QR kódu" a naskenujte zde zobrazený QR kód
                        </Typography>
                    </Box>
                </Grid>
                <Grid md={6} xs={12} item>
                    <Box className={classes.code}>{!!parts && <QRCode value={JSON.stringify({ username: parts[0], password: parts[1] })} size="200" />}</Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ({ user, innerRef }) => {
    const [show, setShow] = useState();
    const [step, setStep] = useState(0);
    const forceUpdate = useForceUpdate();

    if (!global.localStorage) {
        return null;
    }

    const onClose = () => {
        setStep(0);
        setShow(false);
    };

    const handleChange = (e) => {
        if (e.target.checked) {
            localStorage.setItem("_hide_mobileApp_promo", "true");
        } else {
            localStorage.removeItem("_hide_mobileApp_promo");
        }
        forceUpdate();
    };

    useEffect(() => {
        let show = false;
        if (user) {
            show = !localStorage.getItem("_hide_mobileApp_promo");
        }
        setShow(show);
    }, [user]);

    useEffect(() => {
        if (innerRef) {
            innerRef.current = { show: () => setShow(true) };
        }
    }, [innerRef]);

    return (
        <Dialog open={show} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Mobilní aplikace</DialogTitle>
            <DialogContent>
                {step === 0 && <Step0 handleChange={handleChange} />}
                {step === 1 && <Step1 />}
                {step === 2 && <Step2 />}
            </DialogContent>
            <DialogActions>
                {step === 0 && (
                    <Button type="button" onClick={onClose}>
                        Zavřít
                    </Button>
                )}
                <Button type="button" variant="contained" onClick={() => setStep(step - 1)} disabled={step < 1}>
                    Zpět
                </Button>
                {step < 2 && (
                    <Button type="button" color="primary" variant="contained" onClick={() => setStep(step + 1)}>
                        Pokračovat
                    </Button>
                )}
                {step == 2 && (
                    <Button type="button" color="primary" variant="contained" onClick={onClose}>
                        Dokončit
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

import React, { useEffect, useState, useContext, useRef } from "react";

import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";

import moment from "moment";
import clsx from "clsx";

const createClasses = makeStyles((theme) => ({
    grid: {
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(7, 1fr)",
        gridAutoRows: "min() 1fr",
        gap: "3px 3px",
        "& > div": {
            width: "100%",
            position: "relative",
        },
    },
    dayName: {
        paddingTop: 0,
        paddingBottom: theme.spacing(1),
        textAlign: "center",
        color: "#888",
        fontWeight: 700,
    },
    padding: {
        background: "none",
    },
    day: {
        backgroundColor: "#F6F6F6",
        paddingTop: "100%",
        cursor: "default",
        "& > div": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            fontWeight: 700,
            color: "#3B3B3B",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
        },
    },
    dayClickable: {
        cursor: "pointer",
    },
}));

//TODO
const dayNames = ["Po", "Út", "St", "Čt", "Pá", "So", "Ne"];

const renderDay = (d, h, key, classes, onClick, editable) => {
    if (h) {
        return (
            <div
                className={clsx(classes.day, !!editable && classes.dayClickable)}
                style={{ color: h.color, backgroundColor: h.bgColor }}
                onClick={() => onClick && onClick(d)}>
                <div style={{ color: h.color }}>{d.day}</div>
            </div>
        );
    }

    return (
        <div key={key} className={clsx(classes.day, !!editable && classes.dayClickable)} onClick={() => onClick && onClick(d)}>
            <div>{d.day}</div>
        </div>
    );
};

export default ({ month = 11, year, values, onClick, editable, tooltips, renderTooltip, ...props }) => {
    const classes = createClasses();
    const [days, setDays] = useState([]);
    const [heatmap, setHeatmap] = useState({});
    const theme = useTheme();

    const baseColor = theme.palette.primary.main;

    //prepare grid
    useEffect(() => {
        let ds = [];
        let m = moment();
        m.year(year);
        m.month(month);
        m = m.startOf("month");

        if (!month) {
            month = m.month();
        }

        //TODO start of week
        if (m.isoWeekday() != 1) {
            m.isoWeekday(1);
            while (m.month() != month) {
                ds.push({ padding: true });
                m = m.add("day", 1);
            }
        }

        while (m.month() == month) {
            ds.push({ key: m.format("YYYY-MM-DD"), day: m.get("date") });
            m = m.add("day", 1);
        }

        setDays(ds);
    }, [month, year]);

    //prepare data
    useEffect(() => {
        if (!values) {
            setHeatmap({});
        }

        let heatmap = {};
        //prepare heatmap
        for (let key in values) {
            let val = values[key];
            let bgColor = val < 0 ? emphasize("#ff8100", 0.75 * Math.abs(val)) : emphasize(theme.palette.secondary.main, 0.75 * (1 - val));
            let color = theme.palette.getContrastText(bgColor);

            heatmap[key] = { val, color, bgColor };
        }
        setHeatmap(heatmap);
    }, [values]);

    return (
        <div className={classes.grid}>
            {dayNames.map((name, key) => (
                <div className={classes.dayName} key={key}>
                    {name}
                </div>
            ))}
            {days.map((d, key) => {
                if (d.padding) {
                    return <div key={key} className={classes.padding} />;
                }

                let h = heatmap[d.key];
                if (tooltips && tooltips[d.key] && renderTooltip) {
                    return (
                        <Tooltip arrow title={renderTooltip(tooltips[d.key], d)} key={key}>
                            {renderDay(d, h, key, classes, onClick, editable)}
                        </Tooltip>
                    );
                }

                return renderDay(d, h, key, classes, onClick, editable);
            })}
        </div>
    );
};

import React, { createContext, PureComponent, useContext } from "react";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import queryString from "query-string";

import backend, { parseErrors } from "lib/backend";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Checkbox, Container, FormControl, FormControlLabel, FormHelperText, Paper, TextField, Typography } from "@material-ui/core";
import ActionButton from "lib/components/ActionButton";
import Errors from "lib/components/Errors";
import OverlayProgress from "lib/components/OverlayProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";

import HomeIcon from "@material-ui/icons/Home";

import { Formik } from "formik";

import messages from "./messages";

export const LOGIN_INFO_KEY = "_login_info_";

// backend integration

const useButtonStyles = makeStyles((theme) => ({
    wrapper: {
        position: "relative",
        display: "inline-block",
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const ProgressButton = ({ loading, ...props }) => {
    const classes = useButtonStyles();

    return (
        <div className={classes.wrapper}>
            <Button {...props} className={props.success ? classes.buttonSuccess : null} disabled={props.disabled || loading}>
                {props.children}
            </Button>
            {loading ? <CircularProgress size={24} className={classes.buttonProgress} /> : null}
        </div>
    );
};

export async function login(username, password) {
    try {
        let response = await backend("/api/auth/login", "POST", {
            body: {
                username,
                password,
            },
        });
        let info = response.body;
        localStorage.setItem(LOGIN_INFO_KEY, JSON.stringify(info));
        return info;
    } catch (e) {
        await logout();
        throw e;
    }
}

export async function logout() {
    localStorage.removeItem(LOGIN_INFO_KEY);
    //TODO
}

export function loginInfo() {
    let s = localStorage.getItem(LOGIN_INFO_KEY);
    if (s == null) {
        return null;
    }

    return JSON.parse(s);
}

export async function userInfo() {
    try {
        let response = await backend("/api/auth/user", "GET", { logoutOn401: false });
        let info = response.body;
        return info;
    } catch (e) {
        console.error(e);
        logout();
    }
    return null;
}

//TODO
export async function signup(email, password, terms, privacy) {
    try {
        let response = await backend("/api/auth/signup", "POST", {
            body: {
                email,
                password,
                terms,
                privacy,
            },
        });
        let info = response.body;
        return info;
    } catch (e) {
        console.error(e);
        logout();
        throw e;
    }
}

//TODO
export async function resetPasswordRequest(email) {
    try {
        let response = await backend("/api/auth/resetRequest", "POST", {
            body: {
                login: email,
                email,
            },
        });
        let info = response.body;
        return info;
    } catch (e) {
        console.error(e);
        logout();
        throw e;
    }
}

//TODO
export async function resetPassword(email, password, token) {
    try {
        let response = await backend("/api/auth/resetPassword", "POST", {
            body: {
                email,
                password,
                token,
            },
        });
        let info = response.body;
        return info;
    } catch (e) {
        console.error(e);
        throw e;
    }
    return null;
}

export async function fetchTerms(locale) {
    return await fetchDocument("terms", locale);
}

export async function fetchPrivacy(locale) {
    return await fetchDocument("privacy", locale);
}

async function fetchDocument(url, locale) {
    let response = await backend(`/auth/${url}`);
    let info = response.body;
    return info;
}

export async function deleteAccount() {
    await backend("/auth/user", "DELETE");
}

export const SecurityContext = createContext({
    user: null,
    login: () => null,
    logout: () => null,
    hasRole: (role) => false,
});

export const withSecurity = (WrappedComponent, securityProps) => (props) => {
    const WithAuth = class extends PureComponent {
        state = {
            user: null,
            userInfoLoading: true,
            loading: false,

            login: this.login,
            logout: this.logout,
            hasRole: this.hasRole,
            updateProfile: this.updateProfile,
        };

        login = async (username, password) => {
            if (securityProps && securityProps.frontendOnly) {
                const user = { ...(securityProps.user || {}), username };
                this.setState({ user, userInfoLoading: false });
                localStorage.setItem("FE_USER", JSON.stringify(user));
                return;
            }

            let { auth } = this.state;
            this.setState({ loading: true });
            try {
                await login(username, password);
                let user = await userInfo();
                this.setState({ user, loading: false });
            } catch (e) {
                this.setState({ user: null, loading: false });
                throw e;
            }
        };

        logout = async () => {
            if (securityProps && securityProps.frontendOnly) {
                this.setState({ user: null, loading: false });
                localStorage.removeItem("FE_USER");
                return;
            }

            let { auth } = this.state;
            this.setState({ loading: true });
            try {
                await logout();
                this.setState({ user: null, loading: false });
            } catch (e) {
                this.setState({ loading: false });
                throw e;
            }
        };

        updateProfile = async (values) => {
            let { user } = this.state;
            this.setState({ user: { ...user, ...values } });
        };

        userInfo = async () => {
            if (securityProps && securityProps.frontendOnly) {
                const s = localStorage.getItem("FE_USER");
                if (s) {
                    this.setState({ user: JSON.parse(s) });
                }
                return;
            }

            let { auth } = this.state;
            this.setState({ userInfoLoading: true });
            try {
                let user = await userInfo();
                this.setState({ user, userInfoLoading: false });
            } catch (e) {
                this.setState({ user: null, userInfoLoading: false });
                throw e;
            }
        };

        hasRole = (roles) => {
            if (!roles || (Array.isArray[roles] && !roles.length)) {
                return true;
            }

            let { user } = this.state;
            if (!user || !user.roles) {
                return false;
            }
            if (Array.isArray(roles)) {
                for (let i = 0; i < roles.length; i++) {
                    if (user.roles[roles[i]]) {
                        return true;
                    }
                }
            } else {
                return !!user.roles[roles];
            }
        };

        componentDidMount() {
            this.setState(
                {
                    user: null,
                    userInfoLoading: false,
                    loading: false,

                    login: this.login,
                    logout: this.logout,
                    hasRole: this.hasRole,
                    updateProfile: this.updateProfile,
                },
                () => {
                    this.userInfo();
                }
            );
        }

        render() {
            let { userInfoLoading } = this.state;

            if (userInfoLoading) {
                return <OverlayProgress show size={80} />;
            }

            return (
                <SecurityContext.Provider value={this.state}>
                    <WrappedComponent {...this.props} security={this.state} />
                </SecurityContext.Provider>
            );
        }
    };

    return <WithAuth {...props} />;
};

const noop = (e) => {
    e.preventDefault();
    e.stopPropagation();
};

const toHome = (history) => (e) => {
    noop(e);
    history.push("/");
};

const toSignup = (history) => (e) => {
    noop(e);
    history.push("/signup");
};

const toLogin = (history) => (e) => {
    noop(e);
    history.push("/login");
};

const toResetPassword = (history) => (e) => {
    noop(e);
    history.push("/resetPassword");
};

const resetPasswordHandler = (login, setErrors, intl, setSubmitting) => async (e) => {
    noop(e);
    try {
        setSubmitting(true);
        await resetPasswordRequest(login);
        let msg = intl.formatMessage(messages.infoPasswordChangeRequested, { login: login });
        setSubmitting(false);
        alert(msg);
    } catch (err) {
        setSubmitting(false);
        setErrors(
            parseErrors(err, (field, code) => {
                return { field, msg: translateError(code, intl) };
            })
        );
    }
};

/* experimental features */

const translateError = (code, intl) => {
    if (code && code.startsWith("AUTH.")) {
        let msg = messages[`error${code.substring(5)}`];
        if (msg) {
            return intl.formatMessage(msg);
        }
    }
    return intl.formatMessage(messages.genericError, { payload: code });
};

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
    },
    title: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    inline: {
        display: "inline",
    },

    form: {
        padding: theme.spacing(2),
    },
    link: {
        textAlign: "center",
        paddingTop: theme.spacing(1),
    },
}));

const LoginForm = (props) => {
    const classes = useStyles();
    const { onSubmit, intl } = props;

    return (
        <Formik
            initialValues={{ login: "", password: "" }}
            validate={(values) => {
                let errors = {};
                if (!values.login) {
                    errors.login = "Povinné pole";
                }
                if (!values.password) {
                    errors.password = "Povinné pole";
                }
                return errors;
            }}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                setSubmitting(true);
                try {
                    await props.onSubmit(values);
                } catch (err) {
                    setErrors(
                        parseErrors(err, ({ field, code }) => {
                            return { field, msg: translateError(code, intl) };
                        })
                    );
                }
                setSubmitting(false);
            }}>
            {({ values, errors, setErrors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setSubmitting }) => (
                <Container maxWidth="xs">
                    <ActionButton onClick={props.onHome} icon={<HomeIcon />} />

                    <Box m={2}>
                        <Paper className={classes.root}>
                            <Box p={3}>
                                <form onSubmit={handleSubmit}>
                                    <Typography variant="h4" className={classes.title}>
                                        {intl.formatMessage(messages.loginTitle)}
                                    </Typography>

                                    <Errors errors={errors} />

                                    <TextField
                                        label={intl.formatMessage(messages.usernamePlaceholder)}
                                        className={classes.textField}
                                        name="login"
                                        value={values.login}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        disabled={isSubmitting}
                                        helperText={errors.login}
                                        error={!!errors.login}
                                    />
                                    <TextField
                                        label={intl.formatMessage(messages.passwordPlaceholder)}
                                        className={classes.textField}
                                        name="password"
                                        type="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        disabled={isSubmitting}
                                        helperText={errors.password}
                                        error={!!errors.password}
                                    />

                                    <Box py={2}>
                                        <ProgressButton
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            loading={isSubmitting}
                                            fullWidth>
                                            {intl.formatMessage(messages.loginAction)}
                                        </ProgressButton>
                                    </Box>

                                    <Box py={1}>
                                        <a href={"#"} onClick={resetPasswordHandler(values.login, setErrors, intl, setSubmitting)} disabled={isSubmitting}>
                                            {intl.formatMessage(messages.forgotPassword)}
                                        </a>
                                    </Box>
                                </form>
                            </Box>
                        </Paper>
                        {global.security_disableSignup ? null : (
                            <Box className={classes.link}>
                                <a href={"#"} onClick={props.onSignup} disabled={isSubmitting}>
                                    {intl.formatMessage(messages.signupAction)}
                                </a>
                            </Box>
                        )}
                    </Box>
                </Container>
            )}
        </Formik>
    );
};

function loginSubmit(login) {
    return async function (authInfo) {
        await login(authInfo.login, authInfo.password);
    };
}

export const LoginPage = injectIntl(
    withRouter(({ intl, history, ...props }) => {
        const { login } = useContext(SecurityContext);

        return (
            <div className="auth-page">
                <LoginForm intl={intl} onSubmit={loginSubmit(login)} onHome={toHome(history)} onSignup={toSignup(history)} resetPassword />
            </div>
        );
    })
);

const SignupForm = (props) => {
    const classes = useStyles();
    const { onSubmit, intl } = props;

    return (
        <Formik
            initialValues={{ email: "", password: "" }}
            validate={(values) => {
                let errors = {};
                if (!values.email) {
                    errors.email = intl.formatMessage(messages.errorBAD_EMAIL);
                }
                if (!values.password) {
                    errors.password = intl.formatMessage(messages.errorBAD_PASSWORD);
                }
                if (!values.terms) {
                    errors.terms = intl.formatMessage(messages.errorTERMS_REQUIRED);
                }
                if (!values.privacy) {
                    errors.privacy = intl.formatMessage(messages.errorPRIVACY_REQUIRED);
                }
                return errors;
            }}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                setSubmitting(true);
                try {
                    await props.onSubmit(values);
                } catch (err) {
                    setErrors(
                        parseErrors(err, ({ field, code }) => {
                            return { field, msg: translateError(code, intl) };
                        })
                    );
                }
                setSubmitting(false);
            }}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setErrors, setSubmitting }) => (
                <Container maxWidth="xs">
                    <ActionButton onClick={props.onHome} icon={<HomeIcon />} />

                    <Box m={2}>
                        <Paper className={classes.root}>
                            <Box p={3}>
                                <form onSubmit={handleSubmit}>
                                    <Typography variant="h4" className={classes.title}>
                                        {intl.formatMessage(messages.signupTitle)}
                                    </Typography>

                                    <Errors errors={errors} />

                                    <TextField
                                        label="Email"
                                        className={classes.textField}
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        disabled={isSubmitting}
                                        helperText={errors.email}
                                        error={!!errors.email}
                                    />
                                    <TextField
                                        label="Password"
                                        className={classes.textField}
                                        name="password"
                                        type="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        disabled={isSubmitting}
                                        helperText={errors.password}
                                        error={!!errors.password}
                                    />

                                    <FormControl fullWidth error={errors && !!errors.terms}>
                                        <FormControlLabel
                                            key="terms"
                                            control={<Checkbox checked={!!values.terms} onChange={handleChange} value={true} name="terms" color="primary" />}
                                            label={intl.formatMessage(messages.agreement, {
                                                subject: (
                                                    <a href="/doc/terms" rel="noopener noreferrer" target="_blank">
                                                        {intl.formatMessage(messages.termsAndConditions)}
                                                    </a>
                                                ),
                                            })}
                                        />
                                        {errors && errors.terms ? <FormHelperText>{errors.terms}</FormHelperText> : null}
                                    </FormControl>

                                    <FormControl fullWidth error={errors && !!errors.privacy}>
                                        <FormControlLabel
                                            key="privacy"
                                            control={
                                                <Checkbox checked={!!values.privacy} onChange={handleChange} value={true} name="privacy" color="primary" />
                                            }
                                            label={intl.formatMessage(messages.agreement, {
                                                subject: (
                                                    <a href="/doc/privacy" rel="noopener noreferrer" target="_blank">
                                                        {intl.formatMessage(messages.privacyPolicy)}
                                                    </a>
                                                ),
                                            })}
                                        />
                                        {errors && errors.privacy ? <FormHelperText>{errors.privacy}</FormHelperText> : null}
                                    </FormControl>

                                    <Box py={2}>
                                        <ProgressButton
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            loading={isSubmitting}
                                            fullWidth>
                                            {intl.formatMessage(messages.signupAction)}
                                        </ProgressButton>
                                    </Box>
                                </form>
                            </Box>
                        </Paper>
                        <Box className={classes.link}>
                            <a href={"#"} onClick={props.onLogin} disabled={isSubmitting}>
                                {intl.formatMessage(messages.alreadyHaveAccount)}
                            </a>
                        </Box>
                    </Box>
                </Container>
            )}
        </Formik>
    );
};

function signupSubmit(login) {
    return async function (authInfo) {
        await signup(authInfo.email, authInfo.password, authInfo.terms, authInfo.privacy);
        await login(authInfo.email, authInfo.password);
    };
}

export const SignupPage = injectIntl(
    withRouter(({ intl, history, ...props }) => {
        const { login } = useContext(SecurityContext);
        return (
            <div className="auth-page">
                <SignupForm intl={intl} onSubmit={signupSubmit(login)} onHome={toHome(history)} onLogin={toLogin(history)} />
            </div>
        );
    })
);

const ResetPasswordForm = (props) => {
    const classes = useStyles();
    const { onSubmit, intl, token } = props;
    const { login } = useContext(SecurityContext);

    return (
        <Formik
            initialValues={{ login: "", password: "" }}
            validate={(values) => {
                let errors = {};
                if (!values.login) {
                    errors.login = intl.formatMessage(messages.errorBAD_USERNAME);
                }
                if (!values.password) {
                    errors.password = intl.formatMessage(messages.errorBAD_PASSWORD);
                }
                return errors;
            }}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                setSubmitting(true);
                try {
                    await resetPassword(values.login, values.password, token);
                    await login(values.login, values.password);
                    //hack
                    window.location = "/";
                } catch (err) {
                    setErrors(
                        parseErrors(err, ({ field, code }) => {
                            return { field, msg: translateError(code, intl) };
                        })
                    );
                }
                setSubmitting(false);
            }}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Container maxWidth="md">
                    <Box m={2}>
                        <Paper>
                            <form onSubmit={handleSubmit}>
                                <Box className={classes.form}>
                                    <Typography variant="h3">{intl.formatMessage(messages.resetPasswordTitle)}</Typography>

                                    <Errors errors={errors} />

                                    <TextField
                                        label="Username"
                                        className={classes.textField}
                                        name="login"
                                        value={values.login}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        disabled={isSubmitting}
                                        helperText={errors.login}
                                        error={!!errors.login}
                                    />
                                    <TextField
                                        label="Password"
                                        className={classes.textField}
                                        name="password"
                                        type="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        disabled={isSubmitting}
                                        helperText={errors.password}
                                        error={!!errors.password}
                                    />
                                </Box>

                                <Button className={classes.button} disabled={isSubmitting} onClick={props.onHome}>
                                    {intl.formatMessage(messages.home)}
                                </Button>
                                <ProgressButton type="submit" variant="contained" color="primary" className={classes.button} loading={isSubmitting}>
                                    {intl.formatMessage(messages.resetPasswordAction)}
                                </ProgressButton>
                            </form>
                        </Paper>
                    </Box>
                </Container>
            )}
        </Formik>
    );
};

export const ResetPasswordPage = injectIntl(
    withRouter(({ intl, history }) => {
        let parsed = queryString.parse(global.location.search);
        let { token } = parsed;

        return (
            <div className="auth-page">
                <ResetPasswordForm intl={intl} token={token} onHome={toHome(history)} />
            </div>
        );
    })
);

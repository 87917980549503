import React, { useRef, useCallback, useEffect } from "react";

import Chip from "@material-ui/core/Chip";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import AddIcon from "@material-ui/icons/Add";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { useDropzone } from "react-dropzone";

import { download } from "lib/backend";
import FileSaver from "file-saver";
import { formatFileSize } from "lib/format";
import { saveListElement, removeListElement } from "lib/list";
import Alert from "lib/components/Alert";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            margin: theme.spacing(1),
        },
        marginBottom: theme.spacing(0.5),
    },
}));

export const AttachmentField = ({ value, onChange, uploadRef, label, url, disabled, readOnly, ...props }) => {
    const classes = useStyles();
    const inputRef = useRef();
    const handleClick = () => inputRef.current && inputRef.current.click();

    const addFile = (acceptedFiles) => {
        saveListElement(value || [], (value && value.length) || 0, acceptedFiles, onChange);
    };

    const deleteFile = (row, index) => {
        Alert.alert("Smazat přílohu", `Chcete smazat přílohu ${row.name} ?`, [
            { text: "Ne" },
            {
                text: "Ano",
                onPress: () => {
                    removeListElement(value, index, onChange);
                },
            },
        ]);
    };

    useEffect(() => {
        if (uploadRef) {
            uploadRef.current = { addFile, deleteFile };
        }
    }, [uploadRef]);

    return (
        <div className={classes.root}>
            <input
                type="file"
                ref={inputRef}
                style={{ display: "none" }}
                onChange={(e) => {
                    addFile(e.target.files[0]);
                    e.target.value = "";
                }}
            />
            {Array.isArray(value) &&
                value.map((row, key) => (
                    <Chip
                        key={row.id || key}
                        icon={<AttachFileIcon />}
                        label={`${row.name} (${formatFileSize(row.size)})`}
                        onClick={downloadAttachment(row, url)}
                        disabled={!!disabled}
                        onDelete={!!readOnly ? null : () => deleteFile(row, key)}
                    />
                ))}
            <Chip icon={<AddIcon />} label="Nahrát soubor" onClick={handleClick} variant="outlined" disabled={!!disabled} />
        </div>
    );
};

export const AttachmentChips = ({ attachments, url }) => {
    const classes = useStyles();
    if (!Array.isArray(attachments)) {
        return null;
    }

    return (
        <div className={classes.root}>
            {attachments.map((row, key) => (
                <Chip
                    key={row.id || key}
                    icon={<AttachFileIcon />}
                    label={`${row.name} (${formatFileSize(row.size)})`}
                    onClick={downloadAttachment(row, url)}
                />
            ))}
        </div>
    );
};

const handleOnDrop = (onDrop, uploadRef) => {
    if (typeof onDrop == "function") {
        return onDrop;
    }

    if (uploadRef) {
        return (files) => {
            uploadRef && uploadRef.current && uploadRef.current.addFile && uploadRef.current.addFile(files);
        };
    }

    return null;
};

export const AttachmentDropzone = ({ onDrop, disabled, children, uploadRef }) => {
    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ onDrop: handleOnDrop(onDrop, uploadRef), noClick: true, noKeyboard: true });
    return (
        <div
            {...getRootProps({
                className: "dropzone",
                style: isDragActive ? { boxShadow: "0px 0px 20px 10px #CECECECC", background: "none" } : { background: "none" },
            })}>
            <input {...getInputProps()} />
            {children}
        </div>
    );
};

//helper methods
const downloadAttachment = (row, url) => () => {
    if (typeof url !== "function") {
        return;
    }

    if (!row.id) {
        const fr = new FileReader();
        fr.readAsDataURL(row);

        const blob = new Blob([row], { type: row.type });
        FileSaver.saveAs(blob, row.name);
    }

    download(url(row));
};

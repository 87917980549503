import React, { useEffect, useState, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import Chip from "@material-ui/core/Chip";

import DeleteIcon from "@material-ui/icons/Delete";
import CommentIcon from "@material-ui/icons/Comment";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionActions from "@material-ui/core/AccordionActions";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "left",
        flexWrap: "wrap",
        "& > *": {
            margin: theme.spacing(0.5),
        },
    },
}));

export default ({ disabled }) => {
    const classes = useStyles();

    return (
        <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-label="Expand" aria-controls="additional-actions1-content" id="additional-actions1-header">
                <Avatar variant="square" color="primary">
                    <CommentIcon />
                </Avatar>
                <Box flexGrow={1} pl={2} alignSelf="center">
                    <Typography variant="h6" color={disabled ? "textSecondary" : "textPrimary"}>
                        Poznámky
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                {/*
                <Box py={2} className={classes.root}>
                    <Chip label="Barbados" onDelete={() => {}} />
                </Box>
            */}
            </AccordionDetails>
            <Divider />
            <AccordionActions>
                <Button size="small" color="primary" disabled={disabled}>
                    Přidat poznánku
                </Button>
            </AccordionActions>
        </Accordion>
    );
};

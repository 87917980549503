import React, { useEffect, useState, useContext, useRef } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import moment from "moment";
import clsx from "clsx";

const monthNames = ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"];

const now = moment().startOf("day");

/*
    const [period, setPeriod] = useState({
        month: now.month(),
        year: now.year(),
    });
    */

const changePeriod = (period, step) => {
    let m = moment().set({ year: period.year, month: period.month, date: 1 });
    m.add("month", step);
    return {
        year: m.year(),
        month: m.month(),
    };
};

const calculatePeriod = (type = "month", date = now) => {
    let m = moment(date).startOf("day");
    let start = m.startOf("month").format("YYYY-MM-DD"),
        end = m.endOf("month").format("YYYY-MM-DD"),
        year = m.year(),
        month = m.month(),
        quarter = m.quarter(),
        week = m.week();

    return {
        start,
        end,
        type,
        year,
        month,
        quarter,
        week,
    };
};

export const nextPeriod = ([period, setPeriod] = []) => {
    let m = moment(period.start).add(period.type || "month", 1);
    setPeriod(calculatePeriod(period.type || "month", m));
};

export const previousPeriod = ([period, setPeriod] = []) => {
    let m = moment(period.start).add(period.type || "month", -1);
    setPeriod(calculatePeriod(period.type || "month", m));
};

//period hook
export const usePeriod = (type, date) => {
    return useState(calculatePeriod(type, date));
};

const createClasses = makeStyles((theme) => ({
    title: {
        textAlign: "center",
        display: "flex",
        justifyContent: "space-between",
    },
}));

export default ({ periodRef, className, disabled, ...props }) => {
    const classes = createClasses();
    const [period, setPeriod] = periodRef || [];

    return (
        <Box className={clsx(classes.title, className)} {...props}>
            <Button variant="contained" onClick={() => previousPeriod(periodRef)} color="secondary" disabled={disabled}>
                Předchozí
            </Button>
            <Typography variant="h6" display="inline">{`${monthNames[period.month]} ${period.year}`}</Typography>
            <Button variant="contained" onClick={() => nextPeriod(periodRef)} color="secondary" disabled={disabled}>
                Následující
            </Button>
        </Box>
    );
};

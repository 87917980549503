//TODO implement & export

function validateRequired(value) {
    return value || value === 0 || value === false;
}

function validateNumber(num, i18n) {
    if (!num) {
        return true;
    }

    num = ("" + num).replace(/\s/, "").replace(/,/, ".");
    return !isNaN(num);
}

function validateDecimal(num, i18n) {
    return validateNumber(num, i18n);
}

const emailRegexp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

function validateEmail(email) {
    return !email || emailRegexp.test(email);
}

const timeRegexp = (str) => /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

function validateTime(time) {
    return !time || /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
}

export default {
    required: validateRequired,
    number: validateNumber,
    decimal: validateDecimal,
    email: validateEmail,
    time: validateTime,
};

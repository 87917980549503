import React, { useEffect, useState, useContext, useRef, useMemo } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import { Container, FormControl, FormControlLabel, FormHelperText, TextField } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import Avatar from "@material-ui/core/Avatar";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";

import ActionButton from "lib/components/ActionButton";
import ValueField from "lib/components/ValueField";
import LinearProgress from "@material-ui/core/LinearProgress";
import { LayoutInfo, Layout, LayoutHeader, DesktopMenu, MobileMenu, ScrollView } from "lib/components/layout";
import { MasterDetailRoute, Master, Detail, EmptyDetail, MasterDetailContext } from "lib/components/MasterDetail";
import { EmptyInfoPanel, ErrorInfoPanel } from "lib/components/panels";

import { Formik } from "formik";

import icons from "../../icons";

import backend, { parseErrors, download, upload } from "lib/backend";

import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";

import MoreVertIcon from "@material-ui/icons/MoreVert";

import TestFilter from "components/TestFilter";
import References from "components/References";
import Divider from "@material-ui/core/Divider";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import InputLabel from "@material-ui/core/InputLabel";
import Collapse from "@material-ui/core/Collapse";

import Badge from "@material-ui/core/Badge";
import Autocomplete from "lib/components/Autocomplete";
import AmountField from "lib/components/AmountField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import { filterClients, fetchClient, saveClient, deleteClient, fetchClientOverview } from "./actions";
import { formatNum, formatCurrency } from "lib/format";
import { SettingsContext } from "lib/settings";
import formatPersonName from "utils/formatPersonName";

import Modal from "lib/components/Modal";
import { CurrencySelector, PaymentTypeSelector } from "components";
import SearchField from "components/SearchField";
import { ContactsForm } from "components/contacts";

import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";

import { withLoadingIndicator, LoadingIndicator } from "lib/components/LoadingIndicator";

import { SecurityContext } from "lib/security";
import { useSnackbar } from "notistack";

import DoughnutChart from "components/DoughnutChart";
import {
    Table,
    TableHeader,
    TableHeaderActions,
    TableActions,
    TableBody,
    TableRow,
    TableCell,
    TableFormCell,
    TablePagination,
    usePaging,
    getPageContent,
} from "lib/components/Table";

import Menu from "components/menu";
import Alert from "lib/components/Alert";
import ExpandFilter from "components/ExpandFilter";
import { getCurrency } from "utils/currency";

const ClientOverview = ({ clientId, showLoader, hideLoader, readOnly, isLoading, ...props }) => {
    const theme = useTheme();
    const { user } = useContext(SecurityContext);
    const currency = getCurrency(user);
    const [overview, setOverview] = useState({});
    const { enqueueSnackbar } = useSnackbar();
    const [activeTab, setActiveTab] = useState(0);
    const switchTab = (event, newValue) => setActiveTab(newValue);

    const [filter, setFilter] = useState("");

    const loadOverview = useMemo(
        () => async () => {
            if (!clientId || clientId == "add") {
                setOverview({});
                return;
            }
            showLoader();
            try {
                setOverview(await fetchClientOverview(clientId, filter));
            } catch (e) {
                enqueueSnackbar("Došlo k chybě při načítání dat", { variant: "error" });
                setOverview({});
            } finally {
                hideLoader();
            }
        },
        [clientId, filter]
    );

    useEffect(() => {
        loadOverview();
    }, [clientId, filter]);

    const paging = usePaging(overview.projects, { size: 10 });

    const incomePaging = usePaging(overview.income, { size: 15 });
    const expensePaging = usePaging(overview.expenses, { size: 15 });

    return (
        <React.Fragment>
            <ExpandFilter onFilterChange={setFilter} />
            <Grid container wrap="wrap-reverse" spacing={3}>
                <Grid item xs={12} md={7}>
                    <Box mb={3}>
                        <Table>
                            <TableHeader>
                                <TableCell xs={8}>Název</TableCell>
                                <TableCell xs={4} right>
                                    Hodnota
                                </TableCell>
                            </TableHeader>
                            <TableBody>
                                {Array.isArray(overview.projects) &&
                                    getPageContent(overview.projects, paging).map((item, key) => (
                                        <TableRow key={key}>
                                            <TableCell xs={8}>{item.name}</TableCell>
                                            <TableCell right xs={4}>
                                                {formatCurrency(item.total, currency)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        <TablePagination pagingInfo={paging} onChangePage={paging.setPage} disabled={isLoading} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                    <DoughnutChart
                        data={[overview.incomeChart, overview.expenseChart]}
                        colors={[theme.palette.primary.main, theme.palette.secondary.main]}
                        disabled={isLoading}>
                        <div>
                            <Typography variant="h6" display="block" color={isLoading ? "textSecondary" : "textPrimary"}>
                                Celkem
                            </Typography>
                            <Typography variant="h5" display="block" color={isLoading ? "textSecondary" : "textPrimary"}>
                                {formatCurrency(overview.totalBalance, currency)}
                            </Typography>
                        </div>
                    </DoughnutChart>
                </Grid>
            </Grid>
            <Box py={3}>
                <Tabs indicatorColor="primary" textColor="primary" value={activeTab} onChange={switchTab} disabled={isLoading}>
                    <Tab label="Podle příjmů" disabled={isLoading} />
                    <Tab label="Podle výdajů" disabled={isLoading} />
                </Tabs>

                <Box py={2}>
                    {activeTab == 0 && (
                        <React.Fragment>
                            <Table>
                                <TableHeader>
                                    <TableCell xs={8}>Název</TableCell>
                                    <TableCell xs={4} right>
                                        Příjmy
                                    </TableCell>
                                </TableHeader>
                                <TableBody>
                                    {Array.isArray(overview.income) &&
                                        getPageContent(overview.income, incomePaging).map((item, key) => (
                                            <TableRow key={key}>
                                                <TableCell xs={8}>{item.name}</TableCell>
                                                <TableCell right xs={4}>
                                                    {formatCurrency(item.value, currency)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                            <TablePagination pagingInfo={incomePaging} onChangePage={incomePaging.setPage} disabled={isLoading} />
                        </React.Fragment>
                    )}
                    {activeTab == 1 && (
                        <React.Fragment>
                            <Table>
                                <TableHeader>
                                    <TableCell xs={8}>Název</TableCell>
                                    <TableCell xs={4} right>
                                        Výdaje
                                    </TableCell>
                                </TableHeader>
                                <TableBody>
                                    {Array.isArray(overview.expenses) &&
                                        getPageContent(overview.expenses, expensePaging).map((item, key) => (
                                            <TableRow key={key}>
                                                <TableCell xs={8}>{item.name}</TableCell>
                                                <TableCell right xs={4}>
                                                    {formatCurrency(item.value, currency)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                            <TablePagination pagingInfo={expensePaging} onChangePage={expensePaging.setPage} disabled={isLoading} />
                        </React.Fragment>
                    )}
                </Box>
            </Box>
        </React.Fragment>
    );
};

const outerSpacing = 4;

export const ClientList = ({ detailId, showDetail, loading, settings, error, filter, onNextPage, data, hasMoreElements, scrollRef, ...props }) => {
    if (error) {
        return (
            <React.Fragment>
                <ErrorInfoPanel title="Chyba" text="Nepodařilo se získat seznam" />
            </React.Fragment>
        );
    }
    if (!loading && !(data && data.length)) {
        return <EmptyInfoPanel text="Seznam je prázdný" disabled={loading} />;
    }

    return (
        <Box width={1} height={1} position="relative" display="flex" flexDirection="column">
            <Box flexGrow={1}>
                <ScrollView vertical loading={loading} onNextElements={onNextPage} hasMoreElements={hasMoreElements} scrollRef={scrollRef}>
                    <List disablePadding>
                        {Array.isArray(data) &&
                            data.map((row, key) => (
                                <ListItem
                                    key={key}
                                    button
                                    onClick={showDetail ? () => showDetail(row.id) : null}
                                    divider
                                    style={row.id == detailId ? { backgroundColor: "#FFFFFF" } : null}>
                                    <ListItemAvatar>
                                        <Avatar>{(row.name && row.name.charAt(0)) || ""}</Avatar>
                                    </ListItemAvatar>
                                    <Box style={{ flex: "1 1 auto", alignSelf: "", overflow: "hidden" }}>
                                        <Typography noWrap display="block">
                                            {row.name}
                                        </Typography>
                                    </Box>
                                </ListItem>
                            ))}
                    </List>
                </ScrollView>
            </Box>
        </Box>
    );
};

export const ClientForm = ({ detailId, readOnly, loading, settings = {}, formik, showLoader, hideLoader, ...props }) => {
    const [activeTab, setActiveTab] = useState(detailId == "add" ? 0 : 1);
    const switchTab = (event, newValue) => setActiveTab(newValue);

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, setValues } = formik;
    const disabled = isSubmitting || loading;

    useEffect(() => {
        setActiveTab(detailId == "add" ? 0 : 1);
    }, [detailId]);

    return (
        <Box px={2}>
            <Box mb={2}>
                <Tabs indicatorColor="primary" textColor="primary" value={activeTab} onChange={switchTab} disabled={disabled}>
                    <Tab label="Vlastnosti" disabled={disabled} />
                    <Tab label="Přehled" disabled={disabled || detailId == "add"} />
                </Tabs>
            </Box>

            <Grid container alignItems="stretch" spacing={outerSpacing}>
                <Grid item lg={9} xs={12}>
                    {/** properties **/}
                    {activeTab == 0 ? (
                        <React.Fragment>
                            <Box mb={4}>
                                <Paper>
                                    <Grid container alignContent="stretch" spacing={outerSpacing}>
                                        <Grid item xs={12} md={6}>
                                            <Box p={outerSpacing}>
                                                <TextField
                                                    label="Název"
                                                    name="name"
                                                    value={values.name || ""}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    margin="normal"
                                                    InputProps={{ readOnly }}
                                                    disabled={disabled}
                                                    helperText={errors.name}
                                                    error={!!errors.name}
                                                />
                                                <Grid container spacing={outerSpacing}>
                                                    <Grid item sm={6} xs={12}>
                                                        <TextField
                                                            label="IČ"
                                                            name="idNumber"
                                                            value={values.idNumber || ""}
                                                            onChange={handleChange}
                                                            fullWidth
                                                            margin="normal"
                                                            InputProps={{ readOnly }}
                                                            disabled={disabled}
                                                            helperText={errors.idNumber}
                                                            error={!!errors.idNumber}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={6} xs={12}>
                                                        <TextField
                                                            label="DIČ"
                                                            name="taxIdNumber"
                                                            value={values.taxIdNumber || ""}
                                                            onChange={handleChange}
                                                            fullWidth
                                                            margin="normal"
                                                            InputProps={{ readOnly }}
                                                            disabled={disabled}
                                                            helperText={errors.taxIdNumber}
                                                            error={!!errors.taxIdNumber}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box p={outerSpacing}>
                                        <Typography variant="h6">Kontakty</Typography>
                                        <ContactsForm
                                            value={values.contacts}
                                            onChange={(val) => setFieldValue("contacts", val)}
                                            disabled={disabled}
                                            readOnly={readOnly}
                                        />
                                    </Box>
                                </Paper>
                            </Box>
                        </React.Fragment>
                    ) : null}
                    {activeTab == 1 && detailId != "add" ? (
                        <ClientOverview clientId={detailId} showLoader={showLoader} hideLoader={hideLoader} isLoading={disabled} readOnly={!!values.readOnly} />
                    ) : null}
                </Grid>

                {/* tools */}
                <Grid item lg={3} xs={12}>
                    <References
                        attachments={values.attachments}
                        onAttachmentChange={(a) => setFieldValue("attachments", a)}
                        detailId={detailId}
                        disabled={disabled}
                        attachmentUrl={detailId == "add" ? null : (row) => `/api/clients/${detailId}/attachments/${row.id}`}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

const ClientListMaster = (props) => {
    const masterDetailCtx = useContext(MasterDetailContext);
    const scrollRef = useRef();
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }
    }, [props.filter]);

    return (
        <Master>
            <LayoutHeader title="Klienti" noRightMargin>
                <IconButton
                    onClick={() => {
                        masterDetailCtx.showNew();
                    }}>
                    <AddIcon />
                </IconButton>
            </LayoutHeader>
            <SearchField onFilterChange={props.onFilterChange} />
            <Divider />
            <LoadingIndicator color="secondary" />
            <ClientList
                loading={props.loading}
                data={props.data}
                hasMoreElements={props.hasMoreElements}
                onNextPage={props.onNextPage}
                showDetail={masterDetailCtx.showDetail}
                scrollRef={scrollRef}
                detailId={masterDetailCtx.detailId}
            />
        </Master>
    );
};

const ClientMasterDetail = withLoadingIndicator(({ settings, showLoader, hideLoader, isLoading, ...props }) => {
    const masterDetailCtx = useContext(MasterDetailContext);
    const [state, setState] = useState(null);
    const [error, setError] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const loadData = async (id) => {
        let item = {};
        try {
            showLoader();
            if (id != "add") {
                item = await fetchClient(id);
            }
            setError(false);
            setState(item);
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            hideLoader();
        }
    };

    const handleSubmit = async (values, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        showLoader();
        try {
            let c = await saveClient(values);
            // variant could be success, error, warning, info, or default
            enqueueSnackbar("Uloženo", { variant: "success" });
            masterDetailCtx.refreshList();
            masterDetailCtx.refreshDetail(c);
        } catch (err) {
            setErrors(parseErrors(err));
            enqueueSnackbar("Záznam nelze uložit", { variant: "error" });
        }
        setSubmitting(false);
        hideLoader();
    };

    const handleBack = () => {
        setState(null);
        setError(null);
        masterDetailCtx.showList();
    };

    useEffect(() => {
        if (masterDetailCtx.detailId) {
            loadData(masterDetailCtx.detailId);
        }
    }, [masterDetailCtx.detailId]);

    if (error) {
        return (
            <Detail>
                {masterDetailCtx.showBoth ? null : <LayoutHeader title="Klienti" noRightMargin></LayoutHeader>}
                <ErrorInfoPanel text="Záznam nelze zobrazit"></ErrorInfoPanel>
            </Detail>
        );
    }

    if (!state && isLoading) {
        return <Detail>{masterDetailCtx.showBoth ? null : <LayoutHeader title="Klienti" noRightMargin></LayoutHeader>}</Detail>;
    }
    const menuItems = [];
    if (masterDetailCtx.detailId != "add") {
        menuItems.push({
            label: "Smazat",
            onClick: () =>
                Alert.alert("Smazat klienta", `Chcete smazat klienta ${state && state.name} ?`, [
                    { text: "Ne" },
                    {
                        text: "Ano",
                        onPress: async () => {
                            try {
                                showLoader();
                                await deleteClient(state.id);
                                handleBack();
                                masterDetailCtx.refreshList();
                                enqueueSnackbar("Smazáno", { variant: "success" });
                            } catch (e) {
                                enqueueSnackbar("Záznam nelze smazat", { variant: "error" });
                            }
                            hideLoader();
                        },
                    },
                ]),
        });
    }

    return (
        <Detail>
            <Formik
                initialValues={state || {}}
                enableReinitialize={true}
                validate={(values) => {
                    let errors = {};
                    /*
                    if (!values.clientId) {
                        errors.clientId = "Povinné pole";
                    }
                    if (!values.secret) {
                        errors.secret = "Povinné pole";
                    }
                    */
                    return errors;
                }}
                validateOnChange={false}
                onSubmit={handleSubmit}>
                {(formik) => {
                    return (
                        <React.Fragment>
                            <LayoutHeader title={`${formik.values.name || "<bez názvu>"}`}>
                                <DesktopMenu>
                                    <Button variant="contained" onClick={handleBack} disabled={formik.isSubmitting || isLoading}>
                                        Zpět
                                    </Button>
                                    <Menu items={menuItems} disabled={formik.isSubmitting || isLoading} />
                                    <Button variant="contained" color="secondary" onClick={formik.submitForm} disabled={formik.isSubmitting || isLoading}>
                                        Uložit
                                    </Button>
                                </DesktopMenu>
                                <MobileMenu>
                                    <Button onClick={formik.submitForm} disabled={formik.isSubmitting || isLoading}>
                                        Uložit
                                    </Button>
                                    <Menu items={menuItems} disabled={formik.isSubmitting || isLoading} />
                                </MobileMenu>
                            </LayoutHeader>
                            <ClientForm
                                formik={formik}
                                data={state}
                                detailId={masterDetailCtx.detailId}
                                settings={settings}
                                loading={isLoading}
                                showLoader={showLoader}
                                hideLoader={hideLoader}
                            />
                        </React.Fragment>
                    );
                }}
            </Formik>
        </Detail>
    );
});

export default injectIntl(
    withLoadingIndicator((props) => {
        const { values: settings } = useContext(SettingsContext);
        const [list, setList] = useState({ data: [], error: null, filter: {} });
        const masterDetailCtx = useContext(MasterDetailContext);

        const fetchItems = async (resetFilter, filter, page, size, sortBy, sort) => {
            props.showLoader();
            try {
                let s = await filterClients(filter, page, size, sortBy, sort);
                let data = resetFilter ? s.content : [...list.data, ...s.content];
                setList({ ...s, data, filter: filter || {} });
            } catch (e) {
                console.error(e);
                setList({
                    error: e,
                });
            }
            props.hideLoader();
        };

        const changeFilter = (filter) => {
            fetchItems(true, { text: filter });
        };

        const fetchNextPage = () => {
            fetchItems(false, list.filter, list.number + 1);
        };

        const refreshList = () => {
            fetchItems(true, { ...list.filter });
        };

        useEffect(() => {
            fetchItems(true);
        }, []);

        return (
            <MasterDetailRoute path={props.path} refreshList={refreshList}>
                <ClientListMaster
                    loading={props.isLoading}
                    settings={settings}
                    onFilterChange={changeFilter}
                    onNextPage={fetchNextPage}
                    hasMoreElements={list.number < list.totalPages - 1}
                    data={list.data}
                    filter={list.filter && list.filter.text}
                />
                <ClientMasterDetail settings={settings} />
                <EmptyDetail>
                    <EmptyInfoPanel icon={icons.accounting} text="Vyberte záznam k zobrazení" disabled={props.isLoading} />
                </EmptyDetail>
            </MasterDetailRoute>
        );
    })
);

import moment from "moment";

import backend, { parseErrors } from "lib/backend";
import { prepareFormData } from "lib/attachments";

export const filterEmployees = async (filter, page = 0, size = 20, sortBy, sort) => {
    try {
        let response = await backend("/api/employees", "GET", {
            params: {
                page,
                size,
                sort,
                sortBy,
                filter: filter ? filter.text : null,
            },
        });
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const fetchEmployee = async (id) => {
    try {
        let response = await backend("/api/employees/" + id);
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const saveEmployee = async (data = {}) => {
    let formData = prepareFormData(data);

    try {
        let response = await backend("/api/employees/" + (data.id || ""), data.id ? "PUT" : "POST", {
            body: formData,
            upload: true,
        });
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const deleteEmployee = async (id) => {
    try {
        await backend("/api/employees/" + id, "DELETE");
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const prepareFormData = (values, { dataKey = "data", filesKey = "file", attachmentsKey = "attachments" } = {}) => {
    const { [attachmentsKey]: ats, ...data } = values;

    let fileIndex = 0;
    let attachments = [];

    let formData = new FormData();

    if (Array.isArray(ats)) {
        for (let i = 0; i < ats.length; i++) {
            let a = ats[i];
            if (!!a.readOnly) {
                continue;
            }

            if (a.id) {
                //info only?
                attachments.push(a);
            } else {
                //file
                formData.append("file", a);
                attachments.push({
                    name: a.name,
                    fileIndex: fileIndex++,
                });
            }
        }
    }

    formData.append(dataKey, JSON.stringify({ ...data, attachments }));

    return formData;
};

import React, { createContext, PureComponent } from "react";

import moment from "moment";
import OverlayProgress from "../components/OverlayProgress";
import { IntlProvider } from "react-intl";

/*
if (!Intl.PluralRules) {
    require('@formatjs/intl-pluralrules/polyfill');
    require('@formatjs/intl-pluralrules/dist/locale-data/cs');
}

if (!Intl.RelativeTimeFormat) {
    require('@formatjs/intl-relativetimeformat/polyfill');
    require('@formatjs/intl-relativetimeformat/dist/locale-data/cs');
}
*/

const space = String.fromCharCode(160);
export const empty = "\u00a0";

const defaultCtx = {
    locale: "en",

    dateFormat: "DD.MM.YYYY",
    timeFormat: "HH:mm",

    thousandDelimiter: space,
    decimalDelimiter: ",",
    decimalDigits: 2,

    currencySymbolLeft: false,
};
global.i18n = defaultCtx;

function formatNum(n, ctx, decimalDigits) {
    //TODO optimize
    if (!ctx) {
        ctx = global.i18n || defaultCtx;
    }

    let c = decimalDigits || isNaN((ctx.decimalDigits = Math.abs(ctx.decimalDigits))) ? 2 : ctx.decimalDigits,
        d = ctx.decimalDelimiter == undefined ? "." : ctx.decimalDelimiter,
        t = ctx.thousandDelimiter == undefined ? "," : ctx.thousandDelimiter,
        s = n < 0 ? "-" : "",
        i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
        j = i.length > 3 ? i.length % 3 : 0;
    return (
        s +
        (j ? i.substr(0, j) + t : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
        (c
            ? d +
              Math.abs(n - i)
                  .toFixed(c)
                  .slice(2)
            : "")
    );
}

export function formatDate(date, ctx) {
    if (!ctx) {
        ctx = global.i18n || defaultCtx;
    }

    if (!date) {
        return null;
    }
    let format = (ctx && ctx.dateFormat) || undefined;
    return moment(date).format(format);
}

export function formatDateTime(date, ctx) {
    if (!ctx) {
        ctx = global.i18n || defaultCtx;
    }

    if (!date) {
        return null;
    }
    let format = (ctx && ctx.dateFormat && ctx.timeFormat ? ctx.dateFormat + " " + ctx.timeFormat : undefined) || undefined;
    return moment(date).format(format);
}

export const formatDateInterval = (from, to) => {
    let s = "",
        delimiter = "";
    if (from) {
        s += formatDate(from);
        delimiter = " - ";
    }
    if (to) {
        s += delimiter;
        s += formatDate(to);
    }
    return s;
};

export function formatNumber(n, ctx, decimalDigits) {
    if (!ctx) {
        ctx = global.i18n || defaultCtx;
    }

    return formatNum(n, ctx, decimalDigits);
}

export function formatMoney(n, currency, ctx, decimalDigits) {
    if (!ctx) {
        ctx = global.i18n || defaultCtx;
    }

    n = formatNum(n, ctx, decimalDigits);
    return ctx.currencySymbolLeft ? currency + space + n : n + space + currency;
}

export function formatPercent(n, ctx, decimalDigits) {
    if (!ctx) {
        ctx = global.i18n || defaultCtx;
    }

    n = formatNum(n, ctx && ctx.number, decimalDigits);
    return n + space + "%";
}

function sp(s) {
    return s == " " ? space : s;
}

export function setI18nContext(ctx) {
    if (ctx) {
        ctx = { ...defaultCtx, ...ctx };
        //normalize
        ctx.decimalDelimiter = sp(ctx.decimalDelimiter);
        ctx.thousandDelimiter = sp(ctx.thousandDelimiter);
    } else {
        ctx = defaultCtx;
    }
    global.i18n = ctx;
}

export function getI18nContext() {
    return global.i18n || defaultCtx;
}

export async function fetchLocale(locale) {
    let data = {};

    if (locale) {
        try {
            let localeMessages = await import(`../../translations/${locale}.js`);
            if (localeMessages && localeMessages.default) {
                data = localeMessages.default;
            }
        } catch (e) {
            console.error(`Cannot load messages for locale "${locale}"`, e);
        }
    }

    return data;
}

export const I18nContext = createContext({
    //TODO
    ctx: global.defaultCtx,
    locale: "cs",
    loading: false,

    changeI18n: () => null,
    changeLocale: () => null,
});

export const withI18n = (WrappedComponent, opts) => (props) => {
    const WithI18n = class extends PureComponent {
        state = {
            ctx: null,
            locale: (opts && opts.locale) || "en",
            initializing: false,
        };

        changeI18n = async (locale) => {
            if (!locale) {
                locale = (opts && opts.locale) || "en";
            }
            if (this.state.locale != locale || !this.state.messages) {
                let messages = await fetchLocale(locale);
                this.setState({ locale: locale, messages, initializing: false });
            }
        };

        componentDidMount() {
            this.setState({ initializing: true });
            this.changeI18n();
        }

        render() {
            let { locale, messages, initializing } = this.state;

            if (initializing) {
                return <OverlayProgress show size={80} />;
            }

            return (
                <I18nContext.Provider value={{ ...this.state, changeI18n: this.changeI18n }}>
                    <IntlProvider locale={locale} messages={messages}>
                        <WrappedComponent {...this.props} />
                    </IntlProvider>
                </I18nContext.Provider>
            );
        }
    };

    return <WithI18n {...props} />;
};

import React from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";
import BlockIcon from "@material-ui/icons/Block";
import ViewListIcon from "@material-ui/icons/ViewList";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";

import { makeStyles, useTheme } from "@material-ui/core/styles";

const makeIconStyle = makeStyles((theme) => ({
    icon: {
        "& > svg": {
            fontSize: 180,
            color: theme.palette.action.disabledBackground,
        },
    },
}));

export const PanelInfo = ({ icon, title, text, actions }) => {
    const classes = makeIconStyle();
    return (
        <Box align="center">
            {icon ? <Box className={classes.icon}>{icon}</Box> : null}
            {title ? <Box fontSize={45}>{title}</Box> : null}
            {text ? <Box py={3}>{text}</Box> : null}
            {Array.isArray(actions) ? (
                <Box py={1}>
                    {actions.map((action, key) => {
                        console.log(action.onClick);
                        return (
                            <Button key={key} variant="contained" color="secondary" startIcon={action.icon || null} type="button" onClick={action.onClick}>
                                {action.label}
                            </Button>
                        );
                    })}{" "}
                </Box>
            ) : null}
        </Box>
    );
};

export const EmptyPanel = (props) => {
    return (
        <Box display="flex" width={1} height={1} color="text.secondary" justifyContent="center" alignItems="center">
            {props.children}
        </Box>
    );
};

export const EmptyInfoPanel = (props) => {
    return (
        <EmptyPanel>
            <PanelInfo icon={<FindInPageIcon />} {...props} />
        </EmptyPanel>
    );
};

export const ErrorInfoPanel = (props) => {
    return (
        <EmptyPanel>
            <PanelInfo icon={<BlockIcon />} {...props} />
        </EmptyPanel>
    );
};
/*


const makeCardStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 0,
        flexShrink: 0,
    },
    content: {
        flexGrow: 1,
        flexShrink: 1,
    },
    actionsContainer: {
        flexGrow: 0,
        flexShrink: 0,
    },
    actions: {
        '& > *': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        marginLeft: theme.spacing(-1),
        marginRight: theme.spacing(-1),
        position: 'relative',
        textAlign: 'right',
    }
}));
export const CardPanel = ({ p, m, children }) => {
    const classes = makeCardStyles();
    return (
        <Paper className={ classes.root }>
            { children }
        </Paper>
    );
}

export const CardPanelTitle = ({ p = 2, icon, variant = "h4", subtitleVariant = "h6", title, subtitle }) => {
    const classes = makeCardStyles();
    return (
        <Box p={ p } className={ classes.title }>
            { !!icon ? (<Box m={ 1 }>
                <Avatar>
                    { icon }
                </Avatar>
            </Box>) : null }
            <Box m={ !!icon ? 1 : 0 } style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={ title }>
                <Typography variant={ variant } noWrap>{ title }</Typography>
                { !!subtitle ? <Typography variant={ subtitleVariant } noWrap color="textSecondary">{ subtitle }</Typography> : null }
            </Box>
        </Box>
    );
}

export const CardPanelContent = ({ p = 3, children, style }) => {
    const classes = makeCardStyles();
    return (
        <Box p={ 3 } pt={ 0 } pb={ 1 } className={ classes.content } style={ style }>
            { children }
        </Box>
    );
}

export const CardPanelActions = ({ p = 3, pt = 2, children }) => {
    const classes = makeCardStyles();
    return (
        <Box className={ classes.actionsContainer }>
            <Divider/>
            <Box p={ p } pt={ pt } className={ classes.actions }>
                { children }
            </Box>
        </Box>
    );
}
*/

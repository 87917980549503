import React, { useEffect, useState, createContext, useContext, useRef } from "react";

import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const noop = () => null;

export const useLoadingIndicator = () => {
    const loadingRef = useRef(0);
    const [loadingCnt, setLoadingCnt] = useState();

    const showLoader = () => {
        loadingRef.current += 1;
        setLoadingCnt(loadingRef.current);
    };

    const hideLoader = () => {
        let cnt = loadingRef.current - 1;
        if (cnt < 0) {
            cnt = 0;
        }
        loadingRef.current = cnt;
        setLoadingCnt(loadingRef.current);
    };

    return {
        showLoader,
        hideLoader,
        isLoading: loadingCnt > 0,
    };
};

const LoadingIndicatorContext = createContext({
    showLoader: noop,
    hideLoader: noop,
    isLoading: false,
});

//export context

const createLoaderClasses = makeStyles((theme) => ({
    root: {
        position: "relative",
        width: "100%",
        zIndex: 2,
    },
    indicator: {
        position: "absolute",
        top: 0,
        width: "100%",
    },
}));

export const LoadingIndicator = ({ color }) => {
    const classes = createLoaderClasses();
    const ctx = useContext(LoadingIndicatorContext);

    if (!ctx || !ctx.isLoading) {
        return null;
    }

    return (
        <div className={classes.root}>
            <LinearProgress color={color || "primary"} className={classes.indicator} />
        </div>
    );
};

export const withLoadingIndicator = (Clazz) => (props) => {
    const loader = useLoadingIndicator();
    return (
        <LoadingIndicatorContext.Provider value={{ ...loader }}>
            <Clazz {...props} {...loader} />
        </LoadingIndicatorContext.Provider>
    );
};

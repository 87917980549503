import React from "react";

import Attachments from "components/Attachments";
import Tags from "components/Tags";
import Notes from "components/Notes";

export default ({ values, attachments, attachmentUrl, attachmentObjectType, notes, readOnly, disabled, onAttachmentChange, onNoteChange }) => {
    return (
        <React.Fragment>
            <Attachments
                disabled={disabled}
                readOnly={readOnly}
                value={attachments}
                onChange={onAttachmentChange}
                url={attachmentUrl}
                objectType={attachmentObjectType}
            />
            {/*<Notes disabled={disabled} readOnly={readOnly} value={notes} onChange={onNoteChange} />*/}
        </React.Fragment>
    );
};

import "whatwg-fetch";
import FileSaver from "file-saver";

function resolveParam(k, value) {
    if (value instanceof Date) {
        value = value.toISOString().slice(0, 10);
    } else if (value === 0) {
        value = "0";
    } else if (value === false) {
        value = "false";
    }
    return encodeURIComponent(k) + "=" + encodeURIComponent(value || "");
}

function prepareQueryParams(params) {
    let query = "";
    if (params) {
        query += "?";

        query += Object.keys(params)
            .filter((k) => k && k.length > 0)
            .map((k) => {
                let value = params[k];
                if (Array.isArray(value)) {
                    return value.map((val) => resolveParam(k, val)).join("&");
                }
                return resolveParam(k, value);
            })
            .join("&");
    }

    return query;
}

const LOGIN_INFO_KEY = "_login_info_";

export default function backendRequest(url, method, options = {}) {
    let {
        params,
        body,
        upload,
        headers,

        onError,
        parseResponse,
        handleResponse,
        onResponseJson,

        logoutOn401 = true, //localStorage && !!localStorage.getItem(LOGIN_INFO_KEY),
    } = options;

    let query = prepareQueryParams(params);

    const httpHeaders = {};
    if (localStorage && localStorage.getItem(LOGIN_INFO_KEY)) {
        let login = JSON.parse(localStorage.getItem(LOGIN_INFO_KEY));
        if (login.header) {
            httpHeaders[login.header] = login.token;
        }
    }

    if (headers) {
        let header;
        if (Array.isArray(headers)) {
            for (let i = 0; i < headers.length; i++) {
                header = headers[i];
                httpHeaders[header.name] = header.value;
            }
        } else if (typeof headers === "object") {
            Object.keys(headers).forEach((key) => {
                console.log(key, headers[key]);
                httpHeaders[key] = headers[key];
            });
        }
    }

    if (!upload && !httpHeaders["Content-Type"] && typeof body === "object" && !(body instanceof FormData)) {
        body = JSON.stringify(body);
        httpHeaders["Content-Type"] = "application/json;charset=UTF-8";
    }

    const opts = {
        method: method || "GET",
        headers: httpHeaders,
        mode: "cors",
        credentials: "same-origin",
        cache: "no-store",
        body,
    };

    return fetch(`${url}${query}`, opts)
        .then(function (res) {
            //handle 401 response
            if (res.status === 401) {
                //this is probably handled by the server side - TODO
                if (logoutOn401) {
                    window.location.reload();
                }
                return;
            } else if (!res.ok) {
                return res.text().then((text) => {
                    let error = {};
                    try {
                        error = JSON.parse(text);
                    } catch (e) {
                        error = { msg: text || res.statusText || e.message, e };
                    }
                    throw error;
                });
            }

            if (typeof handleResponse === "function") {
                return handleResponse(res);
            }

            return res.text().then((text) => {
                //TODO refactor??
                let rheaders = res.headers;

                let headers = {};
                let body = text;

                if (rheaders) {
                    for (let pair of rheaders.entries()) {
                        headers[pair[0]] = pair[1];
                    }
                }

                if (typeof parseResponse === "function") {
                    body = parseResponse(text);
                } else if (body) {
                    body = JSON.parse(body);
                    if (body && typeof onResponseJson === "function") {
                        body = onResponseJson(body);
                    }
                }

                return { headers, body };
            });
        })
        .catch((e) => {
            throw e;
        });
}

export async function download(url, options) {
    if (options && options.params) {
        console.log(options.params);
        url += prepareQueryParams(options.params);
    }

    try {
        let response = await backendRequest(url, "GET", { handleResponse: (r) => r });
        let filename = undefined;
        if (response.headers) {
            let disposition = response.headers.get("content-disposition");
            if (disposition) {
                const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i;
                const matches = regex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }
        }

        let blob = await response.blob();
        FileSaver.saveAs(blob, filename);
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export function upload(url, file, method) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("date", new Date().toISOString().slice(0, 10));

    return backendRequest(url, method || "POST", {
        upload: true,
        body: formData,
    });
}

//TODO improve!!!
export function parseErrors(e, modify) {
    let errors = {
        _map: {
            _noField: [],
        },
        _noField: [],
        _text: "",
    };

    if (!Array.isArray(e)) {
        e = [e];
    }
    errors._raw = e;

    for (let i = 0; i < e.length; i++) {
        let error = e[i];
        if (!error) {
            continue;
        }

        // modify
        let err = error;
        if (modify) {
            error = modify(error) || {};
        }
        if (!error.msg) {
            error.msg = error.code || "Unhandled error";
        }
        error = { ...err, ...error };

        err = errors._map;
        let field = error.field;
        if (error.field && error.field.length) {
            let p = error.field.split(".");
            for (let j = 0; j < p.length; j++) {
                if (!err[p[j]]) {
                    err[p[j]] = {
                        _noField: [],
                        _text: "",
                    };
                }
                err = err[p[j]];
            }
        } else {
            err._noField.push(error);
            err._text += `${error.msg}\n`;
        }

        if (errors[field || "_noField"]) {
            errors[field || "_noField"] += `${error.msg}\n`;
        } else {
            errors[field || "_noField"] = `${error.msg}\n`;
        }
    }

    return errors;
}

export const parsePaged = ({ content, totalPages, empty, size, number } = {}) => {
    if (empty) {
        return {
            content: [],
            pagingInfo: {
                page: 0,
                pagesCount: 0,
                size: 0,
            },
        };
    }

    return {
        content,
        pagingInfo: {
            pagesCount: totalPages,
            page: number,
            size,
        },
    };
};

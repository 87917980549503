import React, { useState, useEffect, useMemo } from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";

import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";

import Autocomplete from "lib/components/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import Grid from "@material-ui/core/Grid";
import CurrencyField from "lib/components/CurrencyField";

import Alert from "lib/components/Alert";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import validator from "lib/validator";

import { Formik } from "formik";

import { saveListElement, removeListElement } from "lib/list";
import { filterEmployeeOptions, getAllEmployeeOptions } from "./actions";

import { Table, TableHeader, TableHeaderActions, TableActions, TableBody, TableRow, TableCell, TableFormCell } from "lib/components/Table";
import { formatDate, formatNum, parseNum, formatCurrency, dateToString } from "lib/format";

const Row = ({ item, odd, onDelete, onEdit, currency }) => {
    return (
        <TableRow>
            <TableCell xs={12} md={6}>
                {item.name}
            </TableCell>
            <TableCell xs={12} md={2} center>
                {item.billable ? "Ano" : "Ne"}
            </TableCell>
            <TableCell xs={12} md={2} right>
                {item.wage ? formatCurrency(item.wage, currency) + "/h" : ""}
                {!!item.billable && (
                    <Typography display="block" color="textSecondary">
                        {formatCurrency(item.billableWage, currency)}/h
                    </Typography>
                )}
            </TableCell>
            <TableActions xs={12} md={2}>
                <IconButton onClick={onEdit}>
                    <CreateIcon fontSize="small" />
                </IconButton>
                <IconButton onClick={onDelete}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </TableActions>
        </TableRow>
    );
};

const RowForm = ({ item, onSubmit, onCancel, odd, disabled, readOnly, currency }) => {
    return (
        <TableRow>
            <Formik
                initialValues={item || {}}
                enableReinitialize={true}
                validate={(values) => {
                    let errors = {};

                    if (!values.id) {
                        errors.employee = "Povinné pole";
                    }

                    if (values.wage && !validator.decimal(values.wage)) {
                        errors.wage = "Zadejte číslo";
                    }
                    if (values.billableWage && !validator.decimal(values.billableWage)) {
                        errors.billableWage = "Zadejte číslo";
                    }

                    return errors;
                }}
                validateOnChange={false}
                onSubmit={(values) => {
                    onSubmit && onSubmit({ ...values, wage: parseNum(values.wage), billableWage: parseNum(values.billableWage) });
                }}>
                {({ values, setFieldValue, setValues, errors, handleChange, handleBlur, submitForm }) => {
                    return (
                        <React.Fragment>
                            <TableFormCell xs={12} md={6}>
                                <Autocomplete
                                    label="Zaměstnanec"
                                    id="employee"
                                    value={values.id ? { ...values } : null}
                                    onChange={(e, v) => {
                                        setValues({ ...v });
                                    }}
                                    error={!!errors.employee}
                                    helperText={errors.employee}
                                    getOptionLabel={(option) => option.name}
                                    fetchOptions={filterEmployeeOptions}
                                    fullWidth
                                    disabled={disabled}
                                    margin="dense"
                                    loadingText="Načítají se hodnoty..."
                                    noOptionsText="Žádné hodnoty"
                                />
                            </TableFormCell>
                            <TableFormCell xs={5} md={2} center>
                                <Switch
                                    color="secondary"
                                    checked={values.billable || false}
                                    onChange={handleChange}
                                    name="billable"
                                    value={true}
                                    disabled={disabled}
                                />
                            </TableFormCell>
                            <TableFormCell xs={5} md={2} right>
                                <CurrencyField
                                    label="Mzda"
                                    name="wage"
                                    value={values.wage || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    unit="h"
                                    currency={currency}
                                    readOnly={readOnly}
                                    disabled={disabled}
                                    helperText={errors.wage}
                                    error={!!errors.wage}
                                />
                                {!!values.billable && (
                                    <CurrencyField
                                        label="Fakturovaná mzda"
                                        name="billableWage"
                                        value={values.billableWage || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        margin="dense"
                                        unit="h"
                                        currency={currency}
                                        readOnly={readOnly}
                                        disabled={disabled}
                                        helperText={errors.billableWage}
                                        error={!!errors.billableWage}
                                    />
                                )}
                            </TableFormCell>
                            <TableActions xs={12} md={2}>
                                <IconButton onClick={submitForm}>
                                    <CheckIcon fontSize="small" />
                                </IconButton>
                                <IconButton onClick={onCancel}>
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            </TableActions>
                        </React.Fragment>
                    );
                }}
            </Formik>
        </TableRow>
    );
};

export const EmployeesForm = ({ value, onChange, disabled, currency, ...props }) => {
    const [formIndex, setFormIndex] = useState(false);

    const submitForm = (values) => {
        saveListElement(value, formIndex, values, onChange);
        setFormIndex(false);
    };

    const addAll = useMemo(
        () => async () => {
            try {
                let all = await getAllEmployeeOptions();
                if (Array.isArray(all) && all.length) {
                    const existing = {};
                    if (Array.isArray(value) && value.length) {
                        for (let i = 0; i < value.length; i++) {
                            existing[value[i].id] = value[i];
                        }
                    }
                    const toAdd = [];
                    for (let i = 0; i < all.length; i++) {
                        let obj = all[i];
                        if (!existing[obj.id]) {
                            toAdd.push(obj);
                        }
                    }

                    if (toAdd.length) {
                        Alert.alert("Přidat zaměstnance", `Chcete přidat všechny zaměstnance?`, [
                            { text: "Ne" },
                            { text: "Ano", onPress: () => onChange([...value, ...toAdd]) },
                        ]);
                    }
                }
            } catch (e) {
                console.error(e);
                Alert.alert("Chyba", "Nelze načíst seznam zaměstnanců", [{ text: "Ok" }]);
            }
        },
        [value]
    );

    useEffect(() => setFormIndex(false), [value]);

    let val = value || [];

    return (
        <Table>
            <TableHeader>
                <TableCell xs={12} md={6}>
                    Zaměstnanec
                </TableCell>
                <TableCell xs={12} md={2} center>
                    Fakturovat klientovi
                </TableCell>
                <TableCell xs={12} md={2} right>
                    Mzda
                </TableCell>
                <TableHeaderActions xs={12} md={2}>
                    <IconButton onClick={() => setFormIndex(val.length)} disabled={disabled} title="Přidat zaměstnance">
                        <AddIcon fontSize="small" />
                    </IconButton>
                    <IconButton onClick={addAll} disabled={disabled} title="Přidat všechny zaměstnance">
                        <PlaylistAddIcon fontSize="small" />
                    </IconButton>
                </TableHeaderActions>
            </TableHeader>
            <TableBody>
                {val.map((item, key) =>
                    key === formIndex ? (
                        <RowForm item={item} key={`form-${key}`} onCancel={() => setFormIndex(false)} onSubmit={submitForm} currency={currency} />
                    ) : (
                        <Row
                            item={item}
                            key={`data-${key}`}
                            onEdit={() => setFormIndex(key)}
                            currency={currency}
                            onDelete={() =>
                                Alert.alert("Odebrat zaměstnance", `Chcete odebrat zaměstnance ${item.name}?`, [
                                    { text: "Ne" },
                                    { text: "Ano", onPress: () => removeListElement(value, key, onChange) },
                                ])
                            }
                        />
                    )
                )}
                {formIndex !== false && formIndex >= val.length ? (
                    <RowForm item={{}} onCancel={() => setFormIndex(false)} onSubmit={submitForm} currency={currency} />
                ) : null}
            </TableBody>
        </Table>
    );
};

import moment from "moment";

//init default??

/*
  https://stackoverflow.com/questions/33159354/how-do-i-find-the-decimal-separator-for-current-locale-in-javascript
*/
/*
if (!global.formatCtx) {
    global.formatCtx = {};
}

const space = String.fromCharCode(160);
export const empty = "\u00a0";

const defaultCtx = {
    locale: "en",

    dateFormat: "DD.MM.YYYY",
    timeFormat: "HH:mm",

    thousandDelimiter: space,
    decimalDelimiter: ",",
    decimalDigits: 2,

    currencySymbolLeft: false
};
global.i18n = defaultCtx;
*/

const space = String.fromCharCode(160);
export const empty = "\u00a0";

export const formatNum = (n, decimalDigits, decimalDelimiter, groupSeparator) => {
    if (isNaN(n)) {
        return "0";
    }

    let c = decimalDigits || 2,
        d = decimalDelimiter || ",",
        t = groupSeparator || space,
        s = n < 0 ? "-" : "",
        i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
        j = i.length > 3 ? i.length % 3 : 0;
    //TODO optimize?
    return (
        s +
        (j ? i.substr(0, j) + t : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
        (c
            ? d +
              Math.abs(n - i)
                  .toFixed(c)
                  .slice(2)
            : "")
    );
};

export const formatCurrency = (n, currency) => {
    if (!currency) {
        return formatAmount(n);
    }

    return formatAmount(n, currency.symbol, currency.symbolPosition, currency.decimalDigits);
};

export const formatAmount = (n, symbol = "", symbolPosition = "left", decimalDigits = 2, decimalDelimiter, groupSeparator) => {
    let s = "";
    if (symbolPosition == "right") {
        return `${formatNum(n || 0, decimalDigits, decimalDelimiter, groupSeparator)}${space}${symbol}`;
    }

    return symbol + space + formatNum(n, decimalDigits, decimalDelimiter, groupSeparator);
};

export const formatDate = (d, format, sourceFormat) => {
    return !d ? "" : moment(d, sourceFormat || "YYYY-MM-DD").format(format || "DD.MM.YYYY");
};

export const dateToString = (v, format) => {
    return v ? moment(v).format(format || "YYYY-MM-DD") : null;
};

export const parseNum = (n) => {
    if (n && isNaN(n) && n.length) {
        return n.replaceAll(",", ".").replaceAll(/\s/g, "");
    }
    return n;
};

export function formatFileSize(fileSizeInBytes) {
    var i = -1;
    var byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 1).toFixed(1) + byteUnits[i];
}

export const parseTime = (v) => {
    return v ? moment(v, "HH:mm") : null;
};

export const formatTime = (v) => {
    return v ? moment(v).format("HH:mm") : null;
};

const timeRegexp = /[^\d]+/g;

export const normalizeTime = (val) => {
    if (!val) {
        return val;
    }

    let s = val.replace(/\s/, "");
    let groups = s.split(timeRegexp);
    if (!groups.length) {
        return val;
    }

    if (isNaN(groups[0])) {
        return val;
    }

    let hours = lpad(groups[0], 2, "0").substring(0, 2);
    let minutes = "00";
    if (groups.length > 1) {
        if (isNaN(groups[1])) {
            return val;
        }
        minutes = lpad(groups[1], 2, "0").substring(0, 2);
    }

    return `${hours}:${minutes}`;
};

function lpad(n, width, z) {
    z = z || "0";
    n = n + "";
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

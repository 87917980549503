import React, { createContext, useEffect, useContext, useState } from "react";

export const SettingsContext = createContext({});

const readSettings = (settings) => {
    if (!settings) {
        settings = {};
    }
    let s = global.localStorage.getItem("settings");
    if (s) {
        s = JSON.parse(s);
        settings = { ...settings, ...s };
    }
    return settings;
};

export const SettingsProvider = ({ settings, local, ...props }) => {
    const [values, setValues] = useState({});

    const load = async (user) => {
        setValues(readSettings(values));
    };

    const save = async (settings) => {
        let vals = { ...values, ...settings };
        global.localStorage.setItem("settings", JSON.stringify(vals));
        setValues(vals);
    };

    useEffect(() => {
        let s = readSettings(settings);
        setValues(s);
    }, [settings]);

    return <SettingsContext.Provider value={{ values, save, load }}>{props.children}</SettingsContext.Provider>;
};

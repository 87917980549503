import moment from "moment";

import backend, { parseErrors, download, upload } from "lib/backend";
import { prepareFormData } from "lib/attachments";

export const fetchProjectIncome = async (projectId, from, to, filter) => {
    try {
        let response = await backend("/api/income/project/" + projectId, "GET", {
            params: { from, to, filter },
        });
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const saveProjectIncome = async (projectId, item) => {
    let formData = prepareFormData(item);

    try {
        let response = await backend("/api/income/v2/project/" + projectId + (item.id ? "/" + item.id : ""), item.id ? "PUT" : "POST", {
            body: formData,
            upload: true,
        });
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const deleteProjectIncome = async (projectId, id) => {
    try {
        await backend("/api/income/project/" + projectId + "/" + id, "DELETE");
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const filterIncomeOptions = async (employeeId, filter) => {
    try {
        let response = await backend("/api/income/templates/options", "GET", {
            params: {
                filter,
            },
        });
        return response.body || [];
    } catch (e) {
        console.log(e);
        throw e;
    }
};

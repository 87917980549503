import backend, { parseErrors } from "lib/backend";

export const changePassword = async (currentPassword, password, passwordCheck) => {
    try {
        let response = await backend("/api/auth/password", "PUT", {
            body: { currentPassword, password, passwordCheck },
        });
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const filterExpenses = async (filter, page = 0, size = 20, sortBy, sort) => {
    try {
        let response = await backend("/api/expenses/templates", "GET", {
            params: {
                page,
                size,
                sort,
                sortBy,
                filter: filter ? filter.text : null,
            },
        });
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const fetchExpense = async (id) => {
    try {
        let response = await backend("/api/expenses/templates/" + id);
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const saveExpense = async (data = {}) => {
    try {
        let response = await backend("/api/expenses/templates/" + (data.id || ""), data.id ? "PUT" : "POST", {
            body: data,
        });
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const deleteExpense = async (id) => {
    try {
        await backend("/api/expenses/templates/" + id, "DELETE");
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const filterIncomes = async (filter, page = 0, size = 20, sortBy, sort) => {
    try {
        let response = await backend("/api/income/templates", "GET", {
            params: {
                page,
                size,
                sort,
                sortBy,
                filter: filter ? filter.text : null,
            },
        });
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const fetchIncome = async (id) => {
    try {
        let response = await backend("/api/income/templates/" + id);
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const saveIncome = async (data = {}) => {
    try {
        let response = await backend("/api/income/templates/" + (data.id || ""), data.id ? "PUT" : "POST", {
            body: data,
        });
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const deleteIncome = async (id) => {
    try {
        await backend("/api/income/templates/" + id, "DELETE");
    } catch (e) {
        console.log(e);
        throw e;
    }
};

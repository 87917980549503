import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

export default ({ children, label, value, my, big, showFor = true }) => {
    return !!showFor ? (
        <Box my={2 || my}>
            <Typography display="block" color="textSecondary" gutterBottom={true} variant="caption">
                {label}
            </Typography>
            {children ? children : <Typography variant={big ? "h5" : "body1"}>{value || "\u00a0"}</Typography>}
        </Box>
    ) : null;
};

import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
    },
    progress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
}));

//TODO shadow
//TODO add error reporting

export default ({ show, size = 40 }) => {
    const classes = useStyles();

    if (!show) {
        return null;
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.progress}>
                <CircularProgress size={size || 40} />
            </div>
        </div>
    );
};

export const OverlayProgressContainer = ({ children }) => <div style={{ position: "relative" }}>{children}</div>;

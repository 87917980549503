import React, { useEffect, useState, useContext, useRef, useCallback } from "react";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import AccordionActions from "@material-ui/core/AccordionActions";

import Alert from "lib/components/Alert";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";

import { useDropzone } from "react-dropzone";
import { formatFileSize } from "lib/format";
import { saveListElement, removeListElement } from "lib/list";

import { download } from "lib/backend";
import FileSaver from "file-saver";

import { usePaging, getPageContent, TablePagination } from "lib/components/Table";

const noop = (e) => {
    e.preventDefault();
    e.stopPropagation();
};

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        minHeight: theme.spacing(8),
        width: "100%",
    },
    avatar: {
        //backgroundColor: theme.palette.primary.main
    },
    overlay: {
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundColor: "#FAFAFADD",
        margin: 0,
        border: "dashed 1px #ABABAB",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    dropzone: {
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        zIndex: 10,
    },
    dropzoneActive: {
        boxShadow: "inset 0px 0px 30px -10px #CECECECC",
        zIndex: 100,
        position: "relative",
    },
    input: {
        display: "none",
    },
}));

export default ({ value = [], onChange, disabled, url, objectType }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const onDrop = useCallback(
        (acceptedFiles) => {
            saveListElement(value, value.length || 0, acceptedFiles, onChange);
        },
        [value]
    );
    const onDelete = useCallback(
        (row, index) => {
            Alert.alert("Smazat přílohu", `Chcete smazat přílohu ${row.name} ?`, [
                { text: "Ne" },
                {
                    text: "Ano",
                    onPress: () => {
                        removeListElement(value, index, onChange);
                    },
                },
            ]);
        },
        [value]
    );

    const onEdit = () => {};
    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ onDrop, noClick: true, noKeyboard: true });

    const downloadAttachment = (row) => () => {
        if (typeof url !== "function") {
            return;
        }

        if (!row.id) {
            const fr = new FileReader();
            fr.readAsDataURL(row);

            const blob = new Blob([row], { type: row.type });
            FileSaver.saveAs(blob, row.name);
        }

        download(url(row));
    };

    const paging = usePaging(value, { size: 5 });
    useEffect(() => {
        if (!expanded && isDragActive) {
            setExpanded(true);
        }
    }, [isDragActive]);

    return (
        <div {...getRootProps({ className: "dropzone", style: isDragActive ? { boxShadow: "0px 0px 20px 10px #CECECECC" } : null })}>
            <input {...getInputProps()} />
            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded} onChange={(e, expanded) => setExpanded(expanded)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Avatar variant="square" className={classes.avatar}>
                        <AttachFileIcon size="small" />
                    </Avatar>
                    <Box flexGrow={1} pl={2} alignSelf={"center"}>
                        <Typography variant="h6" color={disabled ? "textSecondary" : "textPrimary"}>
                            Přílohy
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Box className={classes.root}>
                        <List dense>
                            {getPageContent(value, paging).map((row, key) => (
                                <ListItem button key={row.id || row.fileId || key} onClick={downloadAttachment(row)} disabled={disabled}>
                                    <ListItemText primary={row.name} secondary={`${formatFileSize(row.size)}`} />
                                    <ListItemSecondaryAction>
                                        {/*<IconButton edge="end" onClick={() => onEdit(row, key)}>
                                        <EditIcon />
                                    </IconButton>*/}
                                        {(!objectType || !row.objectType || objectType == row.objectType) && !row.readOnly && (
                                            <IconButton edge="end" onClick={() => onDelete(row, key)} disabled={disabled}>
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </AccordionDetails>
                <Divider />
                <AccordionActions>
                    <Button variant="text" color="primary" component="span" size="small" startIcon={<CloudUploadIcon />} onClick={open} disabled={disabled}>
                        Nahrát soubor
                    </Button>
                </AccordionActions>
            </Accordion>
        </div>
    );
};

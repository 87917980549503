import React, { useState, useEffect, useMemo } from "react";

import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import Pagination from "@material-ui/lab/Pagination";

/*
const createClasses = makeStyles((theme) => ({
    title: {
        textAlign: "center",
        display: "flex",
        justifyContent: "space-between",
    },
    header: {
        backgroundColor: "#EEEEEE",
        alignItems: "center",
        "& .MuiGrid-item": {
            fontWeight: "bolder",
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            color: "#3B3B3B",
        },
    },
    headerActions: {
        textAlign: "center",
        padding: "0px !important",
    },
    dataEven: {
        backgroundColor: "#FCFCFC",
        alignItems: "center",
        "& .MuiGrid-item": {
            padding: theme.spacing(1),
            color: "#3B3B3B",
        },
        "& .MuiInputBase-root": {
            backgroundColor: "#FFFFFF",
        },
    },
    dataOdd: {
        backgroundColor: "#F6F6F6",
        alignItems: "center",
        "& .MuiGrid-item": {
            padding: theme.spacing(1),
            color: "#3B3B3B",
        },
        "& .MuiInputBase-root": {
            backgroundColor: "#FFFFFF",
        },
    },

    actions: {
        padding: 0,
        textAlign: "center",
    },
}));


                <Grid container className={classes.header}>
                    <Grid item xs={12} md={2}>
                        Datum
                    </Grid>
                    { showProject && <Grid item xs={12} md={2}>
                        Projekt
                    </Grid> }
                    { showEmployee && <Grid item xs={12} md={2}>
                        Zaměstnanec
                    </Grid> }
                    <Grid item xs={12} md={4}>
                        Činnost
                    </Grid>
                    <Grid item xs={12} md={2} className={classes.right}>
                        Počet hodin
                    </Grid>
                    <Grid item xs={12} md={2} className={classes.headerActions}>
                        <IconButton onClick={() => setFormIndex(data.items.length)}>
                            <AddIcon fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
*/

/*
        <Grid container className={odd ? classes.dataOdd : classes.dataEven}>
            <Grid item xs={12} md={2}>
                {formatDate(item.date)}
            </Grid>
            { !!showProject && <Grid item xs={12} md={2}>
                {item.projectName}
            </Grid> }
            { !!showEmployee && <Grid item xs={12} md={2}>
                {item.employeeName}
            </Grid> }
            <Grid item xs={12} md={4}>
                {item.description}
            </Grid>
            <Grid item xs={12} md={2} className={classes.right}>
                {(item.hours && formatNum(item.hours)) || "0"} h
            </Grid>
            <Grid item xs={12} md={2} className={classes.actions}>
                <IconButton onClick={onEdit}>
                    <CreateIcon fontSize="small" />
                </IconButton>
                <IconButton onClick={onDelete}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Grid>
        </Grid>
*/

/*
 */

export const Table = ({ className, style, children }) => {
    return (
        <div className={className} style={style}>
            {children}
        </div>
    );
};

const createHeaderClasses = makeStyles((theme) => ({
    header: {
        backgroundColor: "#EEEEEE",
        alignItems: "center",
        "& .MuiGrid-item": {
            fontWeight: "bolder",
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            color: "#3B3B3B",
        },
    },
}));

export const TableHeader = ({ className, style, children, ...props }) => {
    const classes = createHeaderClasses();
    return (
        <Grid container className={clsx(classes.header, className)} style={style} {...props}>
            {children}
        </Grid>
    );
};

const createHeaderActionsClasses = makeStyles((theme) => ({
    headerActions: {
        textAlign: "center",
        padding: "0px !important",
    },
}));

export const TableHeaderActions = ({ className, style, children, ...props }) => {
    const classes = createHeaderActionsClasses();
    return (
        <Grid item className={clsx(classes.headerActions, className)} style={style} {...props}>
            {children}
        </Grid>
    );
};

const createActionsClasses = makeStyles((theme) => ({
    actions: {
        textAlign: "center",
        alignSelf: "flex-start",
    },
}));

export const TableActions = ({ className, style, children, ...props }) => {
    const classes = createActionsClasses();
    return (
        <Grid item className={clsx(classes.actions, className)} style={style} {...props}>
            {children}
        </Grid>
    );
};

const createTableClasses = makeStyles((theme) => ({
    root: {
        "& > .MuiGrid-container:nth-child(2n+1)": {
            backgroundColor: "#FCFCFC",
            alignItems: "center",
            "& > .MuiGrid-item": {
                padding: theme.spacing(1),
                color: "#3B3B3B",
            },
            "& .MuiInputBase-root": {
                backgroundColor: "#FFFFFF",
            },
        },
        "& > .MuiGrid-container:nth-child(2n)": {
            backgroundColor: "#F6F6F6",
            alignItems: "center",
            "& > .MuiGrid-item": {
                padding: theme.spacing(1),
                color: "#3B3B3B",
            },
            "& .MuiInputBase-root": {
                backgroundColor: "#FFFFFF",
            },
        },
    },
}));

export const TableBody = ({ className, style, children, ...props }) => {
    const classes = createTableClasses();
    return (
        <div className={clsx(classes.root, className)} style={style}>
            {children}
        </div>
    );
};

export const TableRow = ({ className, style, children, ...props }) => {
    return (
        <Grid container className={className} style={style} {...props}>
            {children}
        </Grid>
    );
};

const createCellClasses = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    left: {
        textAlign: "left",
    },
    right: {
        textAlign: "right",
    },
    center: {
        textAlign: "center",
    },
}));

export const TableCell = ({ className, style, children, left, right, center, ...props }) => {
    const classes = createCellClasses();
    return (
        <Grid item className={clsx(className, { [classes.left]: !!left, [classes.right]: !!right, [classes.center]: !!center })} style={style} {...props}>
            {children}
        </Grid>
    );
};

const createFormClasses = makeStyles((theme) => ({
    root: {
        alignSelf: "flex-start",
    },
    left: {
        textAlign: "left",
    },
    right: {
        textAlign: "right",
    },
    center: {
        textAlign: "center",
    },
}));

export const TableFormCell = ({ className, style, children, left, right, center, ...props }) => {
    const classes = createFormClasses();
    return (
        <Grid
            item
            className={clsx(classes.root, className, { [classes.left]: !!left, [classes.right]: !!right, [classes.center]: !!center })}
            style={style}
            {...props}>
            {children}
        </Grid>
    );
};

/*
https://stackoverflow.com/questions/44936917/table-striping-rows-in-css-grid
.grid-table {
  display: grid;
  grid-template-columns: repeat(3, auto);
}

.grid-table > span:nth-child(-n+3) {
  background-color: red;
}

.grid-table > span:nth-child(6n+4),
.grid-table > span:nth-child(6n+5),
.grid-table > span:nth-child(6n+6) {
  background-color: lightgreen;
}

*/

const createGridTableClasses = makeStyles((theme) => ({
    root: {
        "& > div:nth-child(n+3)": {
            backgroundColor: "#F6F6F6",
            color: "#3B3B3B",
        },
        "& > div:nth-child(6n+4), & > div:nth-child(6n+5), & > div:nth-child(6n+6)": {
            backgroundColor: "#FCFCFC",
            color: "#3B3B3B",
        },
        alignItems: "stretch",
    },
}));
/*
const useStyles = makeStyles(theme => ({
  root: props => ({
    backgroundColor: props.backgroundColor,
    color: theme.color,
  }),
}))
*/

export const GridTable = ({ className, columns, rowTemplate, children }) => {
    const classes = createGridTableClasses();
    return (
        <div className={clsx(classes.root, className)} style={{ display: "grid", gridTemplateColumns: "fit-content(3fr) fit-content(1fr) fit-content(1fr)" }}>
            {children}
        </div>
    );
};

const createGridHeaderClasses = makeStyles((theme) => ({
    header: {
        backgroundColor: "#EEEEEE !important",
        alignItems: "center",
        fontWeight: "bolder",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        color: "#3B3B3B",
    },
    left: {
        textAlign: "left",
    },
    right: {
        textAlign: "right",
    },
    center: {
        textAlign: "center",
    },
}));

export const GridTableHeaderCell = ({ className, left, right, center, children, style, ...props }) => {
    const classes = createGridHeaderClasses();
    return (
        <div className={clsx(classes.header, className, { [classes.left]: !!left, [classes.right]: !!right, [classes.center]: !!center })} style={style}>
            {children}
        </div>
    );
};

export const GridTableRow = ({ className, children, ...props }) => {
    return <React.Fragment>{children}</React.Fragment>;
};

export const GridTableCell = ({ className, left, right, center, children, style }) => {
    const classes = createCellClasses();
    return (
        <div
            style={{ height: "100%" }}
            className={clsx(classes.root, className, { [classes.left]: !!left, [classes.right]: !!right, [classes.center]: !!center })}
            style={style}>
            {children}
        </div>
    );
};

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textAlign: "right",
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    },
}));

export const TablePagination = ({ pagingInfo, disabled, onChangePage, ...props }) => {
    const classes = usePaginationStyles();
    const { pagesCount = 0, page = 0, size = 10 } = pagingInfo || {};

    if (pagesCount < 2) {
        return null;
    }

    return (
        <Box className={classes.root}>
            <Pagination
                disabled={disabled}
                count={pagesCount}
                page={page + 1}
                siblingCount={0}
                boundaryCount={1}
                onChange={(e, page) => onChangePage && onChangePage(page - 1, size)}
            />
        </Box>
    );
};

export const getPageContent = (items, pagingInfo) => {
    if (!Array.isArray(items)) {
        return [];
    }
    if (!pagingInfo || !pagingInfo.size || pagingInfo.size < 1 || isNaN(pagingInfo.page) || pagingInfo.page < 0) {
        return items;
    }

    let start = pagingInfo.page * pagingInfo.size;
    let end = Math.min((1 + pagingInfo.page) * pagingInfo.size, items.length);

    return items.slice(start, end);
};

export const getPagingInfo = (items, page, { size = 10 } = {}) => {
    let pagesCount = 0;
    if (Array.isArray(items)) {
        if (size < 1) {
            size = 1;
        }

        pagesCount = Math.ceil(items.length / size);
    }

    if (page > pagesCount) {
        page = pagesCount;
    } else if (page < 0) {
        page = 0;
    }

    return {
        pagesCount,
        page,
        size,
    };
};

export const usePaging = (items, { size = 10 } = {}) => {
    const [page, setPage] = useState(0);
    const [_size, setsize] = useState(size);

    return useMemo(() => {
        const pagingInfo = getPagingInfo(items, page, { size: _size });

        return {
            ...pagingInfo,
            setPage,
        };
    }, [items, _size, page]);
};

import { useState } from "react";

export const useList = () => {};

export const removeListElement = (list, index, onChange) => {
    if (!Array.isArray(list) || index < 0 || index >= list.length) {
        typeof onChange === "function" && onChange(list, item);
        return;
    }

    const item = list[index];
    list = [...list];
    list.splice(index, 1);

    typeof onChange === "function" && onChange(list, item);
};

export const saveListElement = (list, index, item, onChange) => {
    if (Array.isArray(list)) {
        list = [...list];
    } else {
        list = [];
    }

    if (Array.isArray(item)) {
        for (let i = 0; i < item.length; i++) {
            add(list, item[i], index + i);
        }
    } else {
        add(list, item, index);
    }

    typeof onChange === "function" && onChange(list, item);
};

const add = (list, item, index) => {
    if (index < 0) {
        list.unshift(item);
    } else if (index < list.length) {
        list[index] = item;
    } else {
        list.push(item);
    }
};

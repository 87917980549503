import React, { useEffect, useState, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Chip from "@material-ui/core/Chip";

import DeleteIcon from "@material-ui/icons/Delete";
import LabelIcon from "@material-ui/icons/Label";

import AccordionActions from "@material-ui/core/AccordionActions";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "left",
        flexWrap: "wrap",
        "& > *": {
            margin: theme.spacing(0.5),
        },
    },
}));

export default () => {
    const classes = useStyles();

    return (
        <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-label="Expand" aria-controls="additional-actions1-content" id="additional-actions1-header">
                <Avatar variant="square" color="primary">
                    <LabelIcon />
                </Avatar>
                <Box flexGrow={1} pl={2}>
                    <Typography variant="h6">Štítky</Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box px={2} className={classes.root}>
                    <Chip label="Barbados" onDelete={() => {}} />
                    <Chip label="Barbados" onDelete={() => {}} />
                    <Chip label="Barbados" onDelete={() => {}} />
                    <Chip label="Barbados" onDelete={() => {}} />
                </Box>
            </AccordionDetails>
            <Divider />
            <AccordionActions>
                <Button size="small" color="primary">
                    Přidat
                </Button>
            </AccordionActions>
        </Accordion>
    );
};

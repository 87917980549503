import React, { useContext, useRef } from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { LoginPage, ResetPasswordPage, SecurityContext, SignupPage, withSecurity } from "lib/security";
//import { SettingsContext, SettingsProvider, withSetings } from "lib/settings";
import { AlertContainer } from "lib/components/Alert";

import { withI18n } from "lib/i18n";

import theme from "./theme";
import withTheme from "lib/theme";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/cs";

// pages
import PublicPage, { PublicLayout } from "public/PublicPage";
import { createAgreementPage } from "lib/components/public";

import Home from "private/Home";
import EmployeesMasterDetailRoute from "private/employees";
import ClientsMasterDetailRoute from "private/clients";
import ProjectsMasterDetailRoute from "private/projects";
import ReportsMasterDetailRoute from "private/reports";
import SettingsMasterDetailRoute from "private/settings";
import TimesheetPage from "private/timesheets";
import { ExpensesPage } from "private/expenses";
import IncomeMasterDetailRoute from "private/income";

import { Menu } from "lib/components/layout";
import HomeRedirect from "lib/components/HomeRedirect";
import DocMasterDetailRoute from "public/doc";

//styles
import "./index.scss";

/* icons */
import icons from "./icons";
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import BuildIcon from "@material-ui/icons/Build";
import WorkIcon from "@material-ui/icons/Work";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import InboxIcon from "@material-ui/icons/Inbox";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import BarChartIcon from "@material-ui/icons/BarChart";
import SettingsIcon from "@material-ui/icons/Settings";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";

import { SnackbarProvider } from "notistack";
import Banner from "./private/banner";

moment.locale("cs");

const DocRoute = ({ path = "/doc", exact = false }) => {
    return (
        <Route path={path} exact={exact}>
            <Route path="*" render={() => <div>yyy</div>} />
        </Route>
    );
};

const PublicRoutes = () => {
    return (
        <Switch>
            <Route path="/" exact component={PublicPage} />
            <Route path="/signup" exact component={SignupPage} />
            <Route path="/resetPassword" exact component={ResetPasswordPage} />

            <Route
                path="/doc"
                render={(props) => (
                    <PublicLayout>
                        <DocRoute path="/" />
                    </PublicLayout>
                )}
            />

            <Route path="*" component={LoginPage} />
        </Switch>
    );
};

const PrivateRoutes = () => {
    const { user, logout } = useContext(SecurityContext);
    const bannerRef = useRef();
    //const { values: settings } = useContext(SettingsContext);

    const menuItems = [];

    if (user.owner) {
        menuItems.push({
            id: "home",
            pathname: "/",
            label: "Domů",
            icon: <HomeIcon />,
            route: <Route path="/" exact component={Home} key="/" />,
        });

        menuItems.push({
            id: "projects",
            pathname: "/projects",
            label: "Projekty",
            icon: <AccountTreeIcon />,
            route: <ProjectsMasterDetailRoute path="/projects" key="/projects" />,
        });
        menuItems.push({
            id: "clients",
            pathname: "/clients",
            label: "Klienti",
            icon: <WorkIcon />,
            route: <ClientsMasterDetailRoute path="/clients" key="/clients" />,
        });
        menuItems.push({
            id: "employees",
            pathname: "/employees",
            label: "Zaměstnanci",
            icon: <PersonIcon />,
            route: <EmployeesMasterDetailRoute path="/employees" key="/employees" />,
        });
        menuItems.push({
            id: "reports",
            pathname: "/reports",
            label: "Přehledy",
            icon: <BarChartIcon />,
            route: <ReportsMasterDetailRoute path="/reports" key="/reports" />,
            divider: true,
        });
        menuItems.push({
            id: "settings",
            pathname: "/settings",
            label: "Nastavení",
            icon: <SettingsIcon />,
            route: <SettingsMasterDetailRoute path="/settings" key="/settings" />,
        });
    }
    if (user.employee) {
        menuItems.push({
            id: "timesheets",
            pathname: "/",
            label: "Výkazy",
            icon: <EventAvailableIcon />,
            route: <Route path="/" exact component={TimesheetPage} key="/" />,
        });
        menuItems.push({
            id: "expenses",
            pathname: "/expenses",
            label: "Náklady",
            icon: <InboxIcon />,
            route: <Route path="/expenses" exact component={ExpensesPage} key="/expenses" />,
        });
        menuItems.push({
            id: "mobile",
            pathname: "/mobile",
            label: "Mobilní aplikace",
            icon: <PhoneAndroidIcon />,
            action: () => {
                if (bannerRef && bannerRef.current) {
                    bannerRef.current.show();
                }
            },
            divider: true,
        });
    }
    menuItems.push({
        id: "logout",
        pathname: "/logout",
        action: async () => {
            await logout();
        },
        label: "Odhlášení",
        icon: icons.logout,
        divider: true,
    });

    /*
    if (!settings.initialized) {
        return <Route path="*" component={SettingsWizard} />;
    }
*/

    return (
        <React.Fragment>
            <Switch>
                <DocRoute path="/doc" />
                <Route
                    path="/"
                    render={(props) => (
                        <Menu menuItems={menuItems}>
                            <Switch>
                                {menuItems.filter((mi) => !!mi.route).map((mi) => mi.route)}
                                <Route path="*" component={HomeRedirect} />
                            </Switch>
                        </Menu>
                    )}
                />
            </Switch>

            {!!user && !!user.employee && <Banner user={user} innerRef={bannerRef} />}
        </React.Fragment>
    );
};

export default withTheme(
    withI18n(
        withSecurity(
            ({ security }) => (
                <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <AlertContainer>
                            {/*<SettingsProvider user={security.user} settings={settings}>*/}
                            <BrowserRouter>{security.user ? <PrivateRoutes /> : <PublicRoutes />}</BrowserRouter>
                            {/*</SettingsProvider>*/}
                        </AlertContainer>
                    </MuiPickersUtilsProvider>
                </SnackbarProvider>
            ) /*,
            {
                frontendOnly: false,
                user: {
                    roles: ["ADMIN"],
                },
            }*/
        ),
        { locale: "cs" }
    ),
    theme
);

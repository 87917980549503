import React, { useState, useEffect } from "react";

import { Paper, Typography, Box, TextField, FormControlLabel, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import { withRouter } from "react-router-dom";
import { fetchPrivacy, fetchTerms } from "lib/security";

import { Formik } from "formik";
import clsx from "clsx";

const usePageStyles = makeStyles((theme) => {
    return {
        root: {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            minHeight: "100%",
            overflow: "auto",
        },
        withMenuPadding: {
            paddingTop: 64,
            [theme.breakpoints.down("sm")]: {
                paddingTop: 56,
            },
        },
    };
});

export const Page = ({ children, withMenu }) => {
    const classes = usePageStyles();
    document.getElementById("root").className = "";
    return <div className={clsx(classes.root, !!withMenu && classes.withMenuPadding)}>{children}</div>;
};

const useLogoStyles = makeStyles((theme) => ({
    logoContainer: {
        backgroundColor: theme.palette.getContrastText(theme.palette.primary.main),
        borderRadius: "25%",
        [theme.breakpoints.down("sm")]: {
            marginBottom: 16,
        },
        width: "11em",
        height: "11em",
        position: "relative",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    logo: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        borderRadius: "25%",
        zIndex: 50,
        overflow: "hidden",
        position: "relative",
        width: "86%",
        height: "86%",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        "& svg": {
            position: "relative",
            width: "85% !important",
            height: "85% !important",
        },
    },
}));

export const Logo = ({ icon, size = "11.66em", elevation = 8, iconColor, borderColor, backgroundColor }) => {
    const classes = useLogoStyles();
    const logoStyle = {};
    const containerStyle = { width: size, height: size };

    if (elevation) {
        logoStyle.boxShadow = "inset 0px 5px 5px -3px rgba(0,0,0,0.2), inset 0px 8px 10px 1px rgba(0,0,0,0.14), inset 0px 3px 14px 2px rgba(0,0,0,0.12)";
    }
    if (iconColor) {
        logoStyle.color = iconColor;
    }
    if (borderColor) {
        containerStyle.backgroundColor = borderColor;
    }
    if (backgroundColor) {
        logoStyle.backgroundColor = backgroundColor;
    }

    return (
        <Paper elevation={elevation || 0} className={classes.logoContainer} style={containerStyle}>
            <div className={classes.logo} style={logoStyle}>
                {icon}
            </div>
        </Paper>
    );
};

const useJumbotronStyles = makeStyles((theme) => ({
    appToolbar: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: 500,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        /*
        [theme.breakpoints.down("sm")]: {
            paddingTop: 100,
        },*/
    },
    landing: {
        maxWidth: 750,
    },
    landingTypography: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
        },
        paddingTop: 8,
        paddingBottom: 8,
    },
    subtitle: {},
    buttons: {
        marginRight: -16,
        marginLeft: -16,
        marginTop: 16,

        "& > *": {
            margin: 16,
        },
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
            marginLeft: 0,
            marginRight: 0,
        },
    },
    leftIcon: {
        marginRight: 8,
    },
    papersContainer: {
        marginTop: 64,
        maxWidth: 1200,
    },
    paper: {
        marginBottom: 32,
    },
    mainContainer: {
        paddingTop: 100,
        paddingBottom: 100,
        fontSize: 20,
        width: "50%",
        float: "left",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    main: {
        padding: 20,
        paddingTop: 40,
        backgroundColor: "#0000001a",
    },
}));

export const Jumbotron = ({ actions, title, subtitle, icon, children, style, backgroundImage }) => {
    const classes = useJumbotronStyles();
    if (!style) {
        style = {};
    }
    if (backgroundImage) {
        style.backgroundImage = `url(${backgroundImage})`;
        style.backgroundRepeat = "no-repeat";
        style.backgroundPosition = "center center";
        style.backgroundSize = "cover";
    }

    return (
        <Toolbar color="primary" className={classes.appToolbar} style={style}>
            <Container>
                <div className={classes.mainContainer}>
                    <div className={classes.main}>
                        <Typography variant="h3" className={classes.landingTypography} gutterBottom>
                            {title || ""}
                        </Typography>

                        <Typography variant="h5" className={clsx(classes.landingTypography, classes.subtitle)} gutterBottom>
                            {subtitle || ""}
                        </Typography>

                        <Grid container className={classes.buttons}>
                            {Array.isArray(actions)
                                ? actions.map((a, key) => (
                                      <Button
                                          variant="contained"
                                          size="large"
                                          key={key}
                                          {...(a.actionButtonProps || {})}
                                          color={a.color || "default"}
                                          onClick={a.action}>
                                          {a.label}
                                      </Button>
                                  ))
                                : null}
                        </Grid>
                    </div>
                </div>
            </Container>
        </Toolbar>
    );
};

const useMenuStyles = makeStyles((theme) => {
    return {
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),

            [theme.breakpoints.up("md")]: {
                display: "none",
            },
        },
        topMenuButton: {
            display: "none",
            [theme.breakpoints.up("md")]: {
                display: "initial",
            },
        },
        title: {
            flexGrow: 1,
        },
        drawer: {
            backgroundColor: theme.palette.primary.main,
            background: theme.palette.primary.main,
        },

        appToolbar: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.getContrastText(theme.palette.primary.main),
            padding: "100px 0px",
            [theme.breakpoints.down("sm")]: {
                paddingTop: 100,
            },
        },
        toolbarPlaceholder: {
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
    };
});

function navigate(history, path) {
    return () => {
        history.push(path);
    };
}

const wrapDrawerAction = (toggleDrawer, action) => () => {
    toggleDrawer(false);
    action && action();
};

export const PublicMenu = ({ menuItems, actions, fixed }) => {
    const [open, toggleDrawer] = useState(false);
    const classes = useMenuStyles();

    const drawerContent = [];
    if (open) {
        Array.isArray(menuItems) &&
            menuItems.forEach((menu) => {
                drawerContent.push(
                    <List key={`m${menu.id}`}>
                        <ListItem button onClick={wrapDrawerAction(toggleDrawer, menu.action || navigate(menu.route))}>
                            {menu.icon ? <ListItemIcon>{menu.icon}</ListItemIcon> : null}
                            <ListItemText>{menu.label || menu.id}</ListItemText>
                        </ListItem>
                    </List>
                );
                if (menu.divider) {
                    drawerContent.push(<Divider key={`d${menu.id}`} />);
                }
            });
        if (Array.isArray(actions) && actions.length) {
            drawerContent.push(<Divider key="actions" />);
        }
        actions.forEach((menu) => {
            drawerContent.push(
                <List key={`m${menu.id}`}>
                    <ListItem button onClick={wrapDrawerAction(toggleDrawer, menu.action || navigate(menu.route))}>
                        {menu.icon ? <ListItemIcon>{menu.icon}</ListItemIcon> : null}
                        <ListItemText>{menu.label || menu.id}</ListItemText>
                    </ListItem>
                </List>
            );
            if (menu.divider) {
                drawerContent.push(<Divider key={`d${menu.id}`} />);
            }
        });
    }

    return (
        <React.Fragment>
            <AppBar position={fixed ? "fixed" : "static"} elevation={0} style={{ opacity: 0.95 }}>
                <Container>
                    <Grid justify="space-between" container>
                        <Grid item>
                            {Array.isArray(menuItems) && menuItems.length ? (
                                <Toolbar>
                                    <IconButton
                                        edge="start"
                                        className={classes.menuButton}
                                        color="inherit"
                                        aria-label="menu"
                                        onClick={() => toggleDrawer(!open)}>
                                        <MenuIcon />
                                    </IconButton>
                                    {menuItems.map((a) => (
                                        <Button key={a.id} color="inherit" onClick={a.action} className={classes.topMenuButton}>
                                            {a.label}
                                        </Button>
                                    ))}
                                </Toolbar>
                            ) : null}
                        </Grid>

                        <Grid item>
                            {Array.isArray(actions) ? (
                                <Toolbar>
                                    {actions.map((a) => (
                                        <Button
                                            key={a.id}
                                            color="inherit"
                                            onClick={a.action}
                                            className={classes.topMenuButton}
                                            {...(a.actionButtonProps || {})}>
                                            {a.label}
                                        </Button>
                                    ))}
                                </Toolbar>
                            ) : null}
                        </Grid>
                    </Grid>
                </Container>
            </AppBar>

            <SwipeableDrawer open={open} onClose={() => toggleDrawer(false)} onOpen={() => toggleDrawer(true)} classes={{ paper: classes.drawer }}>
                {fixed ? <div className={classes.toolbarPlaceholder}></div> : null}
                {drawerContent}
            </SwipeableDrawer>
        </React.Fragment>
    );
};

const useSectionStyles = makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        flexWrap: "no-wrap",
        fontSize: 20,
        color: theme.palette.text.color,
        [theme.breakpoints.down("sm")]: {
            flexWrap: "wrap-reverse",
        },
    },
    half: {
        flex: "1 1 50%",
        paddingTop: 100,
        paddingBottom: 100,
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
            flexBasis: "100%",
            paddingTop: 25,
            paddingBottom: 25,
        },
    },
    halfBanner: {
        flex: "1 1 50%",
        display: "flex",
        padding: 50,
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
    },
    title: {
        fontSize: 46,
        textTransform: "uppercase",
        [theme.breakpoints.down("sm")]: {
            fontSize: 32,
        },
    },
    children: {
        paddingTop: 25,
    },
    left: {
        textAlign: "right",
        flexDirection: "row-reverse",
    },
    right: {
        textAlign: "left",
        flexDirection: "row",
    },
}));

export const PublicSection = ({ left, right, title, description, children, banner }) => {
    const classes = useSectionStyles();

    return (
        <Container>
            <div className={clsx(classes.wrapper, !!left && classes.left, !!right && classes.right)}>
                <div className={classes.halfBanner}>{!!banner && banner}</div>
                <div className={classes.half}>
                    <Typography variant="h4" className={classes.title}>
                        {title}
                    </Typography>
                    {!!description && (
                        <Typography variant="text" className={classes.children} display="block">
                            {description}
                        </Typography>
                    )}
                    {!!children && <div className={classes.children}>{children}</div>}
                </div>
            </div>
        </Container>
    );
};

const useCopyrightStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
    },
}));

const AgreementPage = withRouter(({ type, intl, history }) => {
    const [state, setData] = useState({
        loading: true,
        doc: null,
    });

    useEffect(() => {
        async function fetch() {
            let doc = null;
            try {
                if (type == "terms") {
                    doc = await fetchTerms();
                } else if (type == "privacy") {
                    doc = await fetchPrivacy();
                }
            } catch (e) {
                console.error(e);
            }
            setData({ loading: false, doc });
        }

        fetch();
    }, []);

    return (
        <div className="container pv20">
            <div dangerouslySetInnerHTML={{ __html: state.doc ? state.doc.content : null }} />
        </div>
    );
});

export const createAgreementPage = (type) => () => <AgreementPage type={type} />;

export const Footer = ({ backgroundColor, children, pt = 4, pb = 8 }) => {
    return (
        <Box style={{ backgroundColor: "#282a2e", color: "#AEAEAE90" }} pt={pt} pb={pb}>
            <Container>
                <Grid container spacing={4} direction="row" alignItems="flex-end" justify="space-between" wrap="wrap-reverse">
                    {children}
                </Grid>
            </Container>
        </Box>
    );
};

export const FooterLogo = ({ logo, icon, title, subtitle }) => {
    return (
        <Grid container spacing={3} wrap="nowrap" style={{ maxWidth: 350 }}>
            <Grid item>
                <Logo elevation={0} icon={icon} size="5.75em" borderColor="#FFFFFF15" iconColor="#AEAEAE15" backgroundColor="#282a2e" />
            </Grid>
            <Grid item>
                <Box>
                    <Typography component="div" variant="h6">
                        {title}
                    </Typography>
                    <Typography component="div" variant="body1">
                        {subtitle}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

export const FooterCopyright = ({ owner, ownerUrl, since, logo, icon, title, subtitle }) => {
    let ownerElement = <b>{owner || "Jan Hrabal"}</b>;
    if (ownerUrl) {
        ownerElement = (
            <a href={ownerUrl} target="_blank" rel="noopener noreferrer" style={{ color: "inherit", textDecoration: "none" }}>
                {ownerElement}
            </a>
        );
    }

    let year = new Date().getFullYear();

    return (
        <Grid item style={{ maxWidth: 320 }}>
            <Box pb={2}>
                Copyright {ownerElement} © {`${!!since && since != year ? since + " - " : ""}`}
                {year}
            </Box>
            <Typography variant="caption" display="block" gutterBottom style={{ textAlign: "justify" }}>
                All logos, trademarks and registered trademarks
                <br /> are the property of their respective owners.
            </Typography>
            {logo ? (
                <Box pt={4}>
                    <FooterLogo icon={icon} title={title} subtitle={subtitle} />
                </Box>
            ) : null}
        </Grid>
    );
};

export const FooterSection = ({ children }) => {
    return (
        <Grid item style={{ flexGrow: 1, flexShrink: 1 }}>
            <Grid container spacing={1} justify="space-around">
                {children}
            </Grid>
        </Grid>
    );
};

export const FooterLinks = ({ title, children }) => {
    return (
        <Box pb={2}>
            {title ? (
                <Typography display="block" variant="h6" style={{ color: "#AEAEAE" }} gutterBottom>
                    {title}
                </Typography>
            ) : null}
            <Box>{children}</Box>
        </Box>
    );
};

const handleLinkClick = (handler, path) => (e) => {
    e.preventDefault();
    !!handler && handler();
};

const createLinkStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        display: "block",
        color: "#AEAEAE90",
        "&:hover": {
            color: "#AEAEAE",
        },
    },
}));

export const FooterLink = ({ title, onClick, path }) => {
    const classes = createLinkStyles();
    return (
        <Box mt={1} mb={1}>
            <Link href="#" onClick={handleLinkClick(onClick, path)} className={classes.root} display="block" underline="none">
                {title}
            </Link>
        </Box>
    );
};

export const FooterSocialSection = ({ links, contactMeAction }) => {
    return (
        <Grid item>
            <Box mb={2}>
                <Grid container spacing={2} direction="row" alignItems="center" justify="space-around">
                    {Array.isArray(links) &&
                        links.map((link, key) => (
                            <Grid key={key} item>
                                <IconButton aria-label="delete" target="_blank" rel="noopener noreferrer" href={link.href}>
                                    {link.icon}
                                </IconButton>
                            </Grid>
                        ))}
                </Grid>
            </Box>
            {contactMeAction ? (
                <React.Fragment>
                    <Box pb={2}>Do you want to work together?</Box>
                    <Button style={{ width: "100%" }} type="button" variant="contained" color="primary" onClick={contactMeAction}>
                        Contact me
                    </Button>
                </React.Fragment>
            ) : null}
        </Grid>
    );
};

export const ContactForm = ({ variant = "filled", inputRef, ...props }) => {
    return (
        <Formik
            initialValues={{ name: "", email: "", message: "" }}
            validate={(values) => {
                let errors = {};
                if (!values.name) {
                    errors.name = "Field is required";
                }
                if (!values.email) {
                    errors.email = "Field is required";
                }
                return errors;
            }}
            onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                setSubmitting(true);
                try {
                    if (props.onSubmit) {
                        await props.onSubmit(values);
                    }
                    resetForm({ name: "", email: "", message: "" });
                } catch (err) {
                    //setErrors();
                    console.error(err);
                }
                setSubmitting(false);
            }}>
            {({ values, errors, setErrors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setSubmitting }) => (
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <Grid container spacing={4}>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                label="Your name"
                                variant={variant}
                                fullWidth
                                inputRef={inputRef}
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                helperText={errors.name}
                                error={!!errors.name}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                label="Email"
                                variant={variant}
                                fullWidth
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                helperText={errors.email}
                                error={!!errors.email}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextField
                                label="Message"
                                multiline
                                rows={8}
                                variant={variant}
                                fullWidth
                                name="message"
                                value={values.message}
                                onChange={handleChange}
                                helperText={errors.message}
                                error={!!errors.message}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12} style={{ textAlign: "right" }}>
                            <Button type="submit" variant="contained" color="primary">
                                Send
                            </Button>
                        </Grid>
                    </Grid>
                    {/*checked={state.checkedB}
            onChange={handleChange}*/}
                    {/*    <FormControlLabel
                    control={
                        <Checkbox
                            name="checkedB"
                            color="primary"
                            />
                    }
                    label="Primary"
                    />
            */}
                </form>
            )}
        </Formik>
    );
};

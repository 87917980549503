import moment from "moment";

import backend, { parseErrors, download, upload, parsePaged } from "lib/backend";
import { prepareFormData } from "lib/attachments";

export const fetchEmployeeTimesheet = async (employeeId, from, to, filter, page = 0, size = 10, sortBy, sort) => {
    try {
        let response = await backend("/api/timesheets/v2/employee/" + employeeId, "GET", {
            params: { from, to, filter, page, size, sort, sortBy },
        });
        return parsePaged(response.body);
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const fetchEmployeeTimesheetOverview = async (employeeId, from, to, filter) => {
    try {
        let response = await backend("/api/timesheets/employee/" + employeeId + "/overview", "GET", {
            params: { from, to, filter },
        });
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const saveEmployeeTimesheet = async (employeeId, item) => {
    let formData = prepareFormData(item);
    try {
        let response = await backend("/api/timesheets/v2/employee/" + employeeId + (item.id ? "/" + item.id : ""), item.id ? "PUT" : "POST", {
            body: formData,
            upload: true,
        });
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const deleteEmployeeTimesheet = async (employeeId, id) => {
    try {
        await backend("/api/timesheets/employee/" + employeeId + "/" + id, "DELETE");
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const fetchProjectTimesheet = async (projectId, from, to, filter, page = 0, size = 10, sortBy, sort) => {
    try {
        let response = await backend("/api/timesheets/v2/project/" + projectId, "GET", {
            params: { from, to, filter, page, size, sort, sortBy },
        });
        return parsePaged(response.body);
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const saveProjectTimesheet = async (projectId, item) => {
    let formData = prepareFormData(item);
    try {
        let response = await backend("/api/timesheets/v2/project/" + projectId + (item.id ? "/" + item.id : ""), item.id ? "PUT" : "POST", {
            body: formData,
            upload: true,
        });
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const deleteProjectTimesheet = async (projectId, id) => {
    try {
        await backend("/api/timesheets/project/" + projectId + "/" + id, "DELETE");
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const fetchProjectTimesheetOverview = async (projectId, from, to, filter) => {
    try {
        let response = await backend("/api/timesheets/project/" + projectId + "/overview", "GET", {
            params: { from, to, filter },
        });
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const filterProjectOptions = async (employeeId, filter) => {
    try {
        let response = await backend("/api/employees/" + employeeId + "/projects", "GET", {
            params: {
                filter,
            },
        });
        return response.body || [];
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const filterEmployeeOptions = async (projectId, filter) => {
    try {
        let response = await backend("/api/projects/" + projectId + "/employees", "GET", {
            params: {
                filter,
            },
        });
        return response.body || [];
    } catch (e) {
        console.log(e);
        throw e;
    }
};

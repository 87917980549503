import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TuneIcon from "@material-ui/icons/Tune";

import { throttle } from "lodash";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

export default ({ filter, onFilterChange, timeoutMs = 1000 }) => {
    const classes = useStyles();
    const fetch = React.useMemo(
        () =>
            throttle(
                (input, callback) => {
                    try {
                        onFilterChange && onFilterChange(input);
                    } catch (e) {
                        console.error(e);
                    }
                },
                timeoutMs,
                { leading: false }
            ),
        []
    );

    return (
        <Box py={1} px={2}>
            <Paper className={classes.root}>
                <InputBase
                    className={classes.input}
                    placeholder="Vyhledat..."
                    value={filter}
                    onChange={(e) => fetch(e.target.value || "")}
                    autoComplete="off"
                />
                <IconButton className={classes.iconButton} onClick={() => onFilterChange && onFilterChange(filter)}>
                    <SearchIcon />
                </IconButton>
                {/*<Divider className={classes.divider} orientation="vertical" />
                <IconButton color="primary" className={classes.iconButton} aria-label="directions">
                    <TuneIcon />
                </IconButton>*/}
            </Paper>
        </Box>
    );
};

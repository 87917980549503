import React, { useEffect, useState, createContext, useContext, useRef } from "react";

import { useMediaQuery, useMediaQueries } from "@react-hook/media-query";
import Hidden from "@material-ui/core/Hidden";

import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";
import { SecurityContext } from "lib/security";
import clsx from "clsx";

import { LoadingIndicator } from "lib/components/LoadingIndicator";

import { Route, Switch } from "react-router-dom";

const noop = () => null;

export const MasterDetailContext = createContext({});

const style = {
    overflow: "hidden",
    overflowY: "auto",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
};

export const Master = (props) => {
    const masterDetail = useContext(MasterDetailContext);
    let md = 12,
        path = `${masterDetail.path || "/"}`;

    if (masterDetail.showBoth) {
        md = 2;
        path = `${masterDetail.path || "/"}/:detailId?`;
    }

    return (
        <Route path={path} exact>
            <Grid item xs={12} md={md} style={{ overflow: "hidden", height: "100%", display: "flex", flexDirection: "column", backgroundColor: "#F5F5F5" }}>
                {props.children}
            </Grid>
        </Route>
    );
};

export const Detail = ({ detailIdPath, newTypePath, children }) => {
    const masterDetail = useContext(MasterDetailContext);
    return (
        <Route path={`${masterDetail.path}/${detailIdPath || ":detailId"}/${newTypePath || ":newType"}?`}>
            <Grid item xs={12} md={masterDetail.showBoth ? 10 : 12} style={style}>
                <LoadingIndicator mode="detail" />
                {children}
            </Grid>
        </Route>
    );
};

export const EmptyDetail = (props) => {
    const masterDetail = useContext(MasterDetailContext);
    if (!masterDetail.showBoth) {
        return null;
    }
    return (
        <Route path={`${masterDetail.path || "/"}`} exact>
            <Grid item md={masterDetail.showBoth ? 10 : 12} style={style}>
                {props.children}
            </Grid>
        </Route>
    );
};

export const MasterDetail = withRouter(({ history, match, path, refreshList, ...props }) => {
    const theme = useTheme();
    const { matches } = useMediaQueries({
        width: "(min-width: 960px)",
    });

    const { params } = match;
    const detailId = params ? params.detailId : null;
    const type = params ? params.type : null;

    const showNew = (type) => {
        history.push(`${path || ""}/add${type ? "/" + type : ""}`);
    };

    const showDetail = (id) => {
        history.push(`${path || ""}/${id}`);
    };

    const showList = () => {
        history.push(path || "/");
    };

    function doRefreshList() {
        typeof refreshList == "function" && refreshList.apply(null, arguments);
    }

    const doRefreshDetail = (detail = {}, idProp = "id") => {
        if (detailId != detail[idProp]) {
            showDetail(detail[idProp]);
        }
    };

    return (
        <MasterDetailContext.Provider
            value={{
                showBoth: matches.width,
                path: path || "",
                showNew,
                showDetail,
                showList,
                refreshList: doRefreshList,
                refreshDetail: doRefreshDetail,
                detailId,
                type,
            }}>
            <Grid container alignItems="stretch" style={{ overflow: "hidden", height: "100%" }} spacing={props.spacing || 0}>
                {props.children}
            </Grid>
        </MasterDetailContext.Provider>
    );
});

export const MasterDetailRoute = ({ path, children, ...props }) => {
    return (
        <Route path={`${path}/:detailId?/:type?`} exact={true}>
            <MasterDetail path={path} {...props}>
                {children}
            </MasterDetail>
        </Route>
    );
};

import React from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

//MuiInputAdornment-root

const useStyles = makeStyles((theme) => ({
    large: {
        "& .MuiInputAdornment-root .MuiTypography-root": {
            fontSize: 26,
        },
    },
}));

export default ({ currency, large, unit, readOnly, ...props }) => {
    const classes = useStyles();

    let sizeStyle = {};
    if (large) {
        sizeStyle.fontSize = 26;
    }

    let inputProps = {
        readOnly,
        inputProps: {
            style: { textAlign: "right", ...sizeStyle },
        },
        className: large ? classes.large : null,
    };

    let startAdornment = null;
    let endAdornment = null;

    if (currency && currency.symbolPosition != "right") {
        inputProps["startAdornment"] = <InputAdornment position="start">{currency.symbol}</InputAdornment>;
    }

    if ((currency && currency.symbolPosition != "left") || !!unit) {
        inputProps["endAdornment"] = (
            <InputAdornment position="end">
                {currency && currency.symbolPosition != "left" ? currency.symbol : null}
                {!!unit ? "/" + unit : null}
            </InputAdornment>
        );
    }

    return <TextField InputProps={inputProps} {...props} />;
};

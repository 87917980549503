import React, { useEffect, useState, useContext, useRef } from "react";

import Container from "@material-ui/core/Container";

import { LayoutHeader } from "lib/components/layout";
import { withLoadingIndicator, LoadingIndicator } from "lib/components/LoadingIndicator";
import { SecurityContext } from "lib/security";
import Expenses from "components/expenses";
import { getCurrency } from "utils/currency";

export const ExpensesPage = withLoadingIndicator(({ showLoader, hideLoader, isLoading, ...props }) => {
    const { user } = useContext(SecurityContext);
    const currency = getCurrency(user);
    return (
        <React.Fragment>
            <LoadingIndicator />
            <LayoutHeader title="Náklady" />
            <Container maxWidth="lg">
                <Expenses showLoader={showLoader} hideLoader={hideLoader} disabled={isLoading} employeeId={user.userId} currency={currency} />
            </Container>
        </React.Fragment>
    );
});

import React, { useEffect, useState, useContext, useRef } from "react";

import { Doughnut } from "react-chartjs-2";

import { emphasize } from "@material-ui/core/styles/colorManipulator";

import { makeStyles, useTheme } from "@material-ui/core/styles";

const colors1 = [];
const colors2 = [];

for (let i = 0; i < 3; i++) {
    colors1[i] = emphasize("#6A1B9A", 0.25 * i);
}

for (let i = 0; i < 6; i++) {
    colors2[i] = emphasize("#00695C", 0.125 * i);
}

const createClasses = makeStyles((theme) => ({
    root: {
        position: "relative",
        width: "100%",
        paddingTop: "100%",
    },
    chart: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
    },
    children: {
        overflow: "hidden",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
    },
}));

const emptyColor = "#DADADA";

const emptyData = {
    label: "empty",
    data: [1],
    backgroundColor: [emptyColor],
    hoverBackgroundColor: [emptyColor],
};

export default ({ data, children, colors, ...props }) => {
    const [dataInner, setDataInner] = useState(emptyData);
    const classes = createClasses();

    useEffect(() => {
        if (!(Array.isArray(data) && data.length)) {
            setDataInner({ datasets: [{ ...emptyData, data: [1] }] });
            return;
        }

        let datasets = [];

        for (let i = 0; i < data.length; i++) {
            let val = 0;
            if (Array.isArray(data[i]) && data[i].length) {
                for (let j = 0; j < data[i].length; j++) {
                    val += (data[i][j] || 0) * 1;
                }
            }

            if (!val || isNaN(val) || val === 0) {
                datasets.push({ backgroundColor: [emptyColor], data: [1], label: `dataset-${i}` });
                continue;
            }

            let ds = {
                data: [],
                backgroundColor: [],
                label: `dataset-${i}`,
            };

            for (let j = 0; j < data[i].length; j++) {
                ds.backgroundColor.push(emphasize(colors[i], (0.8 / data[i].length) * j));
                ds.data.push(data[i][j] || 0);
            }
            datasets.push(ds);
        }

        setDataInner({ datasets });
    }, [data, colors]);

    return (
        <div className={classes.root}>
            <div className={classes.chart}>
                <Doughnut
                    data={dataInner}
                    options={{
                        legend: { display: false },
                        cutoutPercentage: 80,
                        responsive: true,
                        maintainAspectRatio: false,
                        animation: { duration: 1000 },
                        layout: {
                            padding: 32,
                        },
                    }}
                />
            </div>
            {children ? <div className={classes.children}>{children}</div> : null}
        </div>
    );
};

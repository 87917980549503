import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

import { throttle } from "lodash";

import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";

const useStyles = makeStyles((theme) => {
    const transition = {
        duration: theme.transitions.duration.shortest,
    };

    return {
        root: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(2),
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        expandIcon: {
            transform: "rotate(0deg)",
            transition: theme.transitions.create("transform", transition),
        },
        expanded: {
            transform: "rotate(180deg)",
        },
        noPadding: {
            padding: 0,
        },
        left: {
            textAlign: "left",
        },
        right: {
            textAlign: "right",
        },
        link: {
            fontSize: "0.9em",
        },
    };
});

export default ({ onFilterChange, timeoutMs = 1000, left, right }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState("");
    const fetch = React.useMemo(
        () =>
            throttle(
                (input, callback) => {
                    try {
                        onFilterChange && onFilterChange(input);
                    } catch (e) {
                        console.error(e);
                    }
                },
                timeoutMs,
                { leading: false }
            ),
        [onFilterChange]
    );

    const handleFilterChange = (e) => {
        let val = e.target.value;
        setFilter(val);
        fetch(val);
    };

    const handleOpenChange = (e) => {
        e.preventDefault();
        let newOpen = !open;
        setOpen(newOpen);
        fetch(newOpen ? filter : "");
    };

    return (
        <Box mb={2} className={clsx({ [classes.right]: !!right, [classes.left]: !!left })}>
            <Link className={classes.link} href="#/filter" color="textSecondary" onClick={handleOpenChange} gutterBottom underline="none">
                Filtrovat
                <IconButton
                    size="small"
                    className={clsx(classes.expandIcon, {
                        [classes.expanded]: !!open,
                    })}>
                    <ExpandMoreIcon />
                </IconButton>
            </Link>

            <Collapse in={!!open}>
                <Paper className={classes.root}>
                    <InputBase className={classes.input} placeholder="Zadejte kritéria..." value={filter} onChange={handleFilterChange} autoComplete="off" />
                    <IconButton className={classes.iconButton} onClick={() => onFilterChange && onFilterChange(filter)}>
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </Collapse>
        </Box>
    );
};

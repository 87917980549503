import React, { useEffect, useState, useContext, useRef } from "react";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "lib/components/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import Switch from "@material-ui/core/Switch";
import CurrencyField from "lib/components/CurrencyField";

import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import Alert from "lib/components/Alert";

import { LayoutHeader, DesktopMenu, MobileMenu } from "lib/components/layout";
import { Table, TableHeader, TableHeaderActions, TableActions, TableBody, TableRow, TableCell, TableFormCell } from "lib/components/Table";

import { Formik } from "formik";

import moment from "moment";
import validator from "lib/validator";

import { useSnackbar } from "notistack";
import PeriodSelector, { usePeriod } from "components/period";
import { AttachmentChips, AttachmentField, AttachmentDropzone } from "lib/components/Attachment";
import { formatDate, formatNum, parseNum, formatCurrency, dateToString } from "lib/format";
import { fetchProjectIncome, saveProjectIncome, deleteProjectIncome, filterIncomeOptions } from "./actions";

const Row = ({ item, showProject, showEmployee, onEdit, onDelete, showPrice, disabled, editable, currency }) => {
    return (
        <TableRow>
            <TableCell item xs={12} md={2}>
                {formatDate(item.date)}
            </TableCell>
            <TableCell item xs={12} md={6}>
                <Typography display="block">{item.templateName}</Typography>
                {!!item.description && (
                    <Typography display="block" color="textSecondary">
                        {item.description}
                    </Typography>
                )}
            </TableCell>
            <TableCell item xs={12} md={2} right>
                <Typography display="block">
                    {(item.amount && formatCurrency(item.amount, currency)) || "0"} {item.unit || ""}
                </Typography>

                <Typography display="block" color="textSecondary">
                    {formatCurrency(item.unitPrice || "0", "Kč", "right")}
                    {item.unit ? "/" + item.unit : null}
                </Typography>
            </TableCell>
            <TableActions xs={12} md={2}>
                {!!editable && (
                    <React.Fragment>
                        <IconButton onClick={onEdit} disabled={disabled}>
                            <CreateIcon fontSize="small" />
                        </IconButton>
                        <IconButton onClick={onDelete} disabled={disabled}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                )}
            </TableActions>
            <AttachmentChips attachments={item.attachments} url={(row) => `/api/income/${row.objectId}/attachments/${row.id}`} disabled={disabled} />
        </TableRow>
    );
};

const RowForm = ({ item, onSubmit, onCancel, showProject, showEmployee, projectId, employeeId, disabled, readOnly, showPrice, currency }) => {
    const uploadRef = useRef();
    return (
        <TableRow>
            <Formik
                initialValues={
                    item
                        ? {
                              ...item,
                              amount: formatNum(item.amount || 0),
                              unitPrice: formatNum(item.unitPrice || 0),
                          }
                        : {}
                }
                enableReinitialize={true}
                validate={(values) => {
                    let errors = {};

                    if (!values.date) {
                        errors.date = "Povinné pole";
                    }
                    if (!values.amount) {
                        errors.amount = "Povinné pole";
                    } else if (!validator.decimal(values.amount)) {
                        errors.amount = "Chybný formát čísla";
                    }
                    if (!values.unitPrice) {
                        errors.unitPrice = "Povinné pole";
                    } else if (!validator.decimal(values.unitPrice)) {
                        errors.unitPrice = "Chybný formát čísla";
                    }
                    return errors;
                }}
                validateOnChange={false}
                onSubmit={(values) => {
                    onSubmit &&
                        onSubmit({
                            ...values,
                            amount: parseNum(values.amount),
                            unitPrice: parseNum(values.unitPrice),
                            date: dateToString(values.date),
                        });
                }}>
                {({ values, setFieldValue, setValues, errors, handleChange, handleBlur, submitForm }) => {
                    return (
                        <React.Fragment>
                            <TableFormCell item xs={12} md={2}>
                                <KeyboardDatePicker
                                    label="Datum"
                                    margin="dense"
                                    name="date"
                                    value={values.date || null}
                                    onChange={(v) => setFieldValue("date", v)}
                                    disabled={disabled}
                                    format="DD.MM.YYYY"
                                    fullWidth
                                    autoOk
                                    helperText={errors.date}
                                    error={!!errors.date}
                                />
                            </TableFormCell>
                            <TableFormCell item xs={12} md={6}>
                                <Autocomplete
                                    label="Šablona příjmu"
                                    id="template"
                                    value={values.templateId ? { id: values.templateId, name: values.templateName } : null}
                                    onChange={(e, v) => {
                                        if (v && v.id) {
                                            setValues({
                                                ...values,
                                                templateId: v.id,
                                                templateName: v.name,
                                                unit: v.unit,
                                                unitPrice: formatNum(v.unitPrice),
                                            });
                                        } else {
                                            setValues({
                                                ...values,
                                                templateId: null,
                                                templateName: null,
                                                unit: null,
                                                unitPrice: null,
                                            });
                                        }
                                    }}
                                    error={!!errors.template}
                                    helperText={errors.template}
                                    getOptionLabel={(option) => option.name}
                                    fetchOptions={async () => filterIncomeOptions()}
                                    fullWidth
                                    disabled={disabled}
                                    margin="dense"
                                    loadingText="Načítají se hodnoty..."
                                    noOptionsText="Žádné hodnoty"
                                />
                                <TextField
                                    label="Popis"
                                    name="description"
                                    value={values.description || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    InputProps={{ readOnly }}
                                    disabled={disabled}
                                    helperText={errors.description}
                                    error={!!errors.description}
                                />
                            </TableFormCell>
                            <TableFormCell item xs={12} md={2} right>
                                <TextField
                                    label="Počet"
                                    name="amount"
                                    value={values.amount || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        readOnly,
                                        endAdornment: <InputAdornment position="end">{values.unit || ""}</InputAdornment>,
                                        inputProps: { style: { textAlign: "right" } },
                                    }}
                                    disabled={disabled}
                                    helperText={errors.amount}
                                    error={!!errors.amount}
                                />
                                <CurrencyField
                                    label="Jednotková cena"
                                    name="unitPrice"
                                    value={values.unitPrice || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    unit={values.unit}
                                    currency={currency}
                                    readOnly={readOnly}
                                    disabled={disabled}
                                    helperText={errors.unitPrice}
                                    error={!!errors.unitPrice}
                                />
                            </TableFormCell>
                            <TableActions item xs={12} md={2}>
                                <IconButton onClick={submitForm} disabled={disabled}>
                                    <CheckIcon fontSize="small" />
                                </IconButton>
                                <IconButton onClick={onCancel} disabled={disabled}>
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            </TableActions>
                            <AttachmentField
                                disabled={disabled}
                                value={values.attachments}
                                uploadRef={uploadRef}
                                onChange={(attachments) => setFieldValue("attachments", attachments)}
                            />
                        </React.Fragment>
                    );
                }}
            </Formik>
        </TableRow>
    );
};

export const IncomeTable = ({ data = [], disabled, editable = true, onSaveItem, onDeleteItem, ...props }) => {
    const [formIndex, setFormIndex] = useState(false);
    useEffect(() => setFormIndex(false), [data]);

    return (
        <React.Fragment>
            {/* header */}
            <TableHeader container>
                <TableCell item xs={12} md={2}>
                    Datum
                </TableCell>
                <TableCell item xs={12} md={6}>
                    Příjem
                </TableCell>
                <TableCell item xs={12} md={2} right>
                    Počet
                </TableCell>
                <TableHeaderActions item xs={12} md={2}>
                    {!!editable && (
                        <IconButton onClick={() => setFormIndex(-1)} disabled={disabled}>
                            <AddIcon fontSize="small" />
                        </IconButton>
                    )}
                </TableHeaderActions>
            </TableHeader>
            <TableBody>
                {formIndex === -1 ? <RowForm item={{}} onCancel={() => setFormIndex(false)} onSubmit={onSaveItem} disabled={disabled} /> : null}
                {Array.isArray(data) &&
                    data.map((item, key) =>
                        key === formIndex ? (
                            <RowForm item={item} key={key} onCancel={() => setFormIndex(false)} onSubmit={onSaveItem} disabled={disabled} />
                        ) : (
                            <Row
                                item={item}
                                key={key}
                                onEdit={() => setFormIndex(key)}
                                disabled={disabled}
                                editable={editable}
                                onDelete={() =>
                                    Alert.alert(
                                        "Smazat položku",
                                        `Chcete smazat položku ${formatDate(item.date)} ${item.templateName} ${item.description || ""} ?`,
                                        [{ text: "Ne" }, { text: "Ano", onPress: () => onDeleteItem && onDeleteItem(item.id) }]
                                    )
                                }
                            />
                        )
                    )}
            </TableBody>
        </React.Fragment>
    );
};

const noop = () => null;

export default ({ showLoader = noop, hideLoader = noop, disabled, projectId, ...props }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState([]);

    const load = async () => {
        showLoader();
        try {
            let list = await fetchProjectIncome(projectId);
            setData(list);
        } catch (e) {
            setData([]);
            console.log(e);
            enqueueSnackbar("Došlo k chybě při načítání dat", { variant: "error" });
        }
        hideLoader();
    };

    const saveItem = async (item) => {
        showLoader();
        try {
            await saveProjectIncome(projectId, item);
            load();
        } catch (e) {
            enqueueSnackbar("Došlo k chybě při ukládání dat", { variant: "error" });
        }
        hideLoader();
    };

    const deleteItem = async (id) => {
        showLoader();
        try {
            await deleteProjectIncome(projectId, id);
            load();
        } catch (e) {
            enqueueSnackbar("Došlo k chybě při mazání dat", { variant: "error" });
        }
        hideLoader();
    };

    useEffect(() => {
        load();
    }, [projectId]);

    return <IncomeTable data={data} disabled={disabled} onSaveItem={saveItem} onDeleteItem={deleteItem} />;
};

export const IncomeTemplatesTable = ({ data = [], currency, ...props }) => {
    return (
        <Box {...props}>
            <TableHeader>
                <TableCell xs={6}>Příjem</TableCell>
                <TableCell xs={3} right>
                    Počet
                </TableCell>
                <TableCell xs={3} right>
                    Celkem
                </TableCell>
            </TableHeader>
            <TableBody>
                {Array.isArray(data) &&
                    data.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell xs={6}>{row.name}</TableCell>
                            <TableCell xs={3} right>
                                {formatNum(row.value || 0)} {row.unit}
                            </TableCell>
                            <TableCell xs={3} right>
                                {formatCurrency(row.amount, currency)}
                            </TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Box>
    );
};

import React, { useEffect, useState, useContext } from "react";

import { Box, Container, Grid, Paper } from "@material-ui/core";

import ActionButton from "lib/components/ActionButton";
import Alert from "lib/components/Alert";
import ValueField from "lib/components/ValueField";
import { DashboardPanelWithChart } from "components/Dashboard";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CallMadeIcon from "@material-ui/icons/CallMade";
import CallReceivedIcon from "@material-ui/icons/CallReceived";

import Typography from "@material-ui/core/Typography";

import backend from "lib/backend";

import DoughnutChart from "components/DoughnutChart";
import { LayoutInfo, Layout, LayoutHeader, LayoutContent } from "lib/components/layout";
import icons from "icons";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";

import { formatDate, formatNum, parseNum } from "lib/format";

import PersonIcon from "@material-ui/icons/Person";
import WorkIcon from "@material-ui/icons/Work";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";

import { withLoadingIndicator, LoadingIndicator } from "lib/components/LoadingIndicator";
import { Table, TableHeader, TableHeaderActions, TableActions, TableBody, TableRow, TableCell, TableFormCell } from "lib/components/Table";

import { formatCurrency } from "lib/format";
import PeriodSelector, { usePeriod } from "components/period";
import { fetchSummary } from "./actions";

import { Bar } from "react-chartjs-2";
import { SecurityContext } from "lib/security";
import { getCurrency } from "utils/currency";

//TODO fixme
const createClasses = (props) =>
    makeStyles((theme) => {
        let color = props.color || theme.palette.primary.main;
        let textColor = theme.palette.getContrastText(color);

        if (!!props.disabled) {
            color = "#DFDFDF";
            textColor = emphasize(theme.palette.getContrastText(color), 0.5);
        }

        return {
            root: {
                position: "relative",
                backgroundImage: `linear-gradient(132deg, ${emphasize(color, -0.4)}, ${color} 35%, ${emphasize(color, 0.2)})`,
                padding: theme.spacing(2),
                color: textColor,
                minHeight: 100,
            },
            content: {
                position: "relative",
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
            icon: {
                position: "absolute",
                bottom: 0,
                right: 0,
                opacity: 0.1,
                "& .MuiSvgIcon-root": {
                    fontSize: 75,
                },
            },
            bold: {
                fontWeight: 400,
            },
        };
    });

const Panel = (props) => {
    const classes = createClasses(props)();
    return (
        <Grid item xs={12} sm={6} md={3} lg={2}>
            <div className={classes.root}>
                <div className={classes.content}>
                    <Typography variant="h6">{props.title}</Typography>
                    <Typography variant="h4" display="inline">
                        {props.value || 0}
                    </Typography>
                    {props.total !== undefined ? (
                        <Typography variant="h5" display="inline" className={classes.bold}>
                            {" "}
                            / {props.total}
                        </Typography>
                    ) : null}
                </div>
                {props.icon ? <div className={classes.icon}>{props.icon}</div> : null}
            </div>
        </Grid>
    );
};

export default withLoadingIndicator(({ showLoader, hideLoader, isLoading, history, ...props }) => {
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const periodRef = usePeriod();
    const [period] = periodRef;
    const [data, setData] = useState({});
    const { user } = useContext(SecurityContext);
    const currency = getCurrency(user);

    const actions = [
        { icon: <AccountTreeIcon />, name: "Přidat projekt", action: () => history.push("/projects/add") },
        { icon: <WorkIcon />, name: "Přidat klienta", action: () => history.push("/clients/add") },
        { icon: <PersonIcon />, name: "Přidat zaměstnance", action: () => history.push("/employees/add") },
    ];

    const fetchData = async (from, to) => {
        showLoader();
        try {
            let data = await fetchSummary(from, to);
            setData(data);
        } catch (e) {
            setData({});
        }
        hideLoader();
    };

    useEffect(() => {
        fetchData(period.start, period.end);
    }, [period]);

    const barData = {
        labels: ["1", "2", "3", "4", "5", "6"],
        datasets: [
            {
                label: "# of Red Votes",
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: theme.palette.primary.main,
            },
            {
                label: "# of Blue Votes",
                data: [2, 3, 20, 5, 1, 4],
                backgroundColor: "rgb(54, 162, 235)",
            },
            {
                label: "# of Green Votes",
                data: [3, 10, 13, 15, 22, 30],
                backgroundColor: "rgb(75, 192, 192)",
            },
        ],
    };

    const options = {
        scales: {
            yAxes: [
                {
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
            xAxes: [
                {
                    stacked: true,
                },
            ],
        },
    };

    return (
        <React.Fragment>
            <LoadingIndicator />
            <LayoutHeader title="Přehled" />
            <LayoutContent>
                <Box p={3}>
                    <PeriodSelector periodRef={periodRef} />
                </Box>

                <Box p={3}>
                    <Grid container spacing={3}>
                        <Panel
                            color={theme.palette.secondary.main}
                            title="Projekty"
                            value={data.activeProjectsCount}
                            total={data.projectsCount}
                            disabled={isLoading}
                            icon={<AccountTreeIcon />}
                        />
                        <Panel
                            color={theme.palette.secondary.main}
                            title="Klienti"
                            value={data.activeClientsCount}
                            total={data.clientsCount}
                            disabled={isLoading}
                            icon={<WorkIcon />}
                        />
                        <Panel
                            color="#AAAAAA"
                            title="Zaměstnanci"
                            value={data.activeEmployeesCount}
                            total={data.employeesCount}
                            disabled={isLoading}
                            icon={<PersonIcon />}
                        />
                        <Panel color="#AAAAAA" title="Náklady" value={formatCurrency(data.expenses, currency)} disabled={isLoading} icon={<AllInboxIcon />} />
                        <Panel title="Příjmy" value={formatCurrency(data.income, currency)} disabled={isLoading} icon={<TrendingUpIcon />} />
                        <Panel title="Celkem" value={formatCurrency(data.total, currency)} disabled={isLoading} icon={<AttachMoneyIcon />} />
                    </Grid>
                </Box>

                <Box p={3}>
                    <Grid container>
                        <Grid container wrap="wrap-reverse">
                            <Grid item xs={12} md={5}>
                                <Box py={3}>
                                    <Table>
                                        <TableHeader>
                                            <TableCell xs={8}>Projekt</TableCell>
                                            <TableCell xs={4} right>
                                                Celkem
                                            </TableCell>
                                        </TableHeader>
                                        <TableBody>
                                            {Array.isArray(data.projects) &&
                                                data.projects.map((project, key) => (
                                                    <TableRow key={key}>
                                                        <TableCell xs={8}>{project.name}</TableCell>
                                                        <TableCell xs={4} right>
                                                            {formatCurrency(project.amount, currency)}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                                <Box py={3}>
                                    <Table>
                                        <TableHeader>
                                            <TableCell xs={8}>Klient</TableCell>
                                            <TableCell xs={4} right>
                                                Celkem
                                            </TableCell>
                                        </TableHeader>
                                        <TableBody>
                                            {Array.isArray(data.clients) &&
                                                data.clients.map((client, key) => (
                                                    <TableRow key={key}>
                                                        <TableCell xs={8}>{client.name}</TableCell>
                                                        <TableCell xs={4} right>
                                                            {formatCurrency(client.amount, currency)}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Grid>
                            <Grid item md={2} />
                            <Grid item xs={12} md={4}>
                                <DoughnutChart
                                    data={[data.projectBalanceChart, data.clientBalanceChart]}
                                    colors={[theme.palette.primary.main, theme.palette.secondary.main]}
                                    disabled={isLoading}>
                                    <div>
                                        <Typography variant="h6" display="block" gutterBottom>
                                            Celkem
                                        </Typography>
                                        <Typography variant="h6" display="block">
                                            {formatCurrency(data.total, currency)}
                                        </Typography>
                                    </div>
                                </DoughnutChart>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <ActionButton actions={actions} icon={icons.add} />
            </LayoutContent>
        </React.Fragment>
    );
});

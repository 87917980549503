import React, { useEffect, useState, useContext, useRef } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import { Container, FormControl, FormControlLabel, FormHelperText, TextField } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import Avatar from "@material-ui/core/Avatar";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import ActionButton from "lib/components/ActionButton";
import PasswordField from "lib/components/PasswordField";
import ValueField from "lib/components/ValueField";
import { LayoutInfo, Layout, LayoutHeader, DesktopMenu, MobileMenu, ScrollView } from "lib/components/layout";
import { MasterDetailRoute, Master, Detail, EmptyDetail, MasterDetailContext } from "lib/components/MasterDetail";
import { EmptyInfoPanel, ErrorInfoPanel } from "lib/components/panels";

import { Formik } from "formik";

import icons from "../../icons";

import backend, { parseErrors, download, upload } from "lib/backend";
import validator from "lib/validator";

import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";

import TestFilter from "components/TestFilter";
import References from "components/References";
import Divider from "@material-ui/core/Divider";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import InputLabel from "@material-ui/core/InputLabel";
import Collapse from "@material-ui/core/Collapse";

import Badge from "@material-ui/core/Badge";
import Autocomplete from "lib/components/Autocomplete";
import AmountField from "lib/components/AmountField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import CurrencyField from "lib/components/CurrencyField";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import { filterEmployees, fetchEmployee, saveEmployee, deleteEmployee } from "./actions";
import { formatNum } from "lib/format";
import { SettingsContext } from "lib/settings";
import formatPersonName from "utils/formatPersonName";

import Modal from "lib/components/Modal";
import { CurrencySelector, PaymentTypeSelector } from "components";
import SearchField from "components/SearchField";
import { MenuToggleButton } from "lib/components/layout";

import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import { EmployeeTimesheet } from "components/timesheet";

import { withLoadingIndicator, LoadingIndicator } from "lib/components/LoadingIndicator";

import { useSnackbar } from "notistack";

import Menu from "components/menu";
import Alert from "lib/components/Alert";
import ExpandFilter from "components/ExpandFilter";
import { getCurrency } from "utils/currency";
import { SecurityContext } from "lib/security";

const outerSpacing = 4;

export const EmployeeList = ({ detailId, showDetail, loading, settings, error, filter, onNextPage, data, hasMoreElements, scrollRef, ...props }) => {
    if (error) {
        return (
            <React.Fragment>
                <ErrorInfoPanel title="Chyba" text="Nepodařilo se získat seznam" />
            </React.Fragment>
        );
    }
    if (!loading && !(data && data.length)) {
        return <EmptyInfoPanel text="Seznam je prázdný" disabled={loading} />;
    }

    return (
        <Box width={1} height={1} position="relative" display="flex" flexDirection="column">
            <Box flexGrow={1}>
                <ScrollView vertical loading={loading} onNextElements={onNextPage} hasMoreElements={hasMoreElements} scrollRef={scrollRef}>
                    <List disablePadding>
                        {Array.isArray(data) &&
                            data.map((row, key) => (
                                <ListItem
                                    key={key}
                                    button
                                    onClick={showDetail ? () => showDetail(row.id) : null}
                                    divider
                                    style={row.id == detailId ? { backgroundColor: "#FFFFFF" } : null}>
                                    <ListItemAvatar>
                                        <Avatar>{row.external ? <PersonOutlineIcon /> : <PersonIcon />}</Avatar>
                                    </ListItemAvatar>
                                    <Box style={{ flex: "1 1 auto", alignSelf: "", overflow: "hidden" }}>
                                        <Typography noWrap display="block">
                                            {formatPersonName(row, true)}
                                        </Typography>
                                        <Box style={{ display: "flex", alignItems: "end" }}>
                                            <Box style={{ flex: "1 1 auto" }}>
                                                <Typography variant="caption" display="block" color="textSecondary">
                                                    {row.external ? "Externista" : "Zaměstnanec"}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </ListItem>
                            ))}
                    </List>
                </ScrollView>
            </Box>
        </Box>
    );
};

export const EmployeeForm = ({ detailId, readOnly, loading, settings = {}, formik, showLoader, hideLoader, ...props }) => {
    const { user } = useContext(SecurityContext);
    const currency = getCurrency(user);
    const [activeTab, setActiveTab] = useState(detailId == "add" ? 0 : 1);
    const switchTab = (event, newValue) => setActiveTab(newValue);

    const [filter, setFilter] = useState("");

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, setValues } = formik;
    const disabled = isSubmitting || loading;

    useEffect(() => {
        setActiveTab(detailId == "add" ? 0 : 1);
    }, [detailId]);

    return (
        <Box px={2}>
            <Box mb={2}>
                <Tabs indicatorColor="primary" textColor="primary" value={activeTab} onChange={switchTab} disabled={disabled}>
                    <Tab label="Vlastnosti" disabled={disabled} />
                    <Tab label="Výkazy" disabled={disabled || detailId == "add"} />
                </Tabs>
            </Box>

            <Grid container alignItems="stretch" spacing={outerSpacing}>
                <Grid item lg={9} xs={12}>
                    {/** properties **/}
                    {activeTab == 0 ? (
                        <React.Fragment>
                            <Box mb={4}>
                                <Paper>
                                    <Grid container alignContent="stretch" spacing={outerSpacing}>
                                        <Grid item xs={12} md={6}>
                                            <Box p={outerSpacing}>
                                                <TextField
                                                    label="Příjmení"
                                                    name="lastName"
                                                    value={values.lastName || ""}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    margin="normal"
                                                    InputProps={{ readOnly }}
                                                    disabled={disabled}
                                                    helperText={errors.lastName}
                                                    error={!!errors.lastName}
                                                />
                                                <TextField
                                                    label="Jméno"
                                                    name="firstName"
                                                    value={values.firstName || ""}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    margin="normal"
                                                    InputProps={{ readOnly }}
                                                    disabled={disabled}
                                                    helperText={errors.firstName}
                                                    error={!!errors.firstName}
                                                />

                                                <ValueField>
                                                    <ToggleButtonGroup
                                                        value={values.external}
                                                        name="external"
                                                        onChange={(e, val) => setFieldValue("external", val)}
                                                        size="small"
                                                        exclusive
                                                        disabled={disabled}>
                                                        <ToggleButton value={false} disabled={disabled}>
                                                            <PersonIcon /> Zaměstnanec
                                                        </ToggleButton>
                                                        <ToggleButton value={true} disabled={disabled}>
                                                            <PersonOutlineIcon /> Externista
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </ValueField>

                                                <CurrencyField
                                                    label="Mzda"
                                                    name="wage"
                                                    value={values.wage || ""}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    margin="normal"
                                                    unit="h"
                                                    currency={currency}
                                                    readOnly={readOnly}
                                                    disabled={disabled}
                                                    helperText={errors.wage}
                                                    error={!!errors.wage}
                                                />
                                                <Box display="flex" alignItems="center">
                                                    <Box flexGrow={1} color={values.billable && !disabled ? "text.primary" : "text.secondary"}>
                                                        <Typography>Fakturovat klientovi</Typography>
                                                    </Box>
                                                    <Switch
                                                        color="secondary"
                                                        checked={values.billable || false}
                                                        onChange={handleChange}
                                                        name="billable"
                                                        value={true}
                                                        disabled={disabled}
                                                    />
                                                </Box>
                                                {!!values.billable && (
                                                    <CurrencyField
                                                        label="Fakturovaná mzda"
                                                        name="billableWage"
                                                        value={values.billableWage || ""}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fullWidth
                                                        margin="normal"
                                                        unit="h"
                                                        currency={currency}
                                                        readOnly={readOnly}
                                                        disabled={disabled}
                                                        helperText={errors.billableWage}
                                                        error={!!errors.billableWage}
                                                    />
                                                )}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} style={{ alignSelf: "flex-end" }}>
                                            <Box p={outerSpacing}>
                                                <Box my={4}>
                                                    <Box display="flex" alignItems="center">
                                                        <Box flexGrow={1} color={values.checkMinHoursPerDay && !disabled ? "text.primary" : "text.secondary"}>
                                                            <Typography variant="h6">Minimální počet hodin denně</Typography>
                                                        </Box>
                                                        <Switch
                                                            color="secondary"
                                                            checked={values.checkMinHoursPerDay || false}
                                                            onChange={handleChange}
                                                            name="checkMinHoursPerDay"
                                                            value={true}
                                                            disabled={disabled}
                                                        />
                                                    </Box>
                                                    <TextField
                                                        label="Minimální počet hodin denně"
                                                        name="minHoursPerDay"
                                                        value={values.minHoursPerDay || ""}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fullWidth
                                                        margin="normal"
                                                        InputProps={{ readOnly }}
                                                        disabled={disabled || !values.checkMinHoursPerDay}
                                                        helperText={errors.minHoursPerDay}
                                                        error={!!errors.minHoursPerDay}
                                                    />
                                                </Box>

                                                <Box display="flex" alignItems="center">
                                                    <Box flexGrow={1} color={values.accountEnabled && !disabled ? "text.primary" : "text.secondary"}>
                                                        <Typography variant="h6">Přístup do systému</Typography>
                                                    </Box>
                                                    <Switch
                                                        color="secondary"
                                                        checked={values.accountEnabled || false}
                                                        onChange={handleChange}
                                                        name="accountEnabled"
                                                        value={true}
                                                        disabled={disabled}
                                                    />
                                                </Box>
                                                <TextField
                                                    label="Přihlašovací jméno"
                                                    name="login"
                                                    value={values.login || ""}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    margin="normal"
                                                    InputProps={{ readOnly }}
                                                    disabled={disabled || !values.accountEnabled}
                                                    helperText={errors.login}
                                                    error={!!errors.login}
                                                />
                                                <PasswordField
                                                    label="Heslo"
                                                    name="password"
                                                    value={values.password || ""}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    margin="normal"
                                                    InputProps={{ readOnly }}
                                                    disabled={disabled || !values.accountEnabled}
                                                    helperText={errors.password}
                                                    error={!!errors.password}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box p={outerSpacing}>
                                        <Typography variant="h6" color={!disabled ? "textPrimary" : "textSecondary"}>
                                            Kontaktní informace
                                        </Typography>
                                        <Grid container spacing={outerSpacing * 2 + 2}>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    label="Telefon"
                                                    name="phoneNumber"
                                                    value={values.phoneNumber || ""}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    margin="normal"
                                                    InputProps={{ readOnly }}
                                                    disabled={disabled}
                                                    helperText={errors.phoneNumber}
                                                    error={!!errors.phoneNumber}
                                                />
                                                <TextField
                                                    label="Email"
                                                    name="email"
                                                    value={values.email || ""}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    margin="normal"
                                                    InputProps={{ readOnly }}
                                                    disabled={disabled}
                                                    helperText={errors.email}
                                                    error={!!errors.email}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    label="Adresa"
                                                    name="address"
                                                    value={values.address || ""}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    multiline
                                                    rows={6}
                                                    margin="normal"
                                                    InputProps={{ readOnly }}
                                                    disabled={disabled}
                                                    helperText={errors.address}
                                                    error={!!errors.address}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Box>
                        </React.Fragment>
                    ) : null}
                    {activeTab == 1 && detailId != "add" ? (
                        <React.Fragment>
                            <ExpandFilter onFilterChange={setFilter} />
                            <EmployeeTimesheet
                                employeeId={detailId}
                                isLoading={disabled}
                                showLoader={showLoader}
                                hideLoader={hideLoader}
                                showWage
                                wage={values.wage}
                                billable={values.billable}
                                billableWage={values.billableWage}
                                filter={filter}
                                currency={currency}
                            />
                        </React.Fragment>
                    ) : null}
                </Grid>

                {/* tools */}
                <Grid item lg={3} xs={12}>
                    <References
                        attachments={values.attachments}
                        onAttachmentChange={(a) => setFieldValue("attachments", a)}
                        detailId={detailId}
                        disabled={disabled}
                        attachmentUrl={detailId == "add" ? null : (row) => `/api/employees/${detailId}/attachments/${row.id}`}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

const EmployeeListMaster = (props) => {
    const masterDetailCtx = useContext(MasterDetailContext);
    const scrollRef = useRef();
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }
    }, [props.filter]);

    return (
        <Master>
            <LayoutHeader title="Zaměstnanci" noRightMargin>
                <IconButton
                    onClick={() => {
                        masterDetailCtx.showNew();
                    }}>
                    <AddIcon />
                </IconButton>
            </LayoutHeader>
            <SearchField onFilterChange={props.onFilterChange} />
            <Divider />
            <LoadingIndicator color="secondary" />
            <EmployeeList
                loading={props.loading}
                data={props.data}
                hasMoreElements={props.hasMoreElements}
                onNextPage={props.onNextPage}
                showDetail={masterDetailCtx.showDetail}
                scrollRef={scrollRef}
                detailId={masterDetailCtx.detailId}
            />
        </Master>
    );
};

const EmployeeMasterDetail = withLoadingIndicator(({ settings, showLoader, hideLoader, isLoading, ...props }) => {
    const masterDetailCtx = useContext(MasterDetailContext);
    const [state, setState] = useState(null);
    const [error, setError] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const loadData = async (id) => {
        let item = {};
        try {
            showLoader();
            if (id != "add") {
                item = await fetchEmployee(id);
            }
            setError(false);
            setState(item);
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            hideLoader();
        }
    };

    const handleSubmit = async (values, { setSubmitting, setErrors }) => {
        showLoader();
        setSubmitting(true);
        try {
            let emp = await saveEmployee(values);
            // variant could be success, error, warning, info, or default
            enqueueSnackbar("Uloženo", { variant: "success" });
            masterDetailCtx.refreshList();
            masterDetailCtx.refreshDetail(emp);
        } catch (err) {
            setErrors(parseErrors(err));
            enqueueSnackbar("Záznam nelze uložit", { variant: "error" });
        }
        setSubmitting(false);
        hideLoader();
    };

    const handleBack = () => {
        setState(null);
        setError(null);
        masterDetailCtx.showList();
    };

    useEffect(() => {
        if (masterDetailCtx.detailId) {
            loadData(masterDetailCtx.detailId);
        }
    }, [masterDetailCtx.detailId]);

    const loading = isLoading;

    if (error) {
        return (
            <Detail>
                {masterDetailCtx.showBoth ? null : <LayoutHeader title="Zaměstnanci" noRightMargin></LayoutHeader>}
                <ErrorInfoPanel text="Záznam nelze zobrazit"></ErrorInfoPanel>
            </Detail>
        );
    }

    if (!state && loading) {
        return <Detail>{masterDetailCtx.showBoth ? null : <LayoutHeader title="Zaměstnanci" noRightMargin></LayoutHeader>}</Detail>;
    }

    const menuItems = [];
    if (masterDetailCtx.detailId != "add") {
        menuItems.push({
            label: "Smazat",
            onClick: () =>
                Alert.alert("Smazat zaměstnance", `Chcete smazat zaměstnance ${state && state.name} ?`, [
                    { text: "Ne" },
                    {
                        text: "Ano",
                        onPress: async () => {
                            try {
                                showLoader();
                                await deleteEmployee(state.id);
                                masterDetailCtx.refreshList();
                                handleBack();
                                enqueueSnackbar("Smazáno", { variant: "success" });
                            } catch (e) {
                                enqueueSnackbar("Záznam nelze smazat", { variant: "error" });
                            }
                            hideLoader();
                        },
                    },
                ]),
        });
    }
    return (
        <Detail>
            <Formik
                initialValues={state || {}}
                enableReinitialize={true}
                validate={(values) => {
                    let errors = {};

                    if (!values.firstName) {
                        errors.firstName = "Povinné pole";
                    }
                    if (!values.lastName) {
                        errors.lastName = "Povinné pole";
                    }
                    if (!values.email) {
                        errors.email = "Povinné pole";
                    }
                    if (!values.wage) {
                        errors.wage = "Povinné pole";
                    } else if (!validator.number(values.wage)) {
                        errors.wage = "Zadejte číslo";
                    }
                    if (!!values.billable) {
                        if (!values.billableWage) {
                            errors.billableWage = "Povinné pole";
                        } else if (!validator.number(values.billableWage)) {
                            errors.billableWage = "Zadejte číslo";
                        }
                    }
                    if (values.minHoursPerDay && !validator.number(values.minHoursPerDay)) {
                        errors.minHoursPerDay = "Zadejte číslo";
                    }
                    if (!!values.checkMinHoursPerDay && !values.minHoursPerDay) {
                        errors.minHoursPerDay = "Povinné pole";
                    }
                    return errors;
                }}
                validateOnChange={false}
                onSubmit={handleSubmit}>
                {(formik) => {
                    return (
                        <React.Fragment>
                            <LayoutHeader title={formatPersonName(formik.values, true) || "<beze jména>"} activeMenu="transactions">
                                <DesktopMenu>
                                    <Button variant="contained" onClick={handleBack} disabled={formik.isSubmitting || isLoading}>
                                        Zpět
                                    </Button>
                                    <Menu items={menuItems} disabled={formik.isSubmitting || isLoading} />
                                    <Button variant="contained" color="secondary" onClick={formik.submitForm} disabled={formik.isSubmitting || isLoading}>
                                        Uložit
                                    </Button>
                                </DesktopMenu>
                                <MobileMenu>
                                    <Button onClick={formik.submitForm} disabled={formik.isSubmitting || isLoading}>
                                        Uložit
                                    </Button>
                                    <Menu items={menuItems} disabled={formik.isSubmitting || isLoading} />
                                </MobileMenu>
                            </LayoutHeader>
                            <EmployeeForm
                                formik={formik}
                                data={state}
                                detailId={masterDetailCtx.detailId}
                                settings={settings}
                                loading={loading}
                                showLoader={showLoader}
                                hideLoader={hideLoader}
                            />
                        </React.Fragment>
                    );
                }}
            </Formik>
        </Detail>
    );
});

export default withLoadingIndicator(
    injectIntl((props) => {
        const { values: settings } = useContext(SettingsContext);
        const [list, setList] = useState({ data: [], error: null, filter: {} });
        const masterDetailCtx = useContext(MasterDetailContext);

        const fetchItems = async (resetFilter, filter, page, size, sortBy, sort) => {
            props.showLoader();
            try {
                let s = await filterEmployees(filter, page, size, sortBy, sort);
                let data = resetFilter ? s.content : [...list.data, ...s.content];
                setList({ ...s, data, filter: filter || {} });
            } catch (e) {
                console.error(e);
                setList({
                    error: e,
                });
            }
            props.hideLoader();
        };

        const changeFilter = (filter) => {
            fetchItems(true, { text: filter });
        };

        const fetchNextPage = () => {
            fetchItems(false, list.filter, list.number + 1);
        };

        const refreshList = () => {
            fetchItems(true, list.filter);
        };

        useEffect(() => {
            fetchItems(true);
        }, []);

        return (
            <MasterDetailRoute path={props.path} refreshList={refreshList}>
                <EmployeeListMaster
                    loading={props.isLoading}
                    settings={settings}
                    onFilterChange={changeFilter}
                    onNextPage={fetchNextPage}
                    hasMoreElements={list.number < list.totalPages - 1}
                    data={list.data}
                    filter={list.filter && list.filter.text}
                />
                <EmployeeMasterDetail settings={settings} />
                <EmptyDetail>
                    <EmptyInfoPanel icon={icons.accounting} text="Vyberte záznam k zobrazení" disabled={masterDetailCtx.isDetailLoading} />
                </EmptyDetail>
            </MasterDetailRoute>
        );
    })
);

export default ({ firstName, lastName }, reverse) => {
    let n = [];
    if (reverse) {
        if (lastName) {
            n.push(lastName);
        }
        if (firstName) {
            n.push(firstName);
        }
    } else {
        if (firstName) {
            n.push(firstName);
        }
        if (lastName) {
            n.push(lastName);
        }
    }
    return n.join("\u00a0");
};

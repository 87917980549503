import backend, { parseErrors, download } from "lib/backend";

export const filterEmployees = async (filter, page = 0, size = 100, sortBy, sort) => {
    try {
        let response = await backend("/api/employees", "GET", {
            params: {
                page,
                size,
                sort,
                sortBy,
                filter: filter ? filter.text : null,
            },
        });
        return response.body.content || [];
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const employeeReport = async (employeeIds, from, to) => {
    try {
        let response = await backend("/api/timesheets/employee/report", "GET", {
            params: {
                employeeIds,
                from,
                to,
            },
        });
        return response.body || [];
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const employeeReportExcel = async (employeeIds, from, to) => {
    try {
        await download("/api/timesheets/employee/report/excel", {
            params: {
                employeeIds,
                from,
                to,
            },
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};

import React, { useEffect, useState } from "react";

import AccountTreeIcon from "@material-ui/icons/AccountTree";

import HomeIcon from "@material-ui/icons/Home";
import WorkIcon from "@material-ui/icons/CloudQueue";
import SchoolIcon from "@material-ui/icons/CloudQueue";
import StarIcon from "@material-ui/icons/CloudQueue";

import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import {
    Page,
    Section,
    Jumbotron,
    PublicMenu,
    PublicSection,
    Copyright,
    Logo,
    ContactForm,
    Footer,
    FooterCopyright,
    FooterSocialSection,
    FooterSection,
    FooterLinks,
    FooterLink,
    FooterLogo,
} from "lib/components/public";

import { withRouter } from "react-router-dom";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";

import backend from "lib/backend";

//TODO move components to lib?
function navigate(history, path) {
    return () => {
        history.push(path);
    };
}

const useStyles = makeStyles((theme) => ({
    mobileLinkWrapper: {
        margin: -5,
        marginTop: 10,
    },
    mobileLink: {
        margin: 5,
    },
    mobileLinkImage: {
        height: 50,
        width: "auto",
    },
}));

export default withRouter(({ history, intl }) => {
    const classes = useStyles();
    const [info, setInfo] = useState(false);
    const contactRef = React.createRef();

    const contactMe = () => {
        contactRef && contactRef.current && contactRef.current.focus();
    };

    const submitContactForm = async (data) => {
        try {
            await backend("https://emailer.janhrabal.com/api/endpoints/8114bd3e-f816-4c3d-9f1d-2d1e60ce8b17/email", "POST", {
                headers: { key: "jhendpoint" },
                body: {
                    emailFrom: data.email,
                    payload: {
                        message: data.message,
                        name: data.name,
                        email: data.email,
                    },
                },
            });
            setInfo("success");
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    const linkToStore = (type) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        alert("Aplikace ještě není publikována");
    };

    const actions =
        /*[]  */
        [
            {
                id: "signup",
                route: "/",
                label: "Registrovat",
                icon: <HomeIcon />,
                action: navigate(history, "/signup"),
                color: "secondary",
                actionButtonProps: {},
            },
            {
                id: "login",
                route: "/",
                label: "Přihlásit",
                icon: <HomeIcon />,
                action: navigate(history, "/login"),
                divider: true,
                actionButtonProps: {},
            },
        ];

    return (
        <PublicLayout path="/">
            <Jumbotron
                backgroundImage={require("./bg.png")}
                title="joReport"
                subtitle="Přehledná evidence stavu projektových aktivit"
                actions={actions}
                style={{ flex: "1 1 auto" }}
            />

            <Box style={{ flex: 1 }}>
                <PublicSection
                    right
                    title="Pomáhá jednotlivcům i firmám"
                    banner={<img className={classes.mobileBanner} src={require("./laptop.png")} style={{ maxWidth: "95%" }} />}>
                    <li style={{ lineHeight: 2 }}>evidence docházky</li>
                    <li style={{ lineHeight: 2 }}>evidence klientů a projektů</li>
                    <li style={{ lineHeight: 2 }}>přehledy, hodnocení nákladů a příjmů na zakázkách</li>
                </PublicSection>
                <PublicSection
                    left
                    title="Mobilní aplikace"
                    description="Přidat popis mobilní aplikace. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam vitae ultrices nulla. Suspendisse quis lacinia ipsum. Cras bibendum lacinia pharetra. Ut ultricies eros tortor, at efficitur augue placerat ac. Nunc in laoreet purus, aliquam pharetra felis."
                    banner={<img className={classes.mobileBanner} src={require("./mobile.png")} style={{ maxHeight: "400px" }} />}>
                    <div className={classes.mobileLinkWrapper}>
                        <a href="#" className={classes.mobileLink} onClick={linkToStore("ios")}>
                            <img className={classes.mobileLinkImage} src={require("./ios.png")} />
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.janhrabal.joreport.timetracking"
                            target="_blank"
                            className={classes.mobileLink}>
                            <img className={classes.mobileLinkImage} src={require("./android.png")} />
                        </a>
                    </div>
                </PublicSection>
            </Box>

            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={!!info}
                autoHideDuration={3000}
                onClose={() => setInfo(false)}
                message={<span id="message-id">Thank you very much for your message!</span>}></Snackbar>
        </PublicLayout>
    );
});

export const PublicLayout = withRouter(({ history, path, children }) => {
    console.log(">>>path", path);
    const menuItems = [
        {
            id: "home",
            label: "Domů",
            icon: <HomeIcon />,
            action: path == "/" ? () => window.scrollTo(0, 0) : navigate(history, "/"),
            divider: true,
            top: true,
        },
    ];

    const actions =
        /*[]  */
        [
            {
                id: "signup",
                route: "/",
                label: "Registrovat",
                icon: <HomeIcon />,
                action: navigate(history, "/signup"),
                color: "secondary",
                actionButtonProps: {},
            },
            {
                id: "login",
                route: "/",
                label: "Přihlásit",
                icon: <HomeIcon />,
                action: navigate(history, "/login"),
                divider: true,
                actionButtonProps: {},
            },
        ];

    return (
        <Page withMenu>
            <PublicMenu menuItems={menuItems} actions={actions} logo={<p>logo</p>} fixed />
            <Box style={{ flex: 1 }}>{children}</Box>
            <PublicFooter />
        </Page>
    );
});

export const PublicFooter = withRouter(({ history }) => (
    <Footer>
        <FooterCopyright since="2020" owner="Jan Hrabal" ownerUrl="https://www.janhrabal.com" />
        <FooterSection>
            <FooterLinks title="Podpora">
                <FooterLink title="Zásady ochrany soukromí" onClick={navigate(history, "/doc/privacy")} />
                <FooterLink title="Podmínky poskytování služby" onClick={navigate(history, "/doc/terms")} />
                <FooterLink title="Nápověda" />
            </FooterLinks>
        </FooterSection>
        <FooterSection>
            <FooterLinks title="O Nás">
                <FooterLink title="Kontakty" />
            </FooterLinks>
        </FooterSection>
    </Footer>
));

import React, { useEffect, useState, useContext, useRef } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import { Container, FormControl, FormControlLabel, FormHelperText, TextField } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import Avatar from "@material-ui/core/Avatar";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";

import ActionButton from "lib/components/ActionButton";
import ValueField from "lib/components/ValueField";
import LinearProgress from "@material-ui/core/LinearProgress";
import { LayoutInfo, Layout, LayoutHeader, DesktopMenu, MobileMenu, ScrollView } from "lib/components/layout";
import { MasterDetailRoute, Master, Detail, EmptyDetail, MasterDetailContext } from "lib/components/MasterDetail";
import { EmptyInfoPanel, ErrorInfoPanel } from "lib/components/panels";

import { Formik } from "formik";

import icons from "../../icons";

import backend, { parseErrors, download, upload } from "lib/backend";
import validator from "lib/validator";

import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";

import MoreVertIcon from "@material-ui/icons/MoreVert";

import TestFilter from "components/TestFilter";
import References from "components/References";
import Divider from "@material-ui/core/Divider";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import InputLabel from "@material-ui/core/InputLabel";
import Collapse from "@material-ui/core/Collapse";

import Badge from "@material-ui/core/Badge";
import Autocomplete from "lib/components/Autocomplete";
import AmountField from "lib/components/AmountField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import CurrencyField from "lib/components/CurrencyField";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import { filterIncomes, fetchIncome, saveIncome, deleteIncome } from "./actions";
import { SettingsContext } from "lib/settings";
import formatPersonName from "utils/formatPersonName";

import Modal from "lib/components/Modal";
import { CurrencySelector, PaymentTypeSelector } from "components";
import SearchField from "components/SearchField";

import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";

import { withLoadingIndicator, LoadingIndicator } from "lib/components/LoadingIndicator";

import { useSnackbar } from "notistack";
import { SecurityContext } from "lib/security";
import Menu from "components/menu";
import Alert from "lib/components/Alert";

import { formatDate, formatNum, parseNum } from "lib/format";
import { getCurrency } from "utils/currency";

const outerSpacing = 4;

export const IncomeList = ({ detailId, showDetail, loading, settings, error, filter, onNextPage, data, hasMoreElements, scrollRef, ...props }) => {
    if (error) {
        return (
            <React.Fragment>
                <ErrorInfoPanel title="Chyba" text="Nepodařilo se získat seznam" />
            </React.Fragment>
        );
    }
    if (!loading && !(data && data.length)) {
        return <EmptyInfoPanel text="Seznam je prázdný" disabled={loading} />;
    }

    return (
        <Box width={1} height={1} position="relative" display="flex" flexDirection="column">
            <Box flexGrow={1}>
                <ScrollView vertical loading={loading} onNextElements={onNextPage} hasMoreElements={hasMoreElements} scrollRef={scrollRef}>
                    <List disablePadding>
                        {Array.isArray(data) &&
                            data.map((row, key) => (
                                <ListItem
                                    key={key}
                                    button
                                    onClick={showDetail ? () => showDetail(row.id) : null}
                                    divider
                                    style={row.id == detailId ? { backgroundColor: "#FFFFFF" } : null}>
                                    <ListItemAvatar>
                                        <Avatar>{(row.name && row.name.charAt(0)) || ""}</Avatar>
                                    </ListItemAvatar>
                                    <Box style={{ flex: "1 1 auto", alignSelf: "", overflow: "hidden" }}>
                                        <Typography noWrap display="block">
                                            {row.name}
                                        </Typography>
                                    </Box>
                                </ListItem>
                            ))}
                    </List>
                </ScrollView>
            </Box>
        </Box>
    );
};

export const IncomeForm = ({ detailId, readOnly, loading, settings = {}, formik, currency, ...props }) => {
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, setValues } = formik;
    const disabled = isSubmitting || loading;

    return (
        <Box px={2}>
            <Grid container alignItems="stretch" spacing={outerSpacing}>
                <Grid item lg={9} xs={12}>
                    <React.Fragment>
                        <Box mb={4}>
                            <Paper>
                                <Grid container alignContent="stretch" spacing={outerSpacing}>
                                    <Grid item xs={12} md={6}>
                                        <Box p={outerSpacing}>
                                            <TextField
                                                label="Název"
                                                name="name"
                                                value={values.name || ""}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                InputProps={{ readOnly }}
                                                disabled={disabled}
                                                helperText={errors.name}
                                                error={!!errors.name}
                                            />
                                            <TextField
                                                label="Popis"
                                                name="description"
                                                value={values.description || ""}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                multiline
                                                rows={6}
                                                InputProps={{ readOnly }}
                                                disabled={disabled}
                                                helperText={errors.description}
                                                error={!!errors.description}
                                            />
                                            <TextField
                                                label="Měrná jednotka"
                                                name="unit"
                                                value={values.unit || ""}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                InputProps={{ readOnly }}
                                                disabled={disabled}
                                                helperText={errors.unit}
                                                error={!!errors.unit}
                                            />
                                            <CurrencyField
                                                label="Cena za jednotku"
                                                name="unitPrice"
                                                value={values.unitPrice || ""}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                InputProps={{ readOnly }}
                                                disabled={disabled}
                                                helperText={errors.unitPrice}
                                                error={!!errors.unitPrice}
                                                unit={values.unit}
                                                currency={currency}
                                                readOnly={readOnly}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Box>
                    </React.Fragment>
                </Grid>

                {/* tools */}
                <Grid item lg={3} xs={12}>
                    <References detailId={detailId} disabled={disabled} />
                </Grid>
            </Grid>
        </Box>
    );
};

const IncomeListMaster = (props) => {
    const masterDetailCtx = useContext(MasterDetailContext);
    const scrollRef = useRef();
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }
    }, [props.filter]);

    return (
        <Master>
            <LayoutHeader title="Šablony příjmů" noRightMargin>
                <IconButton
                    onClick={() => {
                        masterDetailCtx.showNew();
                    }}>
                    <AddIcon />
                </IconButton>
            </LayoutHeader>
            <SearchField onFilterChange={props.onFilterChange} />
            <Divider />
            <LoadingIndicator color="secondary" />
            <IncomeList
                loading={props.loading}
                data={props.data}
                hasMoreElements={props.hasMoreElements}
                onNextPage={props.onNextPage}
                showDetail={masterDetailCtx.showDetail}
                scrollRef={scrollRef}
                detailId={masterDetailCtx.detailId}
            />
        </Master>
    );
};

const IncomeMasterDetail = withLoadingIndicator(({ settings, showLoader, hideLoader, isLoading, ...props }) => {
    const masterDetailCtx = useContext(MasterDetailContext);
    const [state, setState] = useState(null);
    const [error, setError] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useContext(SecurityContext);
    const currency = getCurrency(user);

    const loadData = async (id) => {
        let item = {};
        try {
            showLoader();
            if (id != "add") {
                item = await fetchIncome(id);
            }
            setError(false);
            setState(item);
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            hideLoader();
        }
    };

    const handleSubmit = async (values, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        showLoader();
        try {
            values = { ...values, unitPrice: parseNum(values.unitPrice) };
            let c = await saveIncome(values);
            // variant could be success, error, warning, info, or default
            enqueueSnackbar("Uloženo", { variant: "success" });
            masterDetailCtx.refreshList();
            masterDetailCtx.refreshDetail(c);
        } catch (err) {
            setErrors(parseErrors(err));
            enqueueSnackbar("Záznam nelze uložit", { variant: "error" });
        }
        setSubmitting(false);
        hideLoader();
    };

    const handleBack = () => {
        setState(null);
        setError(null);
        masterDetailCtx.showList();
    };

    useEffect(() => {
        if (masterDetailCtx.detailId) {
            loadData(masterDetailCtx.detailId);
        }
    }, [masterDetailCtx.detailId]);

    if (error) {
        return (
            <Detail>
                {masterDetailCtx.showBoth ? null : <LayoutHeader title="Šablony příjmů" noRightMargin></LayoutHeader>}
                <ErrorInfoPanel text="Záznam nelze zobrazit"></ErrorInfoPanel>
            </Detail>
        );
    }

    if (!state && isLoading) {
        return <Detail>{masterDetailCtx.showBoth ? null : <LayoutHeader title="Šablony příjmů" noRightMargin></LayoutHeader>}</Detail>;
    }

    const menuItems = [
        {
            label: "Smazat",
            onClick: () =>
                Alert.alert("Smazat šablonu výdajů", `Chcete smazat šablonu výdajů ${state && state.name} ?`, [
                    { text: "Ne" },
                    {
                        text: "Ano",
                        onPress: async () => {
                            try {
                                showLoader();
                                await deleteIncome(state.id);
                                handleBack();
                                masterDetailCtx.refreshList();
                                enqueueSnackbar("Smazáno", { variant: "success" });
                            } catch (e) {
                                enqueueSnackbar("Záznam nelze smazat", { variant: "error" });
                            }
                            hideLoader();
                        },
                    },
                ]),
        },
    ];

    return (
        <Detail>
            <Formik
                initialValues={state ? { ...state, unitPrice: formatNum(state.unitPrice || 0) } : {}}
                enableReinitialize={true}
                validate={(values) => {
                    let errors = {};

                    if (!values.name) {
                        errors.name = "Povinné pole";
                    }
                    if (!values.unit) {
                        errors.unit = "Povinné pole";
                    }
                    if (!validator.number(values.unitPrice)) {
                        errors.unitPrice = "Zadejte číslo";
                    }
                    return errors;
                }}
                validateOnChange={false}
                onSubmit={handleSubmit}>
                {(formik) => {
                    return (
                        <React.Fragment>
                            <LayoutHeader title={`${formik.values.name || "<bez názvu>"}`}>
                                <DesktopMenu>
                                    <Button variant="contained" onClick={handleBack} disabled={formik.isSubmitting || isLoading}>
                                        Zpět
                                    </Button>
                                    <Menu items={menuItems} disabled={formik.isSubmitting || isLoading} />
                                    <Button variant="contained" color="secondary" onClick={formik.submitForm} disabled={formik.isSubmitting || isLoading}>
                                        Uložit
                                    </Button>
                                </DesktopMenu>
                                <MobileMenu>
                                    <Button onClick={formik.submitForm} disabled={formik.isSubmitting || isLoading}>
                                        Uložit
                                    </Button>
                                    <Menu items={menuItems} disabled={formik.isSubmitting || isLoading} />
                                </MobileMenu>
                            </LayoutHeader>
                            <IncomeForm
                                formik={formik}
                                data={state}
                                detailId={masterDetailCtx.detailId}
                                settings={settings}
                                loading={isLoading}
                                currency={currency}
                            />
                        </React.Fragment>
                    );
                }}
            </Formik>
        </Detail>
    );
});

export default injectIntl(
    withLoadingIndicator((props) => {
        const { values: settings } = useContext(SettingsContext);
        const [list, setList] = useState({ data: [], error: null, filter: {} });
        const masterDetailCtx = useContext(MasterDetailContext);

        const fetchItems = async (resetFilter, filter, page, size, sortBy, sort) => {
            props.showLoader();
            try {
                let s = await filterIncomes(filter, page, size, sortBy, sort);
                let data = resetFilter ? s.content : [...list.data, ...s.content];
                setList({ ...s, data, filter: filter || {} });
            } catch (e) {
                console.error(e);
                setList({
                    error: e,
                });
            }
            props.hideLoader();
        };

        const changeFilter = (filter) => {
            fetchItems(true, { text: filter });
        };

        const fetchNextPage = () => {
            fetchItems(false, list.filter, list.number + 1);
        };

        const refreshList = () => {
            fetchItems(true, { ...list.filter });
        };

        useEffect(() => {
            fetchItems(true);
        }, []);

        return (
            <MasterDetailRoute path={props.path} refreshList={refreshList}>
                <IncomeListMaster
                    loading={props.isLoading}
                    settings={settings}
                    onFilterChange={changeFilter}
                    onNextPage={fetchNextPage}
                    hasMoreElements={list.number < list.totalPages - 1}
                    data={list.data}
                    filter={list.filter && list.filter.text}
                />
                <IncomeMasterDetail settings={settings} />
                <EmptyDetail>
                    <EmptyInfoPanel icon={icons.accounting} text="Vyberte záznam k zobrazení" disabled={props.isLoading} />
                </EmptyDetail>
            </MasterDetailRoute>
        );
    })
);

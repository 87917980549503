import React, { useEffect, useState, useContext, useRef } from "react";

import Container from "@material-ui/core/Container";

import { LayoutHeader } from "lib/components/layout";
import { withLoadingIndicator, LoadingIndicator } from "lib/components/LoadingIndicator";
import { SecurityContext } from "lib/security";
import { EmployeeTimesheet } from "components/timesheet";

export default withLoadingIndicator(({ showLoader, hideLoader, isLoading, currency, ...props }) => {
    const { user } = useContext(SecurityContext);

    return (
        <React.Fragment>
            <LoadingIndicator />
            <LayoutHeader title="Výkazy" />
            <Container maxWidth="lg">
                <EmployeeTimesheet showLoader={showLoader} hideLoader={hideLoader} isLoading={isLoading} employeeId={user.userId} currency={currency} />
            </Container>
        </React.Fragment>
    );
});

import React from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    chart: {
        maxWidth: 125,
        flexGrow: 0,
        padding: theme.spacing(0),
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
}));

export const DashboardPanelWithChart = ({ chart, ...props }) => {
    const classes = useStyles();

    return (
        <Card>
            <CardContent>
                <Box className={classes.root}>
                    <Box className={classes.chart}>{chart}</Box>
                    <Box className={classes.content}>
                        <Typography color="textSecondary" gutterBottom>
                            Word of the Day
                        </Typography>
                        <Typography color="textSecondary">adjective</Typography>
                    </Box>
                </Box>
            </CardContent>
            <CardActions>
                <Button size="small">Learn More</Button>
            </CardActions>
        </Card>
    );
};

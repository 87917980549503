import moment from "moment";

import backend, { parseErrors } from "lib/backend";
import { prepareFormData } from "lib/attachments";

export const filterClients = async (filter, page = 0, size = 20, sortBy, sort) => {
    try {
        let response = await backend("/api/clients", "GET", {
            params: {
                page,
                size,
                sort,
                sortBy,
                filter: filter ? filter.text : null,
            },
        });
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const fetchClient = async (id) => {
    try {
        let response = await backend("/api/clients/" + id);
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const saveClient = async (data = {}) => {
    let formData = prepareFormData(data);
    try {
        let response = await backend("/api/clients/" + (data.id || ""), data.id ? "PUT" : "POST", {
            body: formData,
            upload: true,
        });
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const deleteClient = async (id) => {
    try {
        await backend("/api/clients/" + id, "DELETE");
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const fetchClientOverview = async (id, filter) => {
    try {
        let data = await backend("/api/dashboard/clients/" + id, "GET", { params: { filter } });
        return data.body || {};
    } catch (e) {
        console.log(e);
        throw e;
    }
};

import React, { useContext, useEffect, useState } from "react";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Switch from "@material-ui/core/Switch";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import InputAdornment from "@material-ui/core/InputAdornment";

import Alert from "lib/components/Alert";
import Dialog from "@material-ui/core/Dialog";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ValueField from "lib/components/ValueField";
import Autocomplete from "lib/components/Autocomplete";
import { CurrencySelector } from "components";
import Checkbox from "@material-ui/core/Checkbox";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import LanguageIcon from "@material-ui/icons/Language";
import LockIcon from "@material-ui/icons/Lock";
import DescriptionIcon from "@material-ui/icons/Description";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import PaymentIcon from "@material-ui/icons/Payment";
import PieChartIcon from "@material-ui/icons/PieChart";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import AdjustIcon from "@material-ui/icons/Adjust";
import PaletteIcon from "@material-ui/icons/Palette";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import PersonIcon from "@material-ui/icons/Person";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import WorkIcon from "@material-ui/icons/Work";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import AccountTreeIcon from "@material-ui/icons/AccountTree";

import { Table, TableHeader, TableHeaderActions, TableActions, TableBody, TableRow, TableCell, TableFormCell } from "lib/components/Table";
import Pagination from "@material-ui/lab/Pagination";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import { SecurityContext } from "lib/security";

import { withRouter } from "react-router-dom";

import { formatDate, formatNum, parseNum, formatCurrency, dateToString } from "lib/format";
import validator from "lib/validator";
import icons from "icons";
import { Logo } from "lib/components/public";
import { MasterDetailRoute, Master, Detail, EmptyDetail, MasterDetailContext } from "lib/components/MasterDetail";
import { EmptyInfoPanel, ErrorInfoPanel } from "lib/components/panels";
import { LayoutInfo, Layout, LayoutHeader, DesktopMenu, MobileMenu, ScrollView } from "lib/components/layout";
import { withLoadingIndicator } from "lib/components/LoadingIndicator";

import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { parseErrors } from "lib/backend";
import PeriodSelector, { usePeriod } from "components/period";

import { filterEmployees, employeeReport, employeeReportExcel } from "./actions";

import Collapse from "@material-ui/core/Collapse";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { EmployeeProjectsTable } from "components/timesheet";

import Menu from "components/menu";
//import { changePassword, filterExpenses, saveExpense, deleteExpense, filterIncomes, saveIncome, deleteIncome } from "./actions";
import { getCurrency } from "utils/currency";

const outerSpacing = 4;

const usePaginationStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textAlign: "right",
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    },
}));

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(4) * 2,
        height: theme.spacing(4) * 2,
    },
}));

const ReportsList = withRouter(({ options, history, ...props }) => {
    const masterDetailCtx = useContext(MasterDetailContext);
    return (
        <React.Fragment>
            <LayoutHeader title="Přehledy" />
            <Divider />
            <Box width={1} height={1} position="relative" display="flex" flexDirection="column">
                <Box flexGrow={1}>
                    <ScrollView vertical>
                        <List disablePadding>
                            {options.map((row, idx) => (
                                <ListItem
                                    key={idx}
                                    button
                                    onClick={() => masterDetailCtx.showDetail(row.path)}
                                    divider
                                    style={row.path == masterDetailCtx.detailId ? { backgroundColor: "#FFFFFF" } : null}
                                    disabled={!!row.disabled}
                                    readOnly={!!row.disabled}>
                                    <ListItemAvatar>
                                        <Avatar>{row.icon || (row.name && row.name.charAt(0)) || ""}</Avatar>
                                    </ListItemAvatar>
                                    <Box style={{ flex: "1 1 auto", alignSelf: "", overflow: "hidden" }}>
                                        <Typography noWrap display="block">
                                            {row.name}
                                        </Typography>
                                        <Typography variant="caption" display="block" color="textSecondary">
                                            {row.subtitle}
                                        </Typography>
                                    </Box>
                                </ListItem>
                            ))}
                        </List>
                    </ScrollView>
                </Box>
            </Box>
        </React.Fragment>
    );
});

const ReportsPage = (props) => {
    const masterDetailCtx = useContext(MasterDetailContext);
    const { options, showLoader, hideLoader, isLoading, doExport, title, path, children, menuItems } = props;

    return (
        <React.Fragment>
            <LayoutHeader title={title}>
                <DesktopMenu>
                    <Button variant="contained" onClick={() => masterDetailCtx.showList()} disabled={isLoading}>
                        Zpět
                    </Button>
                    {menuItems && <Menu items={menuItems} disabled={isLoading} />}
                    {!!doExport && (
                        <Button variant="contained" onClick={doExport} disabled={isLoading}>
                            Export
                        </Button>
                    )}
                </DesktopMenu>
                <MobileMenu>
                    {menuItems && <Menu items={menuItems} disabled={isLoading} />}
                    {!!doExport && (
                        <Button onClick={doExport} disabled={isLoading}>
                            Export
                        </Button>
                    )}
                </MobileMenu>
            </LayoutHeader>
            <Box p={3}>{children}</Box>
        </React.Fragment>
    );
};

const useTableStyles = makeStyles((theme) => {
    const transition = {
        duration: theme.transitions.duration.shortest,
    };

    return {
        expandIcon: {
            transform: "rotate(0deg)",
            transition: theme.transitions.create("transform", transition),
        },
        expanded: {
            transform: "rotate(180deg)",
        },
        noPadding: {
            padding: 0,
        },
    };
});

const EmployeeTable = ({ data, renderDetail, showWage, currency }) => {
    const classes = useTableStyles();
    const [open, setOpen] = useState({});
    useEffect(() => {
        setOpen({});
    }, [data]);

    if (!Array.isArray(data)) {
        return null;
    }

    const hasRenderFn = !!renderDetail;
    const renderFn = renderDetail || (() => null);

    return (
        <Table>
            <TableHeader>
                <TableCell xs={6}>Jméno</TableCell>
                <TableCell xs={6} right>
                    Počet hodin
                    {!!showWage && <Typography color="textSecondary">Mzda</Typography>}
                </TableCell>
            </TableHeader>
            <TableBody>
                {Array.isArray(data) &&
                    data.map((row, key) => (
                        <TableRow key={key}>
                            <TableCell xs={6}>
                                {hasRenderFn && (
                                    <IconButton
                                        onClick={() => setOpen({ ...open, [key]: !open[key] })}
                                        className={clsx(classes.expandIcon, {
                                            [classes.expanded]: !!open[key],
                                        })}>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                )}
                                {row.name}
                            </TableCell>
                            <TableCell xs={6} right>
                                {formatNum(row.value || 0)} h
                                {!!showWage && <Typography color="textSecondary">{formatCurrency(row.amount, currency)}</Typography>}
                            </TableCell>
                            <TableCell xs={12} style={{ padding: 0 }}>
                                <Collapse in={!!open[key]}>
                                    <Box p={3}>{renderFn(row)}</Box>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    );
};

export const EmployeeWeeksTable = ({ data, disabled, renderDetail, showWage, currency }) => {
    const classes = useTableStyles();
    const [open, setOpen] = useState({});
    useEffect(() => {
        setOpen({});
    }, [data]);

    if (!Array.isArray(data)) {
        return null;
    }

    const hasRenderFn = !!renderDetail;
    const renderFn = renderDetail || (() => null);

    return (
        <Table>
            <TableHeader>
                <TableCell xs={6}>Týden</TableCell>
                <TableCell xs={6} right>
                    Počet hodin
                    {!!showWage && <Typography color="textSecondary">Mzda</Typography>}
                </TableCell>
            </TableHeader>
            <TableBody>
                {Array.isArray(data) &&
                    data.map((row, key) => (
                        <TableRow key={key}>
                            <TableCell xs={6}>
                                <IconButton
                                    onClick={() => setOpen({ ...open, [key]: !open[key] })}
                                    className={clsx(classes.expandIcon, {
                                        [classes.expanded]: !!open[key],
                                    })}>
                                    <ExpandMoreIcon />
                                </IconButton>
                                {formatDate(row.start)} - {formatDate(row.end)}
                            </TableCell>
                            <TableCell xs={6} right>
                                {formatNum(row.value || 0)} h
                                {!!showWage && <Typography color="textSecondary">{formatCurrency(row.amount, currency)}</Typography>}
                            </TableCell>
                            <TableCell xs={12} style={{ padding: 0 }}>
                                <Collapse in={!!open[key]}>
                                    <Box p={3}>{renderFn(row)}</Box>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    );
};

export const EmployeeDaysTable = ({ data, disabled, renderDetail, showWage, currency }) => {
    const classes = useTableStyles();
    const [open, setOpen] = useState({});
    useEffect(() => {
        setOpen({});
    }, [data]);

    if (!Array.isArray(data)) {
        return null;
    }

    const hasRenderFn = !!renderDetail;
    const renderFn = renderDetail || (() => null);

    return (
        <Table>
            <TableHeader>
                <TableCell xs={6}>Datum</TableCell>
                <TableCell xs={6} right>
                    Počet hodin
                    {!!showWage && <Typography color="textSecondary">Mzda</Typography>}
                </TableCell>
            </TableHeader>
            <TableBody>
                {Array.isArray(data) &&
                    data.map((row, key) => (
                        <TableRow key={key}>
                            <TableCell xs={6}>
                                <IconButton
                                    onClick={() => setOpen({ ...open, [key]: !open[key] })}
                                    className={clsx(classes.expandIcon, {
                                        [classes.expanded]: !!open[key],
                                    })}>
                                    <ExpandMoreIcon />
                                </IconButton>
                                {formatDate(row.start)}
                            </TableCell>
                            <TableCell xs={6} right>
                                {formatNum(row.value || 0)} h
                                {!!showWage && <Typography color="textSecondary">{formatCurrency(row.amount, currency)}</Typography>}
                            </TableCell>
                            <TableCell xs={12} style={{ padding: 0 }}>
                                <Collapse in={!!open[key]}>
                                    <Box p={3}>{renderFn(row)}</Box>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    );
};

const EmployeeReport = withLoadingIndicator(({ showLoader, hideLoader, isLoading, path, ...props }) => {
    const { enqueueSnackbar } = useSnackbar();
    const periodRef = usePeriod();
    const [period] = periodRef;
    const [filter, setFilter] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [data, setData] = useState({});
    const { user } = useContext(SecurityContext);
    const currency = getCurrency(user);
    const switchTab = (event, newValue) => setActiveTab(newValue);

    const reload = async (filter, period) => {
        showLoader();
        try {
            let ids = (Array.isArray(filter) && filter.map((i) => i.id)) || [];
            let data = await employeeReport(ids, period.start, period.end);
            setData(data);
        } catch (e) {
            console.error(e);
            enqueueSnackbar("Nepodařilo se načíst data", { variant: "error" });
        } finally {
            hideLoader();
        }
    };

    const exportToExcel = async (filter, period) => {
        showLoader();
        try {
            let ids = (Array.isArray(filter) && filter.map((i) => i.id)) || [];
            console.log(ids, period.start, period.end);
            await employeeReportExcel(ids, period.start, period.end);
        } catch (e) {
            console.error(e);
            enqueueSnackbar("Nepodařilo se načíst data", { variant: "error" });
        } finally {
            hideLoader();
        }
    };

    useEffect(() => {
        reload(filter, period);
    }, [filter, period]);

    return (
        <Detail detailIdPath={path}>
            <ReportsPage title="Zaměstnanci" path={path} doExport={() => exportToExcel(filter, period)} isLoading={isLoading}>
                <PeriodSelector periodRef={periodRef} disabled={isLoading} />

                <Box pt={4}>
                    <Autocomplete
                        label="Zaměstnanci"
                        id="employees"
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => value.id == option.id}
                        fetchOptions={async () => filterEmployees()}
                        onChange={(e, options) => setFilter(options)}
                        value={filter || []}
                        fullWidth
                        disabled={isLoading}
                        margin="dense"
                        loadingText="Načítají se hodnoty..."
                        noOptionsText="Žádné hodnoty"
                        multiple
                    />
                </Box>
                <Box py={2}>
                    <Tabs indicatorColor="primary" textColor="primary" value={activeTab} onChange={switchTab} disabled={isLoading}>
                        <Tab label="Souhrn" disabled={isLoading} />
                        <Tab label="Týdenní přehled" disabled={isLoading} />
                        <Tab label="Denní přehled" disabled={isLoading} />
                    </Tabs>
                    <Box py={3}>
                        {activeTab == 0 && (
                            <EmployeeTable
                                data={data.all}
                                showWage
                                renderDetail={(row) => <EmployeeProjectsTable data={row.children} showWage currency={currency} />}
                                currency={currency}
                            />
                        )}
                        {activeTab == 1 && (
                            <EmployeeWeeksTable
                                data={data.weeks}
                                currency={currency}
                                showWage
                                renderDetail={(row) => (
                                    <EmployeeTable
                                        data={row.children}
                                        renderDetail={(row) => <EmployeeProjectsTable data={row.children} showWage currency={currency} />}
                                        showWage
                                    />
                                )}
                                showWage
                            />
                        )}
                        {activeTab == 2 && (
                            <EmployeeDaysTable
                                data={data.days}
                                currency={currency}
                                showWage
                                renderDetail={(row) => (
                                    <EmployeeTable
                                        data={row.children}
                                        renderDetail={(row) => <EmployeeProjectsTable data={row.children} showWage currency={currency} />}
                                        showWage
                                    />
                                )}
                                showWage
                            />
                        )}
                    </Box>
                </Box>
            </ReportsPage>
        </Detail>
    );
});

export default withRouter(({ history, ...props }) => {
    const basePath = "/reports";

    const options = [
        {
            name: "Zaměstnanci",
            path: "employees",
            icon: <PersonIcon />,
        },
        {
            name: "Klienti",
            path: "clients",
            icon: <WorkIcon />,
            disabled: true,
        },
        {
            name: "Projekty",
            path: "projects",
            icon: <AccountTreeIcon />,
            disabled: true,
        },
    ];

    return (
        <MasterDetailRoute path={basePath}>
            <Master>
                <ReportsList options={options} />
            </Master>
            <EmployeeReport path="employees" />

            <EmptyDetail>
                <EmptyInfoPanel icon={null} text="Vyberte přehled" disabled={props.isLoading} />
            </EmptyDetail>
        </MasterDetailRoute>
    );
});

/*

employees: [
    {name, hours, wage, projects}
],

weeks: [
    {from, to, employees: [ x ]}
]

days: [
    {from, to, employees: [ x ]}
]

*/

import React, { useEffect, useState, createContext, useContext, useMemo, useRef } from "react";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";

import Grid from "@material-ui/core/Grid";

import ButtonBar from "./ButtonBar";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { withRouter, useLocation } from "react-router-dom";
import { SecurityContext } from "lib/security";
import clsx from "clsx";

import { throttle } from "lodash";

//TODO fix styles!!!

const noop = () => null;

const createScrollViewClasses = makeStyles((theme) => ({
    root: {
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },
    content: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
    },
    vertical: {
        overflowY: "auto",
    },
    horizontal: {
        overflowX: "auto",
    },
    center: {
        textAlign: "center",
        padding: theme.spacing(3),
    },
    loadMore: {
        fontSize: "0.8em",
    },
}));

//TODO scrollable

export const ScrollView = ({ vertical, horizontal, loading, hasMoreElements, onNextElements, children, scrollRef, ...props }) => {
    const classes = createScrollViewClasses();

    const onScroll = (e) => {
        if (loading) {
            return;
        }
        let element = e.target;
        if (element && element.scrollHeight - element.scrollTop === element.clientHeight && hasMoreElements && typeof onNextElements === "function") {
            setTimeout(onNextElements, 250);
        }
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.vertical]: vertical,
                [classes.horizontal]: horizontal,
            })}
            onScroll={onScroll}
            ref={scrollRef}>
            <div className={classes.content}>
                {children}
                {hasMoreElements && !loading ? (
                    <Box className={clsx(classes.center, classes.loadMore)}>
                        <Link
                            href="#more"
                            color="textSecondary"
                            underline="none"
                            onClick={(e) => {
                                e.preventDefault();
                                onNextElements();
                            }}>
                            načíst další...
                        </Link>
                    </Box>
                ) : null}
                {!!loading ? (
                    <Box className={classes.center}>
                        <CircularProgress disableShrink size={16} />
                    </Box>
                ) : null}
            </div>
        </div>
    );
};

export const FullBox = ({ children, ...props }) => {
    return <Box {...props}>{children}</Box>;
};

export const LayoutContext = createContext({
    menuItems: [],
    title: "",
    open: false,
    toggleDrawer: noop,
    setTitle: noop,
    activeMenu: null,
});

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",

        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
    },
    rootContent: {
        flexGrow: 1,
        position: "relative",
    },
    appBar: {
        ...theme.mixins.toolbar,
        flexGrow: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        zIndex: 2,
        border: 0,
    },
    actions: {
        selfAlign: "end",
        whiteSpace: "nowrap",
        "& .MuiButtonBase-root": {
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
        "& .MuiList-root .MuiButtonBase-root": {
            color: theme.palette.text.color,
        },
    },
    title: {
        flexGrow: 1,
    },
    titleMobile: {
        flexGrow: 1,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },

    drawer: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        borderRight: "none",
        zIndex: 1,
    },
    drawerButton: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        marginLeft: -3,
    },
    menuButton: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    largeAppBar: {
        background: "none",
        color: theme.palette.getContrastText(theme.palette.background.default),
        zIndex: 1,
        border: 0,
    },

    miniRoot: {
        display: "flex",
        flexDirection: "row-reverse",
        flexGrow: 1,
        width: "100%",
        height: "100%",
    },
    main: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
        overflowY: "auto",
    },

    miniDrawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    miniDrawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(7),
        },
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    toolbarPlaceholder: {
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    menuItemRow: {
        maxHeight: theme.spacing(6),
        overflow: "hidden",
        "&:hover": {
            backgroundColor: emphasize(theme.palette.primary.main, 0.3),
        },
    },
    menuText: {
        whiteSpace: "nowrap",
    },
    activeMenu: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        "&:hover": {
            backgroundColor: () => emphasize(theme.palette.secondary.main, 0.3),
        },
    },
}));

const MenuContext = createContext({
    open: false,
    setMenuOpen: () => null,
});

export const MenuToggleButton = (props) => {
    const classes = useStyles();
    const { open, setOpen } = useContext(MenuContext);
    return (
        <div className={classes.toolbar}>
            <IconButton className={classes.drawerButton} onClick={() => setOpen(!open)}>
                <MenuIcon />
            </IconButton>
        </div>
    );
};

// layout copied from material-ui
const MenuLayout = withRouter(({ history, children, intl, menuItems }) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = !useMediaQuery(theme.breakpoints.up("md"));
    const location = useLocation();

    function navigate(target) {
        return () => {
            setOpen(false);
            history.push(target);
        };
    }

    const drawerContent = [];

    menuItems.forEach((menu) => {
        const itemClasses = clsx({
            [classes.menuItemRow]: true,
            [classes.activeMenu]: menu.pathname == "/" ? location.pathname == menu.pathname : location.pathname.startsWith(menu.pathname),
        });
        if (menu.divider) {
            drawerContent.push(<Divider key={`d${menu.id}`} />);
        }

        drawerContent.push(
            <List key={`m${menu.id}`}>
                <ListItem button onClick={menu.action || navigate(menu.pathname)} title={menu.label || menu.id} className={itemClasses}>
                    {menu.icon ? <ListItemIcon className={classes.menuButton}>{menu.icon}</ListItemIcon> : null}
                    <ListItemText className={classes.menuText}>{menu.label || menu.id}</ListItemText>
                </ListItem>
            </List>
        );
    });

    document.getElementById("root").className = "fullscreen";

    return (
        <MenuContext.Provider value={{ open, setOpen }}>
            <div className={isMobile ? classes.root : classes.miniRoot}>
                <Layout>{children}</Layout>

                <Hidden mdUp>
                    <SwipeableDrawer
                        open={!!open}
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        style={{ zIndex: 1400 }}
                        classes={{ paper: classes.drawer }}
                        pt={8}>
                        {isMobile ? <div className={classes.toolbarPlaceholder}></div> : null}
                        {drawerContent}
                    </SwipeableDrawer>
                </Hidden>

                {/* TODO */}
                <Hidden smDown>
                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.miniDrawerOpen]: open,
                            [classes.miniDrawerClose]: !open,
                        })}
                        classes={{
                            paper: clsx(classes.drawer, {
                                [classes.miniDrawerOpen]: open,
                                [classes.miniDrawerClose]: !open,
                            }),
                        }}
                        PaperProps={{ elevation: 16 }}>
                        <MenuToggleButton />
                        {drawerContent}
                    </Drawer>
                </Hidden>
            </div>
        </MenuContext.Provider>
    );
});

export const Layout = ({ children }) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Hidden mdUp>
                <div className={classes.toolbarPlaceholder}></div>
            </Hidden>
            <main className={classes.main}>{children}</main>
        </React.Fragment>
    );
};

export const LayoutContent = ({ children }) => {
    const classes = useStyles();
    return <div className={classes.rootContent}>{children}</div>;
};

export const LayoutHeader = ({ title, children, noRightMargin }) => {
    const classes = useStyles();
    const style = noRightMargin ? { paddingRight: 0 } : null;

    return (
        <React.Fragment>
            <Hidden mdUp>
                <AppBar position="fixed" className={classes.appBar} PaperProps={{ elevation: 16 }}>
                    <MenuToggleButton />
                    <Typography variant="h6" className={classes.titleMobile}>
                        {title}
                    </Typography>
                    {children ? <div className={classes.actions}>{children}</div> : null}
                </AppBar>
            </Hidden>

            <Hidden smDown>
                <AppBar position="static" elevation={0} className={classes.largeAppBar}>
                    <Toolbar style={style}>
                        <Typography variant="h6" className={classes.title}>
                            {title}
                        </Typography>
                        {children}
                    </Toolbar>
                </AppBar>
            </Hidden>
        </React.Fragment>
    );
};

export const DesktopMenu = (props) => (
    <Hidden smDown>
        <ButtonBar>{props.children}</ButtonBar>
    </Hidden>
);
export const MobileMenu = (props) => (
    <Hidden mdUp>
        <ButtonBar>{props.children}</ButtonBar>
    </Hidden>
);

export const Menu = MenuLayout;

export default Menu;

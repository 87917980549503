import React, { useContext, useEffect, useState } from "react";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Switch from "@material-ui/core/Switch";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import InputAdornment from "@material-ui/core/InputAdornment";
import PasswordField from "lib/components/PasswordField";
import Link from "@material-ui/core/Link";

import Alert from "lib/components/Alert";
import Dialog from "@material-ui/core/Dialog";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ValueField from "lib/components/ValueField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { CurrencySelector } from "components";
import Checkbox from "@material-ui/core/Checkbox";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import LanguageIcon from "@material-ui/icons/Language";
import LockIcon from "@material-ui/icons/Lock";
import DescriptionIcon from "@material-ui/icons/Description";
import SettingsIcon from "@material-ui/icons/Settings";
import PaymentIcon from "@material-ui/icons/Payment";
import PieChartIcon from "@material-ui/icons/PieChart";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import AdjustIcon from "@material-ui/icons/Adjust";
import PaletteIcon from "@material-ui/icons/Palette";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import BusinessIcon from "@material-ui/icons/Business";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

import {
    Table,
    TableHeader,
    TableHeaderActions,
    TableActions,
    TableBody,
    TableRow,
    TableCell,
    TableFormCell,
    TablePagination,
    useInMemoryPaging,
} from "lib/components/Table";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import { SettingsContext } from "lib/settings";
import { SecurityContext } from "lib/security";

import { withRouter } from "react-router-dom";

import { formatDate, formatNum, parseNum, formatCurrency, formatAmount, dateToString } from "lib/format";
import validator from "lib/validator";
import icons from "icons";
import { Logo } from "lib/components/public";
import { MasterDetailRoute, Master, Detail, EmptyDetail, MasterDetailContext } from "lib/components/MasterDetail";
import { EmptyInfoPanel, ErrorInfoPanel } from "lib/components/panels";
import { LayoutInfo, Layout, LayoutHeader, DesktopMenu, MobileMenu, ScrollView } from "lib/components/layout";
import { withLoadingIndicator } from "lib/components/LoadingIndicator";

import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { parseErrors } from "lib/backend";

import {
    changePassword,
    saveBusinessInfo,
    filterExpenses,
    saveExpense,
    deleteExpense,
    filterIncomes,
    saveIncome,
    deleteIncome,
    saveCurrencies,
} from "./actions";

import CurrencyField from "lib/components/CurrencyField";
import { getCurrency } from "utils/currency";

const outerSpacing = 4;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(4) * 2,
        height: theme.spacing(4) * 2,
    },
}));

const SettingsItem = withRouter(({ history, match, path = "settings", title, description, href, icon, disabled }) => {
    const classes = useStyles();
    return (
        <ListItem button={!!href} onClick={!!href ? () => history.push(`${path}${href}`) : null} divider disabled={disabled}>
            <Box style={{ flex: "0 0 auto" }} p={3}>
                <ListItemAvatar>
                    <Avatar className={classes.large}>{icon}</Avatar>
                </ListItemAvatar>
            </Box>
            <Box style={{ flex: "1 1 auto", overflow: "hidden" }} p={2}>
                <Typography variant="h6" noWrap display="block" gutterBottom={!!description}>
                    {title}
                </Typography>
                {description ? (
                    <Typography variant="subtitle1" display="block" color="textSecondary">
                        {description}
                    </Typography>
                ) : null}
            </Box>
            {href ? (
                <ListItemSecondaryAction>
                    <ChevronRightIcon />
                </ListItemSecondaryAction>
            ) : null}
        </ListItem>
    );
});

const SettingsList = withRouter(({ options, history, ...props }) => {
    const masterDetailCtx = useContext(MasterDetailContext);
    return (
        <React.Fragment>
            <LayoutHeader title="Nastavení" />
            <Divider />
            <Box width={1} height={1} position="relative" display="flex" flexDirection="column">
                <Box flexGrow={1}>
                    <ScrollView vertical>
                        {options.map((row, idx) => (
                            <List disablePadding key={idx}>
                                <ListItem
                                    key={idx}
                                    button
                                    onClick={() => masterDetailCtx.showDetail(row.path)}
                                    divider
                                    style={row.path == masterDetailCtx.detailId ? { backgroundColor: "#FFFFFF" } : null}
                                    disabled={!!row.disabled}>
                                    <ListItemAvatar>
                                        <Avatar>{row.icon || (row.name && row.name.charAt(0)) || ""}</Avatar>
                                    </ListItemAvatar>
                                    <Box style={{ flex: "1 1 auto", alignSelf: "", overflow: "hidden" }}>
                                        <Typography noWrap display="block">
                                            {row.name}
                                        </Typography>
                                        <Typography variant="caption" display="block" color="textSecondary">
                                            {row.subtitle}
                                        </Typography>
                                    </Box>
                                </ListItem>
                            </List>
                        ))}
                    </ScrollView>
                </Box>
            </Box>
        </React.Fragment>
    );
});

const SettingsPage = (props) => {
    const masterDetailCtx = useContext(MasterDetailContext);
    const { options, showLoader, hideLoader, isLoading, doSubmit, title, path, children } = props;

    return (
        <React.Fragment>
            <LayoutHeader title={title}>
                <DesktopMenu>
                    <Button variant="contained" onClick={() => masterDetailCtx.showList()} disabled={isLoading}>
                        Zpět
                    </Button>
                    {!!props.doSubmit && (
                        <Button onClick={doSubmit} variant="contained" color="secondary" disabled={isLoading}>
                            Uložit
                        </Button>
                    )}
                </DesktopMenu>
                <MobileMenu>
                    {!!props.doSubmit && (
                        <IconButton onClick={doSubmit}>
                            <SaveIcon />
                        </IconButton>
                    )}
                </MobileMenu>
            </LayoutHeader>
            <Box p={3}>{children}</Box>
        </React.Fragment>
    );
};

const PasswordSettings = withLoadingIndicator((props) => {
    const { enqueueSnackbar } = useSnackbar();
    const { logout } = useContext(SecurityContext);

    const handleSubmit = async ({ oldPassword, newPassword, passwordCheck }) => {
        try {
            props.showLoader();
            await changePassword(oldPassword, newPassword, passwordCheck);
            enqueueSnackbar("Heslo bylo změněno", { variant: "success" });
            props.hideLoader();
            logout();
        } catch (e) {
            props.hideLoader();
            enqueueSnackbar("Heslo nelze změnit", { variant: "error" });
            console.error(e);
        }
    };
    const disabled = props.isLoading,
        readOnly = false;

    return (
        <Detail detailIdPath={props.path}>
            <Formik
                initialValues={{}}
                enableReinitialize={true}
                validate={(values) => {
                    let errors = {};
                    if (!values.oldPassword) {
                        errors.oldPassword = "Povinné pole";
                    }
                    if (!values.newPassword) {
                        errors.newPassword = "Povinné pole";
                    }
                    if (!values.passwordCheck) {
                        errors.passwordCheck = "Povinné pole";
                    }

                    if (values.oldPassword && values.newPassword && values.passwordCheck) {
                        if (values.oldPassword == values.newPassword) {
                            errors.newPassword = errors.oldPassword = "Hesla se shodují";
                        } else if (values.newPassword != values.passwordCheck) {
                            errors.newPassword = errors.passwordCheck = "Hesla se neshodují";
                        }
                    }

                    return errors;
                }}
                validateOnChange={false}
                onSubmit={handleSubmit}>
                {({ submitForm, values, errors, handleChange }) => {
                    return (
                        <SettingsPage title="Heslo" path={props.path} doSubmit={submitForm}>
                            <Grid container alignItems="stretch" spacing={outerSpacing}>
                                <Grid item xs={12} sm={6}>
                                    <Box mb={4}>
                                        <Paper>
                                            <Box p={outerSpacing}>
                                                <PasswordField
                                                    label="Stávající heslo"
                                                    name="oldPassword"
                                                    value={values.oldPassword || ""}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    margin="normal"
                                                    InputProps={{ readOnly }}
                                                    disabled={disabled}
                                                    helperText={errors.oldPassword}
                                                    error={!!errors.oldPassword}
                                                />
                                                <PasswordField
                                                    label="Nové heslo"
                                                    name="newPassword"
                                                    value={values.newPassword || ""}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    type="password"
                                                    margin="normal"
                                                    InputProps={{ readOnly }}
                                                    disabled={disabled}
                                                    helperText={errors.newPassword}
                                                    error={!!errors.newPassword}
                                                />
                                                <PasswordField
                                                    label="Potvrzení nového hesla"
                                                    name="passwordCheck"
                                                    value={values.passwordCheck || ""}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    type="password"
                                                    margin="normal"
                                                    InputProps={{ readOnly }}
                                                    disabled={disabled}
                                                    helperText={errors.passwordCheck}
                                                    error={!!errors.passwordCheck}
                                                />
                                            </Box>
                                        </Paper>
                                    </Box>
                                </Grid>
                            </Grid>
                        </SettingsPage>
                    );
                }}
            </Formik>
        </Detail>
    );
});

/////////////////////////// BACKUP ///////////////////////////

const BackupSettings = withLoadingIndicator((props) => {
    const { enqueueSnackbar } = useSnackbar();

    const disabled = props.isLoading,
        readOnly = false;

    return (
        <Detail detailIdPath={props.path}>
            <SettingsPage title="Zálohování" path={props.path}></SettingsPage>
        </Detail>
    );
});

/////////////////////////// BUSINESS ///////////////////////////

const GeneralSettings = withLoadingIndicator((props) => {
    const { enqueueSnackbar } = useSnackbar();
    const { updateProfile, user } = useContext(SecurityContext);

    const handleSubmit = async (values) => {
        try {
            props.showLoader();
            let profile = await saveBusinessInfo(user.id, values);
            enqueueSnackbar("Data byla uložena", { variant: "success" });
            updateProfile(profile);
            props.hideLoader();
        } catch (e) {
            props.hideLoader();
            enqueueSnackbar("Při ukládání došlo k chybě", { variant: "error" });
            console.error(e);
        }
    };
    const disabled = props.isLoading,
        readOnly = false;

    return (
        <Detail detailIdPath={props.path}>
            <Formik
                initialValues={user}
                enableReinitialize={true}
                validate={(values) => {
                    let errors = {};
                    if (!values.name) {
                        errors.name = "Povinné pole";
                    }
                    /*
                    if (!values.replyToEmail) {
                        errors.replyToEmail = "Povinné pole";
                    } else if (validator.email(values.replyToEmail)) {
                        errors.replyToEmail = "Neplatný e-mail";
                    }
                    */
                    return errors;
                }}
                validateOnChange={false}
                onSubmit={handleSubmit}>
                {({ submitForm, values, errors, handleChange }) => {
                    return (
                        <SettingsPage title="Obecné" path={props.path} doSubmit={submitForm}>
                            <Paper>
                                <Grid container alignItems="stretch" spacing={outerSpacing}>
                                    <Grid item xs={12} md={6}>
                                        <Box p={outerSpacing}>
                                            <TextField
                                                label="Název společnosti"
                                                name="name"
                                                value={values.name || ""}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                InputProps={{ readOnly }}
                                                disabled={disabled}
                                                helperText={errors.name}
                                                error={!!errors.name}
                                            />
                                            {/*<TextField
                                                label="Zobrazovaná e-mailová adresa"
                                                name="replyToEmail"
                                                value={values.replyToEmail || ""}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                InputProps={{ readOnly }}
                                                disabled={disabled}
                                                helperText={errors.replyToEmail}
                                                error={!!errors.replyToEmail}
                                            />*/}
                                            <TextField
                                                label="Adresa"
                                                name="address"
                                                value={values.address || ""}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                InputProps={{ readOnly }}
                                                disabled={disabled}
                                                helperText={errors.address}
                                                error={!!errors.address}
                                                multiline
                                                rows={4}
                                            />
                                            <TextField
                                                label="IČ"
                                                name="idNumber"
                                                value={values.idNumber || ""}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                InputProps={{ readOnly }}
                                                disabled={disabled}
                                                helperText={errors.idNumber}
                                                error={!!errors.idNumber}
                                            />
                                            <TextField
                                                label="DIČ"
                                                name="taxIdNumber"
                                                value={values.taxIdNumber || ""}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                InputProps={{ readOnly }}
                                                disabled={disabled}
                                                helperText={errors.taxIdNumber}
                                                error={!!errors.taxIdNumber}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box p={outerSpacing}>
                                            <Box display="flex" alignItems="center">
                                                <Box flexGrow={1} color={values.separateClientField && !disabled && !true ? "text.primary" : "text.secondary"}>
                                                    <Typography>Při vykazování zadávat klienta samostatně</Typography>
                                                </Box>
                                                <Switch
                                                    color="secondary"
                                                    checked={values.separateClientField || false}
                                                    onChange={handleChange}
                                                    name="separateClientField"
                                                    value={true}
                                                    disabled={disabled || true}
                                                />
                                            </Box>

                                            <Box display="flex" alignItems="center">
                                                <Box flexGrow={1} color={values.shareExpenses && !disabled ? "text.primary" : "text.secondary"}>
                                                    <Typography>Sdílet zobrazování výdajů mezi zaměstnanci</Typography>
                                                </Box>
                                                <Switch
                                                    color="secondary"
                                                    checked={values.shareExpenses || false}
                                                    onChange={handleChange}
                                                    name="shareExpenses"
                                                    value={true}
                                                    disabled={disabled}
                                                />
                                            </Box>

                                            <Box display="flex" alignItems="center">
                                                <Box flexGrow={1} color={values.showAllProjectAttachments && !disabled ? "text.primary" : "text.secondary"}>
                                                    <Typography>Zobrazovat všechny přílohy projektu v jednom seznamu</Typography>
                                                </Box>
                                                <Switch
                                                    color="secondary"
                                                    checked={values.showAllProjectAttachments}
                                                    onChange={handleChange}
                                                    name="showAllProjectAttachments"
                                                    value={true}
                                                    disabled={disabled}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </SettingsPage>
                    );
                }}
            </Formik>
        </Detail>
    );
});

/////////////////////////// EXPENSES ///////////////////////////

const ExpenseRow = ({ item, onEdit, onDelete, disabled, currency }) => {
    return (
        <TableRow>
            <TableCell item xs={12} md={4}>
                <Typography display="block">{item.name}</Typography>
                {!!item.description && (
                    <Typography display="block" color="textSecondary">
                        {item.description}
                    </Typography>
                )}
            </TableCell>
            <TableCell item xs={12} md={1} center>
                {item.unit}
            </TableCell>
            <TableCell item xs={12} md={2} right>
                <Typography display="block">
                    {formatCurrency(item.unitPrice, currency)}
                    {item.unit ? "/" + item.unit : null}
                </Typography>
            </TableCell>

            <TableCell item xs={12} md={3} right>
                {!!item.billable ? "Ano" : "Ne"}
                <Typography display="block" color="textSecondary">
                    {formatCurrency(item.billableUnitPrice, currency)}
                    {item.unit ? "/" + item.unit : null}
                </Typography>
            </TableCell>

            <TableActions xs={12} md={2}>
                <IconButton onClick={onEdit} disabled={disabled}>
                    <CreateIcon fontSize="small" />
                </IconButton>
                <IconButton onClick={onDelete} disabled={disabled}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </TableActions>
        </TableRow>
    );
};

const ExpenseRowForm = ({ item, onSubmit, onCancel, showProject, showEmployee, disabled, readOnly, showPrice, currency }) => {
    return (
        <TableRow>
            <Formik
                initialValues={
                    item
                        ? {
                              ...item,
                              unitPrice: formatNum(item.unitPrice || 0),
                              billableUnitPrice: formatNum(item.billableUnitPrice || 0),
                          }
                        : {}
                }
                enableReinitialize={true}
                validate={(values) => {
                    let errors = {};

                    if (!values.name) {
                        errors.name = "Povinné pole";
                    }
                    if (!values.unit) {
                        errors.unit = "Povinné pole";
                    }
                    if (!values.unitPrice) {
                        errors.unitPrice = "Povinné pole";
                    } else if (!validator.decimal(values.unitPrice)) {
                        errors.unitPrice = "Chybný formát čísla";
                    }
                    if (values.billable) {
                        if (!values.billableUnitPrice) {
                            errors.billableUnitPrice = "Povinné pole";
                        } else if (!validator.decimal(values.billableUnitPrice)) {
                            errors.billableUnitPrice = "Chybný formát čísla";
                        }
                    }
                    return errors;
                }}
                validateOnChange={false}
                onSubmit={(values, formikBag) => {
                    onSubmit &&
                        onSubmit(
                            {
                                ...values,
                                unitPrice: parseNum(values.unitPrice),
                                billableUnitPrice: parseNum(values.billableUnitPrice),
                            },
                            formikBag
                        );
                }}>
                {({ values, setFieldValue, setValues, errors, handleChange, handleBlur, submitForm }) => {
                    return (
                        <React.Fragment>
                            <TableFormCell item xs={12} md={4}>
                                <TextField
                                    label="Název"
                                    name="name"
                                    value={values.name || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    InputProps={{ readOnly }}
                                    disabled={disabled}
                                    helperText={errors.name}
                                    error={!!errors.name}
                                />
                                <TextField
                                    label="Popis"
                                    name="description"
                                    value={values.description || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    InputProps={{ readOnly }}
                                    disabled={disabled}
                                    helperText={errors.description}
                                    error={!!errors.description}
                                />
                            </TableFormCell>
                            <TableFormCell item xs={12} md={1} center>
                                <TextField
                                    label="Jednotka"
                                    name="unit"
                                    value={values.unit || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    disabled={disabled}
                                    helperText={errors.unit}
                                    error={!!errors.unitt}
                                />
                            </TableFormCell>
                            <TableFormCell xs={12} md={2}>
                                <CurrencyField
                                    label="Jednotková cena"
                                    name="unitPrice"
                                    value={values.unitPrice || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    currency={currency}
                                    readOnly={readOnly}
                                    unit={values.unit}
                                    disabled={disabled}
                                    helperText={errors.unitPrice}
                                    error={!!errors.unitPrice}
                                />
                            </TableFormCell>
                            <TableFormCell xs={12} md={3} right>
                                <Switch
                                    color="secondary"
                                    checked={values.billable || false}
                                    onChange={handleChange}
                                    name="billable"
                                    value={true}
                                    disabled={disabled}
                                />
                                {!!values.billable && (
                                    <CurrencyField
                                        label="Fakturovaná cena za jednotku"
                                        name="billableUnitPrice"
                                        value={values.billableUnitPrice || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        margin="dense"
                                        currency={currency}
                                        readOnly={readOnly}
                                        unit={values.unit}
                                        disabled={disabled}
                                        helperText={errors.billableUnitPrice}
                                        error={!!errors.billableUnitPrice}
                                    />
                                )}
                            </TableFormCell>
                            <TableActions item xs={12} md={2}>
                                <IconButton onClick={submitForm} disabled={disabled}>
                                    <CheckIcon fontSize="small" />
                                </IconButton>
                                <IconButton onClick={onCancel} disabled={disabled}>
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            </TableActions>
                        </React.Fragment>
                    );
                }}
            </Formik>
        </TableRow>
    );
};

const ExpenseSettings = withLoadingIndicator(({ showLoader, hideLoader, isLoading, path }) => {
    const [data, setData] = useState({});
    const [formIndex, setFormIndex] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useContext(SecurityContext);
    const currency = getCurrency(user);

    const filterList = async (filter = "", page = 0, size = 10) => {
        try {
            showLoader();
            let data = await filterExpenses(filter, page, size);
            setFormIndex(false);
            setData(data);
        } catch (e) {
            enqueueSnackbar("Nelze načíst data", { variant: "error" });
            setData({});
        } finally {
            hideLoader();
        }
    };

    const handleSubmit = async (values, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        showLoader();
        try {
            values = { ...values, unitPrice: parseNum(values.unitPrice) };
            let c = await saveExpense(values);
            enqueueSnackbar("Uloženo", { variant: "success" });
            filterList("", data.page, data.size);
        } catch (err) {
            setErrors(parseErrors(err));
            enqueueSnackbar("Záznam nelze uložit", { variant: "error" });
        }
        setSubmitting(false);
        hideLoader();
    };

    const deleteItem = (state) =>
        Alert.alert("Smazat šablonu výdajů", `Chcete smazat šablonu výdajů ${state && state.name} ?`, [
            { text: "Ne" },
            {
                text: "Ano",
                onPress: async () => {
                    try {
                        showLoader();
                        await deleteExpense(state.id);
                        filterList("", data.page, data.size);
                        enqueueSnackbar("Smazáno", { variant: "success" });
                    } catch (e) {
                        enqueueSnackbar("Záznam nelze smazat", { variant: "error" });
                    }
                    hideLoader();
                },
            },
        ]);

    const disabled = isLoading,
        readOnly = false;

    useEffect(() => {
        filterList();
    }, []);

    console.log(data);

    return (
        <Detail detailIdPath={path}>
            <SettingsPage title="Šablony výdajů" path={path}>
                <TableHeader container>
                    <TableCell item xs={12} md={4}>
                        Název
                        <Typography color="textSecondary">Popis</Typography>
                    </TableCell>
                    <TableCell item xs={12} md={1} center>
                        Jednotka
                    </TableCell>
                    <TableCell item xs={12} md={2} right>
                        Jednotková cena
                    </TableCell>
                    <TableCell item xs={12} md={3} right>
                        Fakturovat klientovi
                        <Typography color="textSecondary">Fakturovaná cena za jednotku</Typography>
                    </TableCell>
                    <TableHeaderActions item xs={12} md={2}>
                        <IconButton onClick={() => setFormIndex(-1)} disabled={disabled}>
                            <AddIcon fontSize="small" />
                        </IconButton>
                    </TableHeaderActions>
                </TableHeader>
                <TableBody>
                    {formIndex === -1 ? (
                        <ExpenseRowForm item={{}} onCancel={() => setFormIndex(false)} onSubmit={handleSubmit} disabled={disabled} currency={currency} />
                    ) : null}
                    {data &&
                        Array.isArray(data.content) &&
                        data.content.map((item, key) =>
                            key === formIndex ? (
                                <ExpenseRowForm
                                    item={item}
                                    key={key}
                                    onCancel={() => setFormIndex(false)}
                                    onSubmit={handleSubmit}
                                    disabled={disabled}
                                    currency={currency}
                                />
                            ) : (
                                <ExpenseRow
                                    item={item}
                                    key={key}
                                    onEdit={() => setFormIndex(key)}
                                    disabled={disabled}
                                    onDelete={() => deleteItem(item)}
                                    currency={currency}
                                />
                            )
                        )}
                </TableBody>
                <TablePagination
                    disabled={disabled}
                    pagingInfo={{ pagesCount: data.totalPages, page: data.number, size: data.size }}
                    onChangePage={(page, size) => filterList("", page, size)}
                />
            </SettingsPage>
        </Detail>
    );
});

/////////////////////////// INCOMES ///////////////////////////

const IncomeRow = ({ item, onEdit, onDelete, disabled, currency }) => {
    return (
        <TableRow>
            <TableCell item xs={12} md={7}>
                <Typography display="block">{item.name}</Typography>
                {!!item.description && (
                    <Typography display="block" color="textSecondary">
                        {item.description}
                    </Typography>
                )}
            </TableCell>
            <TableCell item xs={12} md={1} center>
                {item.unit}
            </TableCell>
            <TableCell item xs={12} md={2} right>
                <Typography display="block">
                    {formatCurrency(item.unitPrice, currency)}
                    {item.unit ? "/" + item.unit : null}
                </Typography>
            </TableCell>

            <TableActions xs={12} md={2}>
                <IconButton onClick={onEdit} disabled={disabled}>
                    <CreateIcon fontSize="small" />
                </IconButton>
                <IconButton onClick={onDelete} disabled={disabled}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </TableActions>
        </TableRow>
    );
};

const IncomeRowForm = ({ item, onSubmit, onCancel, showProject, showEmployee, disabled, readOnly, showPrice, currency }) => {
    return (
        <TableRow>
            <Formik
                initialValues={
                    item
                        ? {
                              ...item,
                              unitPrice: formatNum(item.unitPrice || 0),
                          }
                        : {}
                }
                enableReinitialize={true}
                validate={(values) => {
                    let errors = {};

                    if (!values.name) {
                        errors.name = "Povinné pole";
                    }
                    if (!values.unit) {
                        errors.unit = "Povinné pole";
                    }
                    if (!values.unitPrice) {
                        errors.unitPrice = "Povinné pole";
                    } else if (!validator.decimal(values.unitPrice)) {
                        errors.unitPrice = "Chybný formát čísla";
                    }
                    return errors;
                }}
                validateOnChange={false}
                onSubmit={(values, formikBag) => {
                    onSubmit &&
                        onSubmit(
                            {
                                ...values,
                                unitPrice: parseNum(values.unitPrice),
                            },
                            formikBag
                        );
                }}>
                {({ values, setFieldValue, setValues, errors, handleChange, handleBlur, submitForm }) => {
                    return (
                        <React.Fragment>
                            <TableFormCell item xs={12} md={7}>
                                <TextField
                                    label="Název"
                                    name="name"
                                    value={values.name || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    InputProps={{ readOnly }}
                                    disabled={disabled}
                                    helperText={errors.name}
                                    error={!!errors.name}
                                />
                                <TextField
                                    label="Popis"
                                    name="description"
                                    value={values.description || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    InputProps={{ readOnly }}
                                    disabled={disabled}
                                    helperText={errors.description}
                                    error={!!errors.description}
                                />
                            </TableFormCell>
                            <TableFormCell item xs={12} md={1} center>
                                <TextField
                                    label="Jednotka"
                                    name="unit"
                                    value={values.unit || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    disabled={disabled}
                                    helperText={errors.unit}
                                    error={!!errors.unit}
                                />
                            </TableFormCell>
                            <TableFormCell xs={12} md={2}>
                                <CurrencyField
                                    label="Jednotková cena"
                                    name="unitPrice"
                                    value={values.unitPrice || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    unit={values.unit}
                                    currency={currency}
                                    readOnly={readOnly}
                                    disabled={disabled}
                                    helperText={errors.unitPrice}
                                    error={!!errors.unitPrice}
                                />
                            </TableFormCell>
                            <TableActions item xs={12} md={2}>
                                <IconButton onClick={submitForm} disabled={disabled}>
                                    <CheckIcon fontSize="small" />
                                </IconButton>
                                <IconButton onClick={onCancel} disabled={disabled}>
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            </TableActions>
                        </React.Fragment>
                    );
                }}
            </Formik>
        </TableRow>
    );
};

const IncomeSettings = withLoadingIndicator(({ showLoader, hideLoader, isLoading, path }) => {
    const [data, setData] = useState({});
    const [formIndex, setFormIndex] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useContext(SecurityContext);
    const currency = getCurrency(user);

    const filterList = async (filter = "", page = 0, size = 10) => {
        try {
            showLoader();
            let data = await filterIncomes(filter, page, size);
            setFormIndex(false);
            setData(data);
        } catch (e) {
            enqueueSnackbar("Nelze načíst data", { variant: "error" });
            setData({});
        } finally {
            hideLoader();
        }
    };

    const handleSubmit = async (values, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        showLoader();
        try {
            values = { ...values, unitPrice: parseNum(values.unitPrice) };
            let c = await saveIncome(values);
            enqueueSnackbar("Uloženo", { variant: "success" });
            filterList("", data.page, data.size);
        } catch (err) {
            setErrors(parseErrors(err));
            enqueueSnackbar("Záznam nelze uložit", { variant: "error" });
        }
        setSubmitting(false);
        hideLoader();
    };

    const deleteItem = (state) =>
        Alert.alert("Smazat šablonu příjmů", `Chcete smazat šablonu příjmů ${state && state.name} ?`, [
            { text: "Ne" },
            {
                text: "Ano",
                onPress: async () => {
                    try {
                        showLoader();
                        await deleteIncome(state.id);
                        filterList("", data.page, data.size);
                        enqueueSnackbar("Smazáno", { variant: "success" });
                    } catch (e) {
                        enqueueSnackbar("Záznam nelze smazat", { variant: "error" });
                    }
                    hideLoader();
                },
            },
        ]);

    const disabled = isLoading,
        readOnly = false;

    useEffect(() => {
        filterList();
    }, []);

    return (
        <Detail detailIdPath={path}>
            <SettingsPage title="Šablony příjmů" path={path}>
                <TableHeader container>
                    <TableCell item xs={12} md={7}>
                        Název
                        <Typography color="textSecondary">Popis</Typography>
                    </TableCell>
                    <TableCell item xs={12} md={1} center>
                        Jednotka
                    </TableCell>
                    <TableCell item xs={12} md={2} right>
                        Jednotková cena
                    </TableCell>
                    <TableHeaderActions item xs={12} md={2}>
                        <IconButton onClick={() => setFormIndex(-1)} disabled={disabled}>
                            <AddIcon fontSize="small" />
                        </IconButton>
                    </TableHeaderActions>
                </TableHeader>
                <TableBody>
                    {formIndex === -1 ? (
                        <IncomeRowForm item={{}} onCancel={() => setFormIndex(false)} onSubmit={handleSubmit} disabled={disabled} currency={currency} />
                    ) : null}
                    {data &&
                        Array.isArray(data.content) &&
                        data.content.map((item, key) =>
                            key === formIndex ? (
                                <IncomeRowForm
                                    item={item}
                                    key={key}
                                    onCancel={() => setFormIndex(false)}
                                    onSubmit={handleSubmit}
                                    disabled={disabled}
                                    currency={currency}
                                />
                            ) : (
                                <IncomeRow
                                    item={item}
                                    key={key}
                                    onEdit={() => setFormIndex(key)}
                                    disabled={disabled}
                                    onDelete={() => deleteItem(item)}
                                    currency={currency}
                                />
                            )
                        )}
                </TableBody>
                <TablePagination
                    disabled={disabled}
                    pagingInfo={{ pagesCount: data.totalPages, page: data.number, size: data.size }}
                    onChangePage={(page, size) => filterExpenses("", page, size)}
                />
            </SettingsPage>
        </Detail>
    );
});

/////////////////////////// CURRENCY ///////////////////////////

const CurrencyForm = ({ currency, onChange, onDelete, onSetDefault, errors, ...props }) => {
    const [values, setValues] = useState({});

    useEffect(() => {
        setValues(currency || {});
    }, [currency]);

    const setFieldValue = (field) => (e, val) => {
        let vals = { ...values, [field]: val || e.target.value || "" };
        setValues(vals);
        onChange && onChange(vals);
    };

    let num = "1234";
    if (values.decimalDigits) {
        num += ".";
        for (let i = 0; i < values.decimalDigits; i++) {
            num += (i + 5) % 10;
        }
    }

    return (
        <Box mb={3}>
            <Paper>
                <Box p={3}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} pl={2}>
                        <Box>
                            <Typography variant="h6" display="inline">
                                {values.isoCode || "<bez názvu>"}
                            </Typography>
                            {!!values.defaultCurrency ? (
                                <Typography variant="caption" style={{ marginLeft: 8 }} display="inline" color="textSecondary">
                                    Výchozí měna
                                </Typography>
                            ) : (
                                <Link
                                    component="button"
                                    color="secondary"
                                    variant="body2"
                                    style={{ marginLeft: 8 }}
                                    onClick={onSetDefault}
                                    gutterBottom
                                    underline="none">
                                    Nastavit jako výchozí
                                </Link>
                            )}
                        </Box>
                        <IconButton onClick={onDelete}>
                            <ClearIcon />
                        </IconButton>
                    </Box>
                    <Grid container alignItems="center">
                        <Grid item sm={8} xs={12}>
                            <Box p={2}>
                                <Grid container spacing={6} alignItems="flex-end">
                                    <Grid item>
                                        <Box>
                                            <TextField
                                                label="Kód měny"
                                                name="isoCode"
                                                value={values.isoCode}
                                                onChange={setFieldValue("isoCode")}
                                                margin="normal"
                                                helperText={errors.isoCode}
                                                error={!!errors.isoCode}
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                label="Symbol"
                                                name="symbol"
                                                value={values.symbol}
                                                onChange={setFieldValue("symbol")}
                                                margin="normal"
                                                helperText={errors.symbol}
                                                error={!!errors.symbol}
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                label="Počet desetinných míst"
                                                name="decimalDigits"
                                                value={values.decimalDigits}
                                                onChange={setFieldValue("decimalDigits")}
                                                margin="normal"
                                                inputProps={{
                                                    style: { textAlign: "right" },
                                                }}
                                                helperText={errors.decimalDigits}
                                                error={!!errors.decimalDigits}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Pozice symbolu</FormLabel>
                                            <RadioGroup name="gender1" value={values.symbolPosition || "left"} onChange={setFieldValue("symbolPosition")}>
                                                <FormControlLabel value="left" control={<Radio />} label="Vlevo" />
                                                <FormControlLabel value="right" control={<Radio />} label="Vpravo" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Box py={2} px={6} alignSelf="center">
                                <Typography display="block" variant="subtitle1" gutterBottom color="textSecondary">
                                    Náhled
                                </Typography>
                                <Typography display="block" variant="h4">
                                    {formatAmount(num, values.symbol, values.symbolPosition, values.decimalDigits)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Box>
    );
};

const ensureDefaultCurrencySet = (crns) => {
    if (!Array.isArray(crns) || !crns.length) {
        return;
    }

    let defaultFound = crns.find((c) => !!c.defaultCurrency);
    if (!defaultFound) {
        crns[0].defaultCurrency = true;
    }

    crns.sort((a, b) => {
        if (!!a.defaultCurrency) {
            return -1000;
        }
        if (!!b.defaultCurrency) {
            return 1000;
        }
        return a.isoCode.localeCompare(b.isoCode);
    });
};

const CurrenciesForm = ({ submitLabel, cancelLabel, onSubmit, onCancel, currencies, setCurrencies, errors, ...props }) => {
    const { enqueueSnackbar } = useSnackbar();
    const disabled = props.isLoading,
        readOnly = false;

    const addCurrency = () => {
        let crns = [...currencies, {}];
        ensureDefaultCurrencySet(crns);
        setCurrencies(crns);
    };

    const removeCurrency = (idx) => () => {
        let crns = [...currencies];
        crns.splice(idx, 1);
        ensureDefaultCurrencySet(crns);
        setCurrencies(crns);
    };

    const changeCurrency = (idx) => (currency) => {
        currencies[idx] = currency;
        setCurrencies([...currencies]);
    };

    const setDefault = (idx) => () => {
        let crns = [...currencies];
        crns.forEach((c, key) => (c.defaultCurrency = key == idx));
        ensureDefaultCurrencySet(crns);
        setCurrencies(crns);
    };

    return (
        <Box>
            <Box py={3}>
                {currencies
                    ? Object.values(currencies).map((currency, key) => (
                          <CurrencyForm
                              key={key}
                              currency={currency}
                              onChange={changeCurrency(key)}
                              onDelete={removeCurrency(key)}
                              onSetDefault={setDefault(key)}
                              errors={(errors && errors[key]) || {}}
                          />
                      ))
                    : null}
            </Box>
            <Button onClick={addCurrency}>Přidat měnu</Button>
        </Box>
    );
};

export const CurrenciesSettingsPage = withLoadingIndicator(({ showLoader, hideLoader, isLoading, path, ...props }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { updateProfile, user } = useContext(SecurityContext);
    const [currencies, setCurrencies] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (user) {
            let crns = JSON.parse(JSON.stringify(user.currencies || []));
            ensureDefaultCurrencySet(crns);
            setCurrencies(crns);
        } else {
            setCurrencies([]);
        }
        setErrors({});
    }, []);

    const save = async () => {
        //validate
        let errs = {};
        if (currencies.length) {
            for (let i = 0; i < currencies.length; i++) {
                let e = {};
                let c = currencies[i];
                if (!c.isoCode) {
                    e.isoCode = "Povinné pole";
                }
                if (!c.symbol) {
                    e.symbol = "Povinné pole";
                }
                if (Object.keys(e).length) {
                    errs[i] = e;
                }
            }
        } else {
            enqueueSnackbar("Přidejte alespoň jednu měnu", { variant: "error" });
            return;
        }

        setErrors(errs);
        if (Object.keys(errs).length) {
            return;
        }

        showLoader();
        try {
            let crns = await saveCurrencies(currencies);
            let defaultCurrency = crns.find((c) => !!c.defaultCurrency);
            updateProfile({ currencies: crns, defaultCurrency });
            enqueueSnackbar("Měny byly uloženy", { variant: "success" });
            setCurrencies(crns);
        } catch (e) {
            enqueueSnackbar("Došlo k chybě", { variant: "error" });
            console.error(e);
        } finally {
            hideLoader();
        }
    };

    return (
        <Detail detailIdPath={path}>
            <SettingsPage title="Měny" path={path} doSubmit={save}>
                <CurrenciesForm {...props} currencies={currencies} setCurrencies={setCurrencies} errors={errors} />
            </SettingsPage>
        </Detail>
    );
});

/////////////////////////////////////////////////////////////////////

export default withRouter(({ history, ...props }) => {
    const basePath = "/settings";

    const options = [
        {
            name: "Šablony výdajů",
            path: "expenses",
            icon: <AllInboxIcon />,
        },
        {
            name: "Šablony příjmů",
            path: "income",
            icon: <TrendingUpIcon />,
        },
        {
            name: "Obecné",
            path: "general",
            icon: <SettingsIcon />,
        },
        {
            name: "Měny",
            path: "currencies",
            icon: <AttachMoneyIcon />,
        },
        {
            name: "Heslo",
            path: "password",
            icon: <LockIcon />,
        },
        {
            name: "Zálohování",
            path: "backup",
            icon: <SettingsBackupRestoreIcon />,
            disabled: true,
        },
    ];

    return (
        <MasterDetailRoute path={basePath}>
            <Master>
                <SettingsList options={options} />
            </Master>
            <PasswordSettings path="password" />
            <ExpenseSettings path="expenses" />
            <IncomeSettings path="income" />

            <BackupSettings path="backup" />
            <GeneralSettings path="general" />
            <CurrenciesSettingsPage path="currencies" />

            <EmptyDetail>
                <EmptyInfoPanel icon={null} text="Vyberte položku nastavení" disabled={props.isLoading} />
            </EmptyDetail>
        </MasterDetailRoute>
    );
});

import moment from "moment";

import backend, { parseErrors, download, upload } from "lib/backend";
import { prepareFormData } from "lib/attachments";

export const filterProjects = async (filter, page = 0, size = 20, sortBy, sort) => {
    try {
        let response = await backend("/api/projects", "GET", {
            params: {
                page,
                size,
                sort,
                sortBy,
                filter: filter ? filter.text : null,
            },
        });
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const fetchProject = async (id) => {
    try {
        let response = await backend("/api/projects/" + id);
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const saveProject = async (data = {}) => {
    let formData = prepareFormData(data);

    try {
        let response = await backend("/api/projects/" + (data.id || ""), data.id ? "PUT" : "POST", {
            body: formData,
            upload: true,
        });
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const saveProjectEmployees = async (id, employees) => {
    if (!id || !Array.isArray(employees)) {
        return;
    }

    try {
        await backend("/api/projects/" + id + "/employees", "PUT", {
            body: employees,
        });
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const saveProjectExpenseTemplates = async (id, templates) => {
    if (!id || !Array.isArray(templates)) {
        return;
    }

    try {
        await backend("/api/projects/" + id + "/expenseTemplates", "PUT", {
            body: templates,
        });
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const filterClientOptions = async (filter) => {
    console.log(">>>filter", filter);
    try {
        let response = await backend("/api/clients/options", "GET", {
            params: {
                filter,
            },
        });
        return response.body.content || [];
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const filterEmployeeOptions = async (filter) => {
    try {
        let response = await backend("/api/employees/options", "GET", {
            params: {
                filter,
            },
        });
        return response.body || [];
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const getAllEmployeeOptions = async (filter) => {
    try {
        let response = await backend("/api/employees/all", "GET", {
            params: {
                filter,
            },
        });
        return response.body || [];
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const filterExpensesOptions = async (filter) => {
    try {
        let response = await backend("/api/expenses/templates/options", "GET", {
            params: {
                filter,
            },
        });
        return response.body || [];
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const getAllExpensesOptions = async (filter) => {
    try {
        let response = await backend("/api/expenses/templates/all", "GET", {
            params: {
                filter,
            },
        });
        return response.body || [];
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const deleteProject = async (id) => {
    try {
        await backend("/api/projects/" + id, "DELETE");
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const fetchProjectOverview = async (id, filter) => {
    try {
        let data = await backend("/api/dashboard/projects/" + id, "GET", { params: { filter } });
        return data.body || {};
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const projectExcel = async (id) => {
    try {
        await download("/api/projects/" + id + "/excel", {
            params: {},
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};

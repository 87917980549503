import React, { useEffect, useState, useContext, useRef } from "react";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "lib/components/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import CurrencyField from "lib/components/CurrencyField";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";

import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Alert from "lib/components/Alert";

import { LayoutHeader, DesktopMenu, MobileMenu } from "lib/components/layout";
import CalendarHeatmap from "components/CalendarHeatmap";
import DoughnutChart from "components/DoughnutChart";
import { Table, TableHeader, TableHeaderActions, TableActions, TableBody, TableRow, TableCell, TableFormCell, TablePagination } from "lib/components/Table";

import { Formik } from "formik";

import moment from "moment";

import { formatDate, formatNum, parseNum, formatCurrency, dateToString, formatTime, parseTime, normalizeTime } from "lib/format";
import { filterProjectOptions, filterEmployeeOptions } from "./actions";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import validator from "lib/validator";

import { useSnackbar } from "notistack";
import PeriodSelector, { usePeriod } from "components/period";
import { AttachmentChips, AttachmentField, AttachmentDropzone } from "lib/components/Attachment";
import { fetchEmployeeTimesheet, saveEmployeeTimesheet, deleteEmployeeTimesheet, fetchEmployeeTimesheetOverview } from "components/timesheet/actions";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Collapse from "@material-ui/core/Collapse";
import clsx from "clsx";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import ScheduleIcon from "@material-ui/icons/Schedule";

import Tooltip from "@material-ui/core/Tooltip";

const Row = ({ item, showProject, showEmployee, onEdit, onDelete, showWage, showClient, editable, disabled, currency }) => {
    return (
        <TableRow>
            <TableCell xs={12} md={2}>
                <TimesheetDate item={item} />
            </TableCell>
            {!!showProject && (
                <TableCell xs={12} md={2}>
                    {item.projectName || "<bez projektu>"}
                    {!!showClient && !!item.clientName && (
                        <Typography display="block" color="textSecondary">
                            {item.clientName}
                        </Typography>
                    )}
                </TableCell>
            )}
            {!!showEmployee && (
                <TableCell xs={12} md={2}>
                    {item.employeeName}
                </TableCell>
            )}
            <TableCell xs={12} md={!!editable ? 4 : 6}>
                {item.description}
            </TableCell>
            <TableCell xs={12} md={2} right>
                <TimesheetDuration item={item} />
                {!!showWage && (
                    <Typography display="block" color="textSecondary">
                        {formatCurrency(item.wage, currency)}/h
                    </Typography>
                )}
            </TableCell>
            {!!editable && (
                <TableActions xs={12} md={2}>
                    <IconButton onClick={onEdit} disabled={disabled}>
                        <CreateIcon fontSize="small" />
                    </IconButton>
                    <IconButton onClick={onDelete} disabled={disabled}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </TableActions>
            )}
            <AttachmentChips attachments={item.attachments} url={(row) => `/api/timesheets/${row.objectId}/attachments/${row.id}`} disabled={disabled} />
        </TableRow>
    );
};

const createRowFormClasses = makeStyles((theme) => ({
    root: {
        display: "inline-block",
        textAlign: "left",
        width: "100%",
        maxWidth: 380,
    },
}));
const RowForm = ({ item, onSubmit, onCancel, showProject, showEmployee, projectId, employeeId, disabled, readOnly, showWage, currency }) => {
    const classes = createRowFormClasses();
    const formRef = useRef();

    const submitForm = () => {
        if (formRef.current) {
            formRef.current.submitForm();
        }
    };

    return (
        <TableRow>
            <TableCell xs={12} center>
                <Box className={classes.root}>
                    <TimesheetForm
                        formRef={formRef}
                        data={item}
                        onSubmit={onSubmit}
                        showProject={showProject}
                        showEmployee={showEmployee}
                        projectId={projectId}
                        employeeId={employeeId}
                        disabled={disabled}
                        readOnly={readOnly}
                        showWage={showWage}
                        currency={currency}
                    />
                </Box>
            </TableCell>
            <TableActions xs={12}>
                <IconButton onClick={onCancel} disabled={disabled}>
                    <ClearIcon />
                </IconButton>
                <IconButton onClick={submitForm} disabled={disabled}>
                    <CheckIcon />
                </IconButton>
            </TableActions>
        </TableRow>
    );
};

const TimesheetTable = ({
    data = [],
    disabled,
    employeeId,
    projectId,
    showWage,
    editable = true,
    onSaveItem,
    onDeleteItem,
    showClient,
    currency,
    ...props
}) => {
    const [formIndex, setFormIndex] = useState(false);
    useEffect(() => setFormIndex(false), [data, projectId, employeeId, editable]);

    const submitForm = async (vals) => {
        try {
            if (onSaveItem) {
                await onSaveItem(vals);
            }

            setFormIndex(false);
        } catch (e) {
            console.error(e);
        }
    };

    const deleteItem = async (id) => {
        try {
            if (onDeleteItem) {
                await onDeleteItem(id);
                setFormIndex(false);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const showProject = !!employeeId;
    const showEmployee = !!projectId;

    return (
        <Table>
            <TableHeader>
                <TableCell xs={12} md={2}>
                    Datum
                </TableCell>
                {showProject && (
                    <TableCell xs={12} md={2}>
                        Projekt
                    </TableCell>
                )}
                {showEmployee && (
                    <TableCell xs={12} md={2}>
                        Zaměstnanec
                    </TableCell>
                )}
                <TableCell xs={12} md={4}>
                    Činnost
                </TableCell>
                <TableCell xs={12} md={2} right>
                    Počet hodin
                </TableCell>
                <TableHeaderActions xs={12} md={2}>
                    {!!editable && (
                        <IconButton onClick={() => setFormIndex(-1)} disabled={disabled}>
                            <AddIcon fontSize="small" />
                        </IconButton>
                    )}
                </TableHeaderActions>
            </TableHeader>

            <TableBody>
                {formIndex === -1 ? (
                    <RowForm
                        item={{}}
                        onCancel={() => setFormIndex(false)}
                        onSubmit={submitForm}
                        employeeId={employeeId}
                        projectId={projectId}
                        showProject={showProject}
                        showEmployee={showEmployee}
                        showWage={showWage}
                        disabled={disabled}
                        currency={currency}
                    />
                ) : null}
                {Array.isArray(data) &&
                    data.map((item, key) =>
                        key === formIndex ? (
                            <RowForm
                                item={item}
                                key={key}
                                onCancel={() => setFormIndex(false)}
                                onSubmit={submitForm}
                                employeeId={employeeId}
                                projectId={projectId}
                                showProject={showProject}
                                showEmployee={showEmployee}
                                showWage={showWage}
                                disabled={disabled}
                                currency={currency}
                            />
                        ) : (
                            <Row
                                item={item}
                                key={key}
                                onEdit={() => setFormIndex(key)}
                                showProject={showProject}
                                showEmployee={showEmployee}
                                showWage={showWage}
                                disabled={disabled}
                                editable={editable}
                                showClient={showClient}
                                currency={currency}
                                onDelete={() =>
                                    Alert.alert(
                                        "Smazat položku výkazu",
                                        `Chcete smazat položku ${formatDate(item.date)} ${item.projectName} ${item.description || ""} ?`,
                                        [{ text: "Ne" }, { text: "Ano", onPress: () => deleteItem(item.id) }]
                                    )
                                }
                            />
                        )
                    )}
            </TableBody>
        </Table>
    );
};

export const EmployeeTimesheetTable = ({
    data = [],
    disabled,
    employeeId,
    showWage,
    wage,
    from,
    to,
    editable = true,
    onSaveItem,
    onDeleteItem,
    currency,
    ...props
}) => {
    return (
        <TimesheetTable
            disabled={disabled}
            employeeId={employeeId}
            showWage={showWage}
            editable={editable}
            data={data}
            onSaveItem={onSaveItem}
            onDeleteItem={onDeleteItem}
            showClient
            currency={currency}
        />
    );
};

const TimesheetDuration = ({ item }) => {
    return `${formatNum(item.hours || "0")} h`;
};

const TimesheetDate = ({ item }) => {
    return (
        <React.Fragment>
            {formatDate(item.date)}
            {item.mode == "TIME" && (
                <Typography display="block" color="textSecondary">
                    {`${item.startTime} - ${item.endTime}`}
                </Typography>
            )}
        </React.Fragment>
    );
};

export const ProjectTimesheetTable = ({
    data = [],
    disabled,
    projectId,
    showWage,
    from,
    to,
    editable = true,
    onSaveItem,
    onDeleteItem,
    currency,
    ...props
}) => {
    const [formIndex, setFormIndex] = useState(false);
    useEffect(() => setFormIndex(false), [data, projectId, editable]);

    const submitForm = async (vals) => {
        try {
            if (onSaveItem) {
                await onSaveItem(vals);
            }

            setFormIndex(false);
        } catch (e) {
            console.error(e);
        }
    };

    const deleteItem = async (id) => {
        try {
            if (onDeleteItem) {
                await onDeleteItem(id);
                setFormIndex(false);
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Table>
            <TableHeader>
                <TableCell xs={12} md={2}>
                    Datum
                </TableCell>
                <TableCell xs={12} md={4}>
                    Zaměstnanec
                    <Typography color="textSecondary">Činnost</Typography>
                </TableCell>
                <TableCell xs={12} md={2} right>
                    Počet hodin
                    <Typography color="textSecondary">Mzda</Typography>
                </TableCell>
                <TableCell xs={12} md={2} right>
                    Fakturovat klientovi
                    <Typography color="textSecondary">Fakturovaná mzda</Typography>
                </TableCell>
                <TableHeaderActions xs={12} md={2}>
                    {!!editable && (
                        <IconButton onClick={() => setFormIndex(-1)} disabled={disabled}>
                            <AddIcon fontSize="small" />
                        </IconButton>
                    )}
                </TableHeaderActions>
            </TableHeader>

            <TableBody>
                {formIndex === -1 ? (
                    <RowForm
                        item={{}}
                        onCancel={() => setFormIndex(false)}
                        onSubmit={submitForm}
                        projectId={projectId}
                        showEmployee
                        showWage={showWage}
                        disabled={disabled}
                        currency={currency}
                    />
                ) : null}{" "}
                {Array.isArray(data) &&
                    data.map((item, key) =>
                        key === formIndex ? (
                            <RowForm
                                item={item}
                                key={key}
                                onCancel={() => setFormIndex(false)}
                                onSubmit={submitForm}
                                projectId={projectId}
                                showEmployee
                                showWage={showWage}
                                disabled={disabled}
                                currency={currency}
                            />
                        ) : (
                            <TableRow key={key}>
                                <TableCell xs={12} md={2}>
                                    <TimesheetDate item={item} />
                                </TableCell>
                                <TableCell xs={12} md={4}>
                                    {item.employeeName}
                                    <Typography color="textSecondary">{item.description}</Typography>
                                </TableCell>
                                <TableCell xs={12} md={2} right>
                                    <TimesheetDuration item={item} />
                                    <Typography display="block" color="textSecondary">
                                        {formatCurrency(item.wage, currency)}/h
                                    </Typography>
                                </TableCell>
                                <TableCell xs={12} md={2} right>
                                    {item.billable ? "Ano" : "Ne"}
                                    <Typography display="block" color="textSecondary">
                                        {formatCurrency(item.billableWage, currency)}/h
                                    </Typography>
                                </TableCell>
                                <TableActions xs={12} md={2}>
                                    {!!editable && (
                                        <React.Fragment>
                                            <IconButton onClick={() => setFormIndex(key)} disabled={disabled}>
                                                <CreateIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton
                                                onClick={() =>
                                                    Alert.alert(
                                                        "Smazat položku výkazu",
                                                        `Chcete smazat položku ${formatDate(item.date)} ${item.projectName} ${item.description || ""} ?`,
                                                        [{ text: "Ne" }, { text: "Ano", onPress: () => deleteItem(item.id) }]
                                                    )
                                                }
                                                disabled={disabled}>
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </React.Fragment>
                                    )}
                                </TableActions>
                                <AttachmentChips
                                    attachments={item.attachments}
                                    url={(row) => `/api/timesheets/${row.objectId}/attachments/${row.id}`}
                                    disabled={disabled}
                                />
                            </TableRow>
                        )
                    )}
            </TableBody>
        </Table>
    );
};

const TimeInfo = ({ startTime, endTime }) => {
    let hours = "-";
    if (startTime && endTime) {
        startTime = normalizeTime(startTime);
        endTime = normalizeTime(endTime);

        if (validator.time(startTime) && validator.time(endTime)) {
            let duration = moment.duration(moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm"))).as("hours");
            hours = formatNum(duration);
        }
    }
    return (
        <Typography display="block" variant="caption" color="textSecondary" style={{ textAlign: "right" }}>
            {`Počet hodin: ${hours} h`}
        </Typography>
    );
};

const filterProjects = (options, { inputValue, ...state }) => {
    if (!Array.isArray(options)) {
        return [];
    }

    if (!inputValue) {
        return options;
    }

    let val = inputValue.toLowerCase();

    return options.filter((i) => (i && i.name && i.name.toLowerCase().includes(val)) || (i.clientName && i.clientName.toLowerCase().includes(val)));
};

const TimesheetForm = ({
    formRef,
    data,
    date,
    onSubmit,
    onCancel,
    disabled,
    readOnly,
    showWage,
    wage,
    showEmployee,
    showProject,
    projectId,
    employeeId,
    currency,
}) => {
    const margin = "normal";
    const uploadRef = useRef();

    return (
        <Formik
            ref={formRef}
            initialValues={
                data
                    ? {
                          mode: "HOURS",
                          ...data,
                          hours: formatNum(data.hours || 0),
                          wage: formatNum(data.wage || 0),
                          billableWage: formatNum(data.billableWage || 0),
                      }
                    : {
                          mode: "HOURS",
                      }
            }
            enableReinitialize={true}
            validate={(values) => {
                let errors = {};

                if (!values.date) {
                    errors.date = "Povinné pole";
                }
                /*
                if (!!showProject && !values.projectId) {
                    errors.project = "Povinné pole";
                }
                */
                if (!!showEmployee && !values.employeeId) {
                    errors.employee = "Povinné pole";
                }
                if (!!showWage) {
                    if (!values.wage) {
                        errors.wage = "Povinné pole";
                    } else if (!validator.decimal(values.wage)) {
                        errors.wage = "Chybný formát čísla";
                    }
                    if (!!values.billable && !values.billableWage) {
                        errors.billableWage = "Povinné pole";
                    } else if (!validator.decimal(values.billableWage)) {
                        errors.billableWage = "Chybný formát čísla";
                    }
                }

                //TODO validate mode (and hours) properly
                if (values.mode == "TIME") {
                    let startTime = normalizeTime(values.startTime);
                    let endTime = normalizeTime(values.endTime);

                    if (!startTime) {
                        errors.startTime = "Povinné pole";
                    } else if (!validator.time(startTime)) {
                        errors.startTime = "Chybný formát. Použijte HH:mm";
                    }
                    if (!endTime) {
                        errors.endTime = "Povinné pole";
                    } else if (!validator.time(endTime)) {
                        errors.endTime = "Chybný formát. Použijte HH:mm";
                    }

                    if (!errors.startTime && !errors.endTime) {
                        if (endTime.localeCompare(startTime) < 0) {
                            console.log("ERROR");
                            errors.startTime = "Hodnota nesmí být větší než čas do";
                            errors.endTime = "Hodnota nesmí být menší než čas od";
                        }
                    }
                } else {
                    if (!values.hours) {
                        errors.hours = "Povinné pole";
                    } else if (!validator.decimal(values.hours)) {
                        errors.hours = "Chybný formát čísla";
                    }
                }

                return errors;
            }}
            validateOnChange={false}
            onSubmit={(values) => {
                onSubmit &&
                    onSubmit({
                        ...values,
                        startTime: normalizeTime(values.startTime),
                        endTime: normalizeTime(values.endTime),
                        hours: parseNum(values.hours),
                        wage: parseNum(values.wage),
                        date: dateToString(values.date),
                        billableWage: parseNum(values.billableWage),
                    });
            }}>
            {({ values, setFieldValue, setValues, errors, handleChange, handleBlur, submitForm }) => {
                return (
                    <React.Fragment>
                        <AttachmentDropzone uploadRef={uploadRef}>
                            <KeyboardDatePicker
                                label="Datum"
                                margin={margin}
                                name="date"
                                value={values.date || null}
                                onChange={(v) => setFieldValue("date", v)}
                                disabled={disabled}
                                format="DD.MM.YYYY"
                                fullWidth
                                autoOk
                                helperText={errors.date}
                                error={!!errors.date}
                            />

                            {showProject ? (
                                <Autocomplete
                                    label="Projekt"
                                    id="project"
                                    value={values.projectId ? { id: values.projectId, name: values.projectName } : null}
                                    onChange={(e, v) => {
                                        if (v && v.id) {
                                            setValues({
                                                ...values,
                                                projectId: v.id,
                                                projectName: v.name,
                                                wage: formatNum(v.wage),
                                                billable: v.billable,
                                                billableWage: formatNum(v.billableWage),
                                            });
                                        } else {
                                            setValues({ ...values, projectId: null, projectName: null, wage: null, billable: null, billableWage: null });
                                        }
                                    }}
                                    error={!!errors.project}
                                    helperText={errors.project}
                                    getOptionLabel={(option) => option.name}
                                    fetchOptions={async () => filterProjectOptions(employeeId)}
                                    renderOption={(option, { selected }) => (
                                        <div>
                                            {option.name}
                                            {!!option.clientName && (
                                                <Typography display="block" variant="caption" color="textSecondary">
                                                    {option.clientName}
                                                </Typography>
                                            )}
                                        </div>
                                    )}
                                    fullWidth
                                    disabled={disabled}
                                    margin={margin}
                                    loadingText="Načítají se hodnoty..."
                                    noOptionsText="Žádné hodnoty"
                                    filterOptions={filterProjects}
                                />
                            ) : (
                                <Autocomplete
                                    label="Zaměstnanec"
                                    id="employee"
                                    value={values.employeeId ? { id: values.employeeId, name: values.employeeName } : null}
                                    onChange={(e, v) => {
                                        if (v && v.id) {
                                            setValues({
                                                ...values,
                                                employeeId: v.id,
                                                employeeName: v.name,
                                                wage: formatNum(v.wage),
                                                billable: v.billable,
                                                billableWage: formatNum(v.billableWage),
                                            });
                                        } else {
                                            setValues({ ...values, employeeId: null, employeeName: null, wage: null, billable: null, billableWage: null });
                                        }
                                    }}
                                    error={!!errors.employee}
                                    helperText={errors.employee}
                                    getOptionLabel={(option) => option.name}
                                    fetchOptions={async () => filterEmployeeOptions(projectId)}
                                    fullWidth
                                    disabled={disabled}
                                    margin={margin}
                                    loadingText="Načítají se hodnoty..."
                                    noOptionsText="Žádné hodnoty"
                                />
                            )}
                            <TextField
                                label="Činnost"
                                name="description"
                                value={values.description || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                margin={margin}
                                InputProps={{ readOnly }}
                                disabled={disabled}
                                helperText={errors.description}
                                error={!!errors.description}
                            />
                            <Box align="center" py={2}>
                                <ToggleButtonGroup size="small" value={values.mode} exclusive onChange={(e, val) => setFieldValue("mode", val)}>
                                    <ToggleButton value="HOURS">
                                        <HourglassEmptyIcon fontSize="small" /> <Typography variant="button">Počet hodin</Typography>
                                    </ToggleButton>
                                    <ToggleButton value="TIME">
                                        <ScheduleIcon fontSize="small" /> <Typography variant="button">Čas od-do</Typography>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Box>

                            {values.mode != "HOURS" ? (
                                <React.Fragment>
                                    <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="space-between">
                                        <Box flexGrow={1} flexShrink={1}>
                                            <TextField
                                                label="Čas od"
                                                value={values.startTime}
                                                name="startTime"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                fullWidth
                                                margin={margin}
                                                helperText={errors.startTime}
                                                error={!!errors.startTime}
                                                disabled={disabled}
                                                InputProps={{
                                                    readOnly,
                                                    inputProps: { style: { textAlign: "right" } },
                                                }}
                                            />
                                        </Box>
                                        <Box p={2} py={4} flexGrow={0} flexShrink={0}>
                                            -
                                        </Box>
                                        <Box flexGrow={1} flexShrink={1}>
                                            <TextField
                                                label="Čas do"
                                                value={values.endTime}
                                                name="endTime"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                fullWidth
                                                disabled={disabled}
                                                margin={margin}
                                                helperText={errors.endTime}
                                                error={!!errors.endTime}
                                                InputProps={{
                                                    readOnly,
                                                    inputProps: { style: { textAlign: "right" } },
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <TimeInfo startTime={values.startTime} endTime={values.endTime} />
                                </React.Fragment>
                            ) : (
                                <TextField
                                    label="Počet hodin"
                                    name="hours"
                                    value={values.hours || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin={margin}
                                    InputProps={{
                                        readOnly,
                                        endAdornment: <InputAdornment position="end">h</InputAdornment>,
                                        inputProps: { style: { textAlign: "right" } },
                                    }}
                                    disabled={disabled}
                                    helperText={errors.hours}
                                    error={!!errors.hours}
                                />
                            )}
                            {!!showWage && (
                                <CurrencyField
                                    label="Mzda za hodinu"
                                    name="wage"
                                    value={values.wage || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin={margin}
                                    unit="h"
                                    currency={currency}
                                    readOnly={readOnly}
                                    disabled={disabled}
                                    helperText={errors.wage}
                                    error={!!errors.wage}
                                />
                            )}
                            {!!showWage && (
                                <Box display="flex" alignItems="center">
                                    <Box flexGrow={1} color={values.billable && !disabled ? "text.primary" : "text.secondary"}>
                                        <Typography>Fakturovat klientovi</Typography>
                                    </Box>
                                    <Switch
                                        color="secondary"
                                        checked={values.billable || false}
                                        onChange={handleChange}
                                        name="billable"
                                        value={true}
                                        disabled={disabled}
                                    />
                                </Box>
                            )}
                            {!!showWage && !!values.billable && (
                                <CurrencyField
                                    label="Fakturovaná mzda za hodinu"
                                    name="billableWage"
                                    value={values.billableWage || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin={margin}
                                    unit="h"
                                    currency={currency}
                                    readOnly={readOnly}
                                    disabled={disabled}
                                    helperText={errors.billableWage}
                                    error={!!errors.billableWage}
                                />
                            )}
                            <AttachmentField
                                disabled={disabled}
                                value={values.attachments}
                                uploadRef={uploadRef}
                                onChange={(attachments) => setFieldValue("attachments", attachments)}
                            />
                        </AttachmentDropzone>
                    </React.Fragment>
                );
            }}
        </Formik>
    );
};

const TimesheetModal = ({ open, onClose, date, onSaveItem, disabled, employeeId, showWage, wage, currency }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const formRef = useRef();

    const submitForm = () => {
        if (formRef.current) {
            formRef.current.submitForm();
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullScreen={fullScreen} maxWidth="xs" fullWidth>
            <React.Fragment>
                {fullScreen ? (
                    <AppBar position="relative">
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flex: "1 1 auto" }}>
                                Přidat položku výkazu
                            </Typography>
                            <Button color="inherit" type="button" startIcon={<SaveIcon />} onClick={submitForm}>
                                Uložit
                            </Button>
                        </Toolbar>
                    </AppBar>
                ) : (
                    <DialogTitle>Přidat položku výkazu</DialogTitle>
                )}
                <DialogContent>
                    <TimesheetForm
                        formRef={formRef}
                        data={{ date }}
                        onCancel={onClose}
                        onSubmit={(values) => {
                            onSaveItem(values);
                            onClose();
                        }}
                        showProject
                        employeeId={employeeId}
                        showWage={showWage}
                        currency={currency}
                    />
                    {fullScreen ? null : (
                        <DialogActions>
                            <Button onClick={onClose} color="primary">
                                Zpět
                            </Button>
                            <Button type="button" color="primary" variant="contained" onClick={submitForm}>
                                Uložit
                            </Button>
                        </DialogActions>
                    )}
                </DialogContent>
            </React.Fragment>
        </Dialog>
    );
};

export const EmployeeTimsheetHeatmap = ({ data, disabled, editable, month, year, employeeId, onSaveItem, showWage, daysOverview, currency, ...props }) => {
    const [date, setDate] = useState(false);

    return (
        <React.Fragment>
            <CalendarHeatmap
                month={month}
                year={year}
                values={data}
                editable={editable && !disabled}
                onClick={(d) => setDate(d.key)}
                tooltips={daysOverview}
                renderTooltip={(t, d) => (
                    <React.Fragment>
                        <Typography variant="subtitle2" display="block">
                            {formatDate(d.key)}
                        </Typography>
                        <Typography variant="body2" display="block">{`Počet hodin: ${formatNum(t.hours)} h`}</Typography>
                        {!!showWage && <Typography variant="body2" display="block">{`Mzda: ${formatCurrency(t.amount, currency)}`}</Typography>}
                        {!!Array.isArray(t.items) && (
                            <Box mt={2}>
                                {t.items.map((i, k) => (
                                    <Box mb={0.5}>
                                        <Typography variant="subtitle2" display="block">
                                            {i.name}
                                        </Typography>
                                        <Typography variant="caption" display="block">
                                            {i.subtitle}
                                        </Typography>
                                        <Typography variant="caption" display="block">{`Počet hodin: ${formatNum(i.value)} h`}</Typography>
                                        {!!showWage && (
                                            <Typography variant="caption" display="block">{`Mzda: ${formatCurrency(i.amount, currency)}`}</Typography>
                                        )}
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </React.Fragment>
                )}
            />

            <TimesheetModal
                open={!!date}
                date={date}
                onClose={() => setDate()}
                onSaveItem={onSaveItem}
                disabled={disabled}
                employeeId={employeeId}
                showWage={showWage}
                currency={currency}
            />
        </React.Fragment>
    );
};

export const EmployeeProjectsTable = ({ data = [], showWage, currency, ...props }) => {
    return (
        <Box {...props}>
            <TableHeader>
                <TableCell xs={showWage ? 6 : 8}>Projekt</TableCell>
                <TableCell xs={3} right>
                    Počet hodin
                </TableCell>
                {!!showWage && (
                    <TableCell xs={3} right>
                        Celkem
                    </TableCell>
                )}
            </TableHeader>
            <TableBody>
                {Array.isArray(data) &&
                    data.map((row, key) => (
                        <TableRow key={row.id || key}>
                            <TableCell xs={showWage ? 6 : 8}>{row.name || "<bez projektu>"}</TableCell>
                            <TableCell xs={3} right>
                                {formatNum(row.value || 0)} h
                            </TableCell>
                            {!!showWage && (
                                <TableCell xs={3} right>
                                    {formatCurrency(row.amount, currency)}
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
            </TableBody>
        </Box>
    );
};

export const ProjectEmployeesTable = ({ data = [], currency, ...props }) => {
    return (
        <Box {...props}>
            <TableHeader>
                <TableCell xs={6}>Zaměstnanec</TableCell>
                <TableCell xs={3} right>
                    Počet hodin
                </TableCell>
                <TableCell xs={3} right>
                    Celkem
                </TableCell>
            </TableHeader>
            <TableBody>
                {Array.isArray(data) &&
                    data.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell xs={6}>{row.name}</TableCell>
                            <TableCell xs={3} right>
                                {formatNum(row.value || 0)} h
                            </TableCell>
                            <TableCell xs={3} right>
                                {formatCurrency(row.amount, currency)}
                            </TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Box>
    );
};

const noop = () => {};
export const EmployeeTimesheet = ({ showLoader = noop, hideLoader = noop, isLoading, employeeId, showWage, filter, currency, ...props }) => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState([]);
    const [overview, setOverview] = useState({});
    const periodRef = usePeriod();
    const [period] = periodRef;
    const [activeTab, setActiveTab] = useState(0);
    const switchTab = (event, newValue) => setActiveTab(newValue);

    const load = async (page) => {
        showLoader();
        try {
            let list = await fetchEmployeeTimesheet(employeeId, period.start, period.end, filter, page);
            let overview = await fetchEmployeeTimesheetOverview(employeeId, period.start, period.end, filter);
            setData(list);
            setOverview(overview);
        } catch (e) {
            setData([]);
            setOverview({});
            enqueueSnackbar("Došlo k chybě při načítání dat", { variant: "error" });
            throw e;
        } finally {
            hideLoader();
        }
    };

    const saveItem = async (values) => {
        showLoader();
        try {
            await saveEmployeeTimesheet(employeeId, values);
            load();
        } catch (e) {
            enqueueSnackbar("Došlo k chybě při ukládání dat", { variant: "error" });
            throw e;
        } finally {
            hideLoader();
        }
    };

    const deleteItem = async (id) => {
        showLoader();
        try {
            await deleteEmployeeTimesheet(employeeId, id);
            load();
        } catch (e) {
            enqueueSnackbar("Došlo k chybě při mazání dat", { variant: "error" });
            throw e;
        } finally {
            hideLoader();
        }
    };

    useEffect(() => {
        load();
    }, [period, employeeId, filter]);

    return (
        <React.Fragment>
            <PeriodSelector py={3} periodRef={periodRef} disabled={isLoading} />

            <Box py={3}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={7}>
                        <EmployeeTimsheetHeatmap
                            month={period.month}
                            year={period.year}
                            data={overview.heatmap}
                            disabled={isLoading}
                            editable={true}
                            employeeId={employeeId}
                            onSaveItem={saveItem}
                            showWage={showWage}
                            daysOverview={overview.daysOverview}
                            currency={currency}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} alignSelf="center">
                        <DoughnutChart data={[overview.projectsChart || []]} colors={[theme.palette.primary.main]}>
                            <div>
                                <Typography variant="h6" display="block" gutterBottom>
                                    Celkem
                                </Typography>
                                <Typography variant="h5" display="block" gutterBottom={!!showWage}>
                                    {isLoading ? "-" : formatNum(overview.totalHours || "0") + " h "}
                                </Typography>
                                {!!showWage && (
                                    <Typography variant="h5" display="block">
                                        {isLoading ? "-" : formatCurrency(overview.totalAmount, currency)}
                                    </Typography>
                                )}
                            </div>
                        </DoughnutChart>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2}>
                <Tabs indicatorColor="primary" textColor="primary" value={activeTab} onChange={switchTab} disabled={isLoading}>
                    <Tab label="Položky výkazu" disabled={isLoading} />
                    <Tab label="Souhrn" disabled={isLoading} />
                    <Tab label="Týdenní přehled" disabled={isLoading} />
                    <Tab label="Denní přehled" disabled={isLoading} />
                </Tabs>
            </Box>

            <Box py={3}>
                {activeTab === 0 && (
                    <React.Fragment>
                        <EmployeeTimesheetTable
                            data={data && data.content}
                            disabled={isLoading}
                            employeeId={employeeId}
                            onSaveItem={saveItem}
                            onDeleteItem={deleteItem}
                            showWage={showWage}
                            currency={currency}
                        />
                        <TablePagination pagingInfo={data && data.pagingInfo} onChangePage={load} disabled={isLoading} />
                    </React.Fragment>
                )}
                {activeTab === 1 && overview.projects && !!overview.projects.length && (
                    <EmployeeProjectsTable data={overview.projects} showWage={showWage} currency={currency} />
                )}
                {activeTab === 2 && overview.weeks && !!overview.weeks.length && (
                    <EmployeeWeeksTable data={overview.weeks} showWage={showWage} currency={currency} />
                )}
                {activeTab === 3 && overview.days && !!overview.days.length && (
                    <EmployeeDaysTable data={overview.days} showWage={showWage} currency={currency} />
                )}
            </Box>
        </React.Fragment>
    );
};

const OverviewTable = ({}) => {
    return <Box p={2}></Box>;
};

//EmployeeProjectsTable data={projects}
const useStyles = makeStyles((theme) => {
    const transition = {
        duration: theme.transitions.duration.shortest,
    };

    return {
        expandIcon: {
            transform: "rotate(0deg)",
            transition: theme.transitions.create("transform", transition),
        },
        expanded: {
            transform: "rotate(180deg)",
        },
        noPadding: {
            padding: 0,
        },
    };
});

export const EmployeeWeeksTable = ({ data, disabled, showWage, currency }) => {
    const classes = useStyles();
    const [open, setOpen] = useState({});
    useEffect(() => {
        setOpen({});
    }, [data]);

    if (!Array.isArray(data)) {
        return null;
    }

    return (
        <Table>
            <TableHeader>
                <TableCell xs={6}>Týden</TableCell>
                <TableCell xs={6} right>
                    Počet hodin
                    {!!showWage && <Typography color="textSecondary">Mzda</Typography>}
                </TableCell>
            </TableHeader>
            <TableBody>
                {Array.isArray(data) &&
                    data.map((row, key) => (
                        <TableRow key={key}>
                            <TableCell xs={6}>
                                <IconButton
                                    onClick={() => setOpen({ ...open, [key]: !open[key] })}
                                    className={clsx(classes.expandIcon, {
                                        [classes.expanded]: !!open[key],
                                    })}>
                                    <ExpandMoreIcon />
                                </IconButton>
                                {formatDate(row.start)} - {formatDate(row.end)}
                            </TableCell>
                            <TableCell xs={6} right>
                                {formatNum(row.totalHours || 0)} h
                                {!!showWage && <Typography color="textSecondary">{formatCurrency(row.totalAmount, currency)}</Typography>}
                            </TableCell>
                            <TableCell xs={12} style={{ padding: 0 }}>
                                <Collapse in={!!open[key]}>
                                    <Box p={3}>
                                        <EmployeeProjectsTable data={row.projects || []} showWage={showWage} currency={currency} />
                                    </Box>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    );
};

export const EmployeeDaysTable = ({ data, disabled, showWage, currency }) => {
    const classes = useStyles();
    const [open, setOpen] = useState({});
    useEffect(() => {
        setOpen({});
    }, [data]);

    if (!Array.isArray(data)) {
        return null;
    }

    return (
        <Table>
            <TableHeader>
                <TableCell xs={6}>Datum</TableCell>
                <TableCell xs={6} right>
                    Počet hodin
                    {!!showWage && <Typography color="textSecondary">Mzda</Typography>}
                </TableCell>
            </TableHeader>
            <TableBody>
                {Array.isArray(data) &&
                    data.map((row, key) => (
                        <TableRow key={key}>
                            <TableCell xs={6}>
                                <IconButton
                                    onClick={() => setOpen({ ...open, [key]: !open[key] })}
                                    className={clsx(classes.expandIcon, {
                                        [classes.expanded]: !!open[key],
                                    })}>
                                    <ExpandMoreIcon />
                                </IconButton>
                                {formatDate(row.start)}
                            </TableCell>
                            <TableCell xs={6} right>
                                {formatNum(row.totalHours || 0)} h
                                {!!showWage && <Typography color="textSecondary">{formatCurrency(row.totalAmount, currency)}</Typography>}
                            </TableCell>
                            <TableCell xs={12} style={{ padding: 0 }}>
                                <Collapse in={!!open[key]}>
                                    <Box p={3}>
                                        <EmployeeProjectsTable data={row.projects || []} showWage={showWage} currency={currency} />
                                    </Box>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    );
};

export default {
    loginTitle: {
        id: "lib.security.loginTitle",
        defaultMessage: "Login",
    },
    usernamePlaceholder: {
        id: "lib.security.usernamePlaceholder",
        defaultMessage: "email",
    },
    passwordPlaceholder: {
        id: "lib.security.passwordPlaceholder",
        defaultMessage: "password",
    },
    newPasswordPlaceholder: {
        id: "lib.security.newPasswordPlaceholder",
        defaultMessage: "new password",
    },
    loginAction: {
        id: "lib.security.loginAction",
        defaultMessage: "Login",
    },
    forgotPassword: {
        id: "lib.security.forgotPassword",
        defaultMessage: "Forgot password",
    },

    signupTitle: {
        id: "lib.security.signupTitle",
        defaultMessage: "Signup",
    },
    agreement: {
        id: "lib.security.termsAndConditions",
        defaultMessage: "I agree with {subject}",
    },
    privacyPolicy: {
        id: "lib.security.privacyPolicy",
        defaultMessage: "privacy policy",
    },
    termsAndConditions: {
        id: "lib.security.privacyPolicyLink",
        defaultMessage: "terms and conditions",
    },

    signupAction: {
        id: "lib.security.signupAction",
        defaultMessage: "Signup",
    },

    resetPasswordTitle: {
        id: "lib.security.resetPasswordTitle",
        defaultMessage: "Reset password",
    },
    resetPasswordAction: {
        id: "lib.security.resetPasswordAction",
        defaultMessage: "Change password",
    },

    createNewAccount: {
        id: "lib.security.createNewAccount",
        defaultMessage: "Create new account",
    },
    forgottenPassword: {
        id: "lib.security.forgottenPassword",
        defaultMessage: "Having trouble logging in?",
    },
    home: {
        id: "lib.security.home",
        defaultMessage: "Home",
    },
    alreadyHaveAccount: {
        id: "lib.security.alreadyHaveAccount",
        defaultMessage: "Already have an account?",
    },

    infoPasswordChangeRequested: {
        id: "lib.security.infoPasswordChangeRequested",
        defaultMessage: "Information about how to change your password has been sent to email address registered for username {login}",
    },

    genericError: {
        id: "lib.security.genericError",
        defaultMessage: "Unexpected error occured: {payload}",
    },

    errorUSER_NOT_FOUND: {
        id: "lib.security.errorUSER_NOT_FOUND",
        defaultMessage: "User not found",
    },
    errorBAD_USERNAME: {
        id: "lib.security.errorBAD_USERNAME",
        defaultMessage: "Bad username (email)",
    },
    errorBAD_EMAIL: {
        id: "lib.security.errorBAD_EMAIL",
        defaultMessage: "Bad email",
    },
    errorUSER_ALREADY_EXISTS: {
        id: "lib.security.errorUSER_ALREADY_EXISTS",
        defaultMessage: "User already exists",
    },
    errorBAD_USERNAME_OR_PASSWORD: {
        id: "lib.security.errorBAD_USERNAME_OR_PASSWORD",
        defaultMessage: "Bad username or password",
    },
    errorBAD_PASSWORD: {
        id: "lib.security.errorBAD_PASSWORD",
        defaultMessage: "Bad password",
    },
    errorINVALID_TOKEN: {
        id: "lib.security.errorINVALID_TOKEN",
        defaultMessage: "Invalid token. Make sure to use the link from the email",
    },
    errorTERMS_REQUIRED: {
        id: "lib.security.errorTERMS_REQUIRED",
        defaultMessage: "You have to agree with terms and conditions",
    },
    errorPRIVACY_REQUIRED: {
        id: "lib.security.errorPRIVACY_REQUIRED",
        defaultMessage: "You have to agree with privacy policy",
    },
};

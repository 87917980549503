import backend, { parseErrors, download, upload } from "lib/backend";

export const fetchSummary = async (from, to) => {
    try {
        let response = await backend("/api/dashboard/summary", "GET", {
            params: { from, to },
        });
        return response.body;
    } catch (e) {
        console.log(e);
        throw e;
    }
};

import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    buttonBar: {
        "& > *": {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        marginLeft: theme.spacing(-1),
        marginRight: theme.spacing(-1),
        position: "relative",
        textAlign: "right",
        "& .MuiIconButton-label": {
            color: theme.palette.primary.contrastText,
        },
    },
}));

export default (props) => {
    const classes = useStyles();

    return (
        <Box className={classes.buttonBar} {...props} py={props.py || props.p || 0} px={props.px || props.p || 0}>
            {props.children}
        </Box>
    );
};

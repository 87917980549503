import React from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

//MuiInputAdornment-root

const useStyles = makeStyles((theme) => ({
    large: {
        "& .MuiInputAdornment-root .MuiTypography-root": {
            fontSize: 26,
        },
    },
}));

export default ({ fullWidth, margin, label, helperText, value, onChange, name, error, currencySymbol, currencySymbolPosition, large, variant }) => {
    const classes = useStyles();

    let sizeStyle = {};
    if (large) {
        sizeStyle.fontSize = 26;
    }

    let props = {
        inputProps: {
            style: { textAlign: currencySymbolPosition || "right", ...sizeStyle },
        },
        className: large ? classes.large : null,
    };

    if (currencySymbol) {
        props[currencySymbolPosition == "right" ? "endAdornment" : "startAdornment"] = (
            <InputAdornment position={currencySymbolPosition == "right" ? "end" : "start"}>{currencySymbol}</InputAdornment>
        );
    }

    return (
        <TextField
            margin={margin}
            fullWidth={fullWidth}
            error={error}
            variant={variant}
            label={label}
            helperText={helperText}
            value={value}
            onChange={onChange}
            name={name}
            InputProps={props}
        />
    );
    /*
    return (
        <FormControl margin={ margin } fullWidth={ fullWidth } error={ error } variant="outlined">
            { !!label ? <InputLabel variant="outlined">{ label }</InputLabel> : null }
            <OutlinedInput
                value={ value }
                onChange={ onChange }
                name={ name }
                variant="outlined"

                {...props}
                />
            { !!helperText ? <FormHelperText id="standard-weight-helper-text">{ helperText }</FormHelperText> : null }
        </FormControl>
        );
        */
};

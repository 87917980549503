import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Zoom from "@material-ui/core/Zoom";

import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

const useStyles = makeStyles((theme) => ({
    speedDial: {
        position: "fixed",
        "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
        "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
            top: theme.spacing(2),
            left: theme.spacing(2),
        },
    },
    fab: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

export default ({ actions, icon, onClick, color, ...props }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(!open);
    };

    if (!actions || !Array.isArray(actions) || !actions.length) {
        return (
            <Zoom
                in
                timeout={250}
                style={{
                    transitionDelay: `250ms`,
                }}
                unmountOnExit>
                <Fab className={classes.fab} color={color || "primary"} onClick={onClick}>
                    {icon || <AddIcon />}
                </Fab>
            </Zoom>
        );
    }

    //TODO implement properly
    return (
        <Zoom
            in
            timeout={250}
            style={{
                transitionDelay: `250ms`,
            }}
            unmountOnExit>
            <SpeedDial
                ariaLabel="SpeedDial openIcon example"
                className={classes.speedDial}
                icon={<SpeedDialIcon icon={icon || <AddIcon />} />}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}>
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => {
                            setOpen(false);
                            action.action && action.action();
                        }}
                    />
                ))}
            </SpeedDial>
        </Zoom>
    );
};

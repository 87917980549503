import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default ({ open, onClose, fullscreenBreakpoint = "sm", maxWidth, title, actions, children }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down(fullscreenBreakpoint));

    return (
        <Dialog open={open} onClose={onClose} fullScreen={fullScreen} maxWidth={maxWidth || fullscreenBreakpoint} fullWidth>
            {fullScreen ? (
                <AppBar position="relative">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" style={{ flex: "1 1 auto" }}>
                            {title}
                        </Typography>
                        <Button autoFocus color="inherit" type="button" startIcon={<SaveIcon />}>
                            Uložit
                        </Button>
                    </Toolbar>
                </AppBar>
            ) : (
                <DialogTitle>{title}</DialogTitle>
            )}

            <DialogContent>
                {children}

                {fullScreen ? null : (
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
                            Zpět
                        </Button>
                        <Button type="button" color="primary">
                            Uložit
                        </Button>
                    </DialogActions>
                )}
            </DialogContent>
        </Dialog>
    );
};

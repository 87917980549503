import React, { useState, useEffect } from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";

import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";

import InputAdornment from "@material-ui/core/InputAdornment";

import Grid from "@material-ui/core/Grid";

import Alert from "lib/components/Alert";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import { Formik } from "formik";

import { saveListElement, removeListElement } from "lib/list";

import { Table, TableHeader, TableHeaderActions, TableActions, TableBody, TableRow, TableCell, TableFormCell } from "lib/components/Table";

const Row = ({ item, odd, onEdit, onDelete }) => {
    return (
        <TableRow>
            <TableCell xs={12} md={4}>
                {item.name}
            </TableCell>
            <TableCell xs={12} md={3}>
                {item.phone}
            </TableCell>
            <TableCell xs={12} md={3}>
                {item.email}
            </TableCell>
            <TableActions xs={12} md={2}>
                <IconButton onClick={onEdit}>
                    <CreateIcon fontSize="small" />
                </IconButton>
                <IconButton onClick={onDelete}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </TableActions>
        </TableRow>
    );
};

const RowForm = ({ item, onSubmit, onCancel, odd, disabled, readOnly }) => {
    return (
        <TableRow>
            <Formik
                initialValues={item || {}}
                enableReinitialize={true}
                validate={(values) => {
                    let errors = {};

                    if (!values.name) {
                        errors.name = "Povinné pole";
                    }
                    if (!values.phone && !values.email) {
                        errors.phone = "Vyplňte telefon nebo email";
                        errors.email = "Vyplňte telefon nebo email";
                    }

                    return errors;
                }}
                validateOnChange={false}
                onSubmit={onSubmit}>
                {({ values, setFieldValue, setValues, errors, handleChange, handleBlur, submitForm }) => {
                    return (
                        <React.Fragment>
                            <TableFormCell xs={12} md={4}>
                                <TextField
                                    label="Jméno"
                                    name="name"
                                    value={values.name || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    InputProps={{ readOnly }}
                                    disabled={disabled}
                                    helperText={errors.name}
                                    error={!!errors.name}
                                />
                            </TableFormCell>
                            <TableFormCell xs={12} md={3}>
                                <TextField
                                    label="Telefon"
                                    name="phone"
                                    value={values.phone || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    InputProps={{ readOnly }}
                                    disabled={disabled}
                                    helperText={errors.phone}
                                    error={!!errors.phone}
                                />
                            </TableFormCell>
                            <TableFormCell xs={12} md={3}>
                                <TextField
                                    label="Email"
                                    name="email"
                                    value={values.email || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    margin="dense"
                                    InputProps={{ readOnly }}
                                    disabled={disabled}
                                    helperText={errors.email}
                                    error={!!errors.email}
                                />
                            </TableFormCell>
                            <TableActions xs={12} md={2}>
                                <IconButton onClick={submitForm}>
                                    <CheckIcon fontSize="small" />
                                </IconButton>
                                <IconButton onClick={onCancel}>
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            </TableActions>
                        </React.Fragment>
                    );
                }}
            </Formik>
        </TableRow>
    );
};

export const ContactsForm = ({ value, onChange, disabled, ...props }) => {
    const [formIndex, setFormIndex] = useState(false);

    const submitForm = (values) => {
        saveListElement(value, formIndex, values, onChange);
        setFormIndex(false);
    };

    useEffect(() => setFormIndex(false), [value]);

    let val = value || [];

    return (
        <Table>
            <TableHeader>
                <TableCell xs={12} md={4}>
                    Jméno
                </TableCell>
                <TableCell xs={12} md={3}>
                    Telefon
                </TableCell>
                <TableCell xs={12} md={3}>
                    Email
                </TableCell>
                <TableHeaderActions xs={12} md={2}>
                    <IconButton onClick={() => setFormIndex(val.length)}>
                        <AddIcon fontSize="small" />
                    </IconButton>
                </TableHeaderActions>
            </TableHeader>
            <TableBody>
                {val.map((item, key) =>
                    key === formIndex ? (
                        <RowForm item={item} key={`form-${key}`} onCancel={() => setFormIndex(false)} onSubmit={submitForm} />
                    ) : (
                        <Row
                            item={item}
                            key={`data-${key}`}
                            onEdit={() => setFormIndex(key)}
                            onDelete={() =>
                                Alert.alert("Smazat kontakt", `Chcete smazat kontakt ${item.name}?`, [
                                    { text: "Ne" },
                                    { text: "Ano", onPress: () => removeListElement(value, key, onChange) },
                                ])
                            }
                        />
                    )
                )}
                {formIndex !== false && formIndex >= val.length ? <RowForm item={{}} onCancel={() => setFormIndex(false)} onSubmit={submitForm} /> : null}
            </TableBody>
        </Table>
    );
};

import React, { useEffect, useState, useContext, useRef } from "react";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "lib/components/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import Switch from "@material-ui/core/Switch";
import CurrencyField from "lib/components/CurrencyField";

import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import Alert from "lib/components/Alert";

import { LayoutHeader, DesktopMenu, MobileMenu } from "lib/components/layout";
import { Table, TableHeader, TableHeaderActions, TableActions, TableBody, TableRow, TableCell, TableFormCell, TablePagination } from "lib/components/Table";

import { Formik } from "formik";

import moment from "moment";
import validator from "lib/validator";
import { parseErrors } from "lib/backend";

import { useSnackbar } from "notistack";
import PeriodSelector, { usePeriod } from "components/period";
import { AttachmentChips, AttachmentField, AttachmentDropzone } from "lib/components/Attachment";
import { formatDate, formatNum, parseNum, formatCurrency, dateToString } from "lib/format";
import {
    fetchEmployeeExpense,
    saveEmployeeExpense,
    filterProjectOptions,
    deleteEmployeeExpense,
    fetchProjectExpense,
    saveProjectExpense,
    filterEmployeeOptions,
    deleteProjectExpense,
    filterExpenseOptions,
} from "./actions";

const Row = ({ item, odd, showProject, showEmployee, onEdit, onDelete, showPrice, disabled, currency }) => {
    return (
        <TableRow>
            <TableCell item xs={12} md={2}>
                {formatDate(item.date)}
            </TableCell>
            {!!showProject && (
                <TableCell item xs={12} md={2}>
                    {item.projectName}
                    {!!item.clientName && (
                        <Typography display="block" color="textSecondary">
                            {item.clientName}
                        </Typography>
                    )}
                </TableCell>
            )}
            {!!showEmployee && (
                <TableCell item xs={12} md={2}>
                    {item.employeeName}
                </TableCell>
            )}
            <TableCell item xs={12} md={4}>
                <Typography display="block">{item.templateName}</Typography>
                {!!item.description && (
                    <Typography display="block" color="textSecondary">
                        {item.description}
                    </Typography>
                )}
            </TableCell>
            <TableCell item xs={12} md={2} right>
                <Typography display="block">
                    {(item.amount && formatNum(item.amount)) || "0"} {item.unit || ""}
                </Typography>
                <Typography display="block" color="textSecondary">
                    {formatCurrency(item.unitPrice, currency)}
                    {item.unit ? "/" + item.unit : null}
                </Typography>
            </TableCell>
            <TableActions xs={12} md={2}>
                <IconButton onClick={onEdit} disabled={disabled}>
                    <CreateIcon fontSize="small" />
                </IconButton>
                <IconButton onClick={onDelete} disabled={disabled}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </TableActions>
            <AttachmentChips attachments={item.attachments} url={(row) => `/api/expenses/${row.objectId}/attachments/${row.id}`} disabled={disabled} />
        </TableRow>
    );
};

const createRowFormClasses = makeStyles((theme) => ({
    root: {
        display: "inline-block",
        textAlign: "left",
        width: "100%",
        maxWidth: 380,
    },
}));

const filterProjects = (options, { inputValue, ...state }) => {
    if (!Array.isArray(options)) {
        return [];
    }

    if (!inputValue) {
        return options;
    }

    let val = inputValue.toLowerCase();

    return options.filter((i) => (i && i.name && i.name.toLowerCase().includes(val)) || (i.clientName && i.clientName.toLowerCase().includes(val)));
};

const RowForm = ({ item, onSubmit, onCancel, showProject, showEmployee, projectId, employeeId, disabled, readOnly, showPrice, currency }) => {
    const classes = createRowFormClasses();
    const uploadRef = useRef();

    return (
        <Formik
            initialValues={
                item
                    ? {
                          ...item,
                          amount: formatNum(item.amount || 0),
                          unitPrice: formatNum(item.unitPrice || 0),
                          billableUnitPrice: formatNum(item.billableUnitPrice || 0),
                      }
                    : {}
            }
            enableReinitialize={true}
            validate={(values) => {
                let errors = {};

                if (!values.date) {
                    errors.date = "Povinné pole";
                }
                if (showProject && !values.projectId) {
                    errors.project = "Povinné pole";
                }
                if (showEmployee && !values.employeeId) {
                    errors.employee = "Povinné pole";
                }
                if (!values.templateId) {
                    errors.template = "Povinné pole";
                }
                if (!values.amount) {
                    errors.amount = "Povinné pole";
                } else if (!validator.decimal(values.amount)) {
                    errors.amount = "Chybný formát čísla";
                }

                if (!values.unitPrice) {
                    errors.unitPrice = "Povinné pole";
                } else if (!validator.decimal(values.unitPrice)) {
                    errors.unitPrice = "Chybný formát čísla";
                }

                if (showPrice) {
                    if (values.billable) {
                        if (!values.billableUnitPrice) {
                            errors.billableUnitPrice = "Povinné pole";
                        } else if (!validator.decimal(values.billableUnitPrice)) {
                            errors.billableUnitPrice = "Chybný formát čísla";
                        }
                    }
                }
                return errors;
            }}
            validateOnChange={false}
            onSubmit={(values) => {
                onSubmit &&
                    onSubmit({
                        ...values,
                        amount: parseNum(values.amount),
                        unitPrice: parseNum(values.unitPrice),
                        date: dateToString(values.date),
                        billableUnitPrice: parseNum(values.billableUnitPrice),
                    });
            }}>
            {({ values, setFieldValue, setValues, errors, handleChange, handleBlur, submitForm }) => {
                return (
                    <TableRow>
                        <TableCell xs={12} center>
                            <AttachmentDropzone uploadRef={uploadRef}>
                                <Box className={classes.root}>
                                    <KeyboardDatePicker
                                        label="Datum"
                                        margin="dense"
                                        name="date"
                                        value={values.date || null}
                                        onChange={(v) => setFieldValue("date", v)}
                                        disabled={disabled}
                                        format="DD.MM.YYYY"
                                        fullWidth
                                        autoOk
                                        helperText={errors.date}
                                        error={!!errors.date}
                                    />
                                    {showProject ? (
                                        <Autocomplete
                                            label="Projekt"
                                            id="project"
                                            value={values.projectId ? { id: values.projectId, name: values.projectName } : null}
                                            onChange={(e, v) => {
                                                let vals = { ...values };
                                                if (v) {
                                                    vals.projectId = v.id;
                                                    vals.projectName = v.name;
                                                    if (v.unitPrice !== null) {
                                                        vals.unitPrice = formatNum(v.unitPrice);
                                                    }
                                                    if (v.billable !== null) {
                                                        vals.billable = v.billable;
                                                    }
                                                    if (v.billableUnitPrice !== null) {
                                                        vals.billableUnitPrice = formatNum(v.billableUnitPrice);
                                                    }
                                                } else {
                                                    vals.projectId = null;
                                                    vals.projectName = null;
                                                }
                                                setValues(vals);
                                            }}
                                            error={!!errors.project}
                                            helperText={errors.project}
                                            getOptionLabel={(option) => option.name}
                                            fetchOptions={async () => filterProjectOptions("", values.templateId)}
                                            filterOptions={filterProjects}
                                            renderOption={(option, { selected }) => (
                                                <div>
                                                    {option.name}
                                                    {!!option.clientName && (
                                                        <Typography display="block" variant="caption" color="textSecondary">
                                                            {option.clientName}
                                                        </Typography>
                                                    )}
                                                </div>
                                            )}
                                            fullWidth
                                            disabled={disabled}
                                            margin="dense"
                                            loadingText="Načítají se hodnoty..."
                                            noOptionsText="Žádné hodnoty"
                                        />
                                    ) : (
                                        <Autocomplete
                                            label="Zaměstnanec"
                                            id="employee"
                                            value={values.employeeId ? { id: values.employeeId, name: values.employeeName } : null}
                                            onChange={(e, v) => {
                                                setValues({ ...values, employeeId: v ? v.id : null, employeeName: v ? v.name : null });
                                            }}
                                            error={!!errors.employee}
                                            helperText={errors.employee}
                                            getOptionLabel={(option) => option.name}
                                            fetchOptions={async () => filterEmployeeOptions(projectId)}
                                            fullWidth
                                            disabled={disabled}
                                            margin="dense"
                                            loadingText="Načítají se hodnoty..."
                                            noOptionsText="Žádné hodnoty"
                                        />
                                    )}
                                    <Autocomplete
                                        label="Výdaj"
                                        id="template"
                                        value={values.templateId ? { id: values.templateId, name: values.templateName } : null}
                                        onChange={(e, v) => {
                                            if (v && v.id) {
                                                setValues({
                                                    ...values,
                                                    templateId: v.id,
                                                    templateName: v.name,
                                                    unit: v.unit,
                                                    unitPrice: formatNum(v.unitPrice),
                                                    billable: v.billable,
                                                    billableUnitPrice: formatNum(v.billableUnitPrice),
                                                });
                                            } else {
                                                setValues({
                                                    ...values,
                                                    templateId: null,
                                                    templateName: null,
                                                    unit: null,
                                                    unitPrice: null,
                                                    billable: null,
                                                    billableUnitPrice: null,
                                                });
                                            }
                                        }}
                                        error={!!errors.template}
                                        helperText={errors.template}
                                        getOptionLabel={(option) => option.name}
                                        fetchOptions={async () => filterExpenseOptions("", showProject ? values.projectId : projectId)}
                                        fullWidth
                                        disabled={disabled}
                                        margin="dense"
                                        loadingText="Načítají se hodnoty..."
                                        noOptionsText="Žádné hodnoty"
                                    />
                                    <TextField
                                        label="Popis"
                                        name="description"
                                        value={values.description || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        margin="dense"
                                        InputProps={{ readOnly }}
                                        disabled={disabled}
                                        helperText={errors.description}
                                        error={!!errors.description}
                                    />
                                    <TextField
                                        label="Počet"
                                        name="amount"
                                        value={values.amount || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        margin="dense"
                                        InputProps={{
                                            readOnly,
                                            endAdornment: <InputAdornment position="end">{values.unit || ""}</InputAdornment>,
                                            inputProps: { style: { textAlign: "right" } },
                                        }}
                                        disabled={disabled}
                                        helperText={errors.amount}
                                        error={!!errors.amount}
                                    />
                                    <CurrencyField
                                        label="Jednotková cena"
                                        name="unitPrice"
                                        value={values.unitPrice || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        margin="dense"
                                        readOnly={readOnly}
                                        currency={currency}
                                        unit={values.unit}
                                        disabled={disabled}
                                        helperText={errors.unitPrice}
                                        error={!!errors.unitPrice}
                                    />
                                    {!!showPrice && (
                                        <Box display="flex" alignItems="center">
                                            <Box flexGrow={1} color={values.billable && !disabled ? "text.primary" : "text.secondary"}>
                                                <Typography>Fakturovat klientovi</Typography>
                                            </Box>
                                            <Switch
                                                color="secondary"
                                                checked={values.billable || false}
                                                onChange={handleChange}
                                                name="billable"
                                                value={true}
                                                disabled={disabled}
                                            />
                                        </Box>
                                    )}
                                    {!!showPrice && !!values.billable && (
                                        <CurrencyField
                                            label="Fakturovaná jednotková cena"
                                            name="billableUnitPrice"
                                            value={values.billableUnitPrice || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            margin="dense"
                                            readOnly={readOnly}
                                            currency={currency}
                                            unit={values.unit}
                                            disabled={disabled}
                                            helperText={errors.billableUnitPrice}
                                            error={!!errors.billableUnitPrice}
                                        />
                                    )}
                                    <AttachmentField
                                        disabled={disabled}
                                        value={values.attachments}
                                        uploadRef={uploadRef}
                                        onChange={(attachments) => setFieldValue("attachments", attachments)}
                                    />
                                </Box>
                            </AttachmentDropzone>
                        </TableCell>
                        <TableActions xs={12}>
                            <IconButton onClick={onCancel} disabled={disabled}>
                                <ClearIcon />
                            </IconButton>
                            <IconButton onClick={submitForm} disabled={disabled}>
                                <CheckIcon />
                            </IconButton>
                        </TableActions>
                    </TableRow>
                );
            }}
        </Formik>
    );
};

const ExpenseTable = ({ data = [], disabled, employeeId, projectId, showPrice, editable = true, onSaveItem, onDeleteItem, currency, ...props }) => {
    const [formIndex, setFormIndex] = useState(false);
    useEffect(() => setFormIndex(false), [data, employeeId, projectId, editable]);

    const showProject = !!employeeId;
    const showEmployee = !!projectId;

    return (
        <React.Fragment>
            {/* header */}
            <TableHeader container>
                <TableCell item xs={12} md={2}>
                    Datum
                </TableCell>
                {showProject && (
                    <TableCell item xs={12} md={2}>
                        Projekt
                    </TableCell>
                )}
                {showEmployee && (
                    <TableCell item xs={12} md={2}>
                        Zaměstnanec
                    </TableCell>
                )}
                <TableCell item xs={12} md={4}>
                    Náklad
                </TableCell>
                <TableCell item xs={12} md={2} right>
                    Počet
                </TableCell>
                <TableHeaderActions item xs={12} md={2}>
                    <IconButton onClick={() => setFormIndex(-1)} disabled={disabled}>
                        <AddIcon fontSize="small" />
                    </IconButton>
                </TableHeaderActions>
            </TableHeader>
            <TableBody>
                {formIndex === -1 ? (
                    <RowForm
                        item={{}}
                        onCancel={() => setFormIndex(false)}
                        onSubmit={onSaveItem}
                        employeeId={employeeId}
                        projectId={projectId}
                        showProject={showProject}
                        showEmployee={showEmployee}
                        showPrice={showPrice}
                        disabled={disabled}
                        currency={currency}
                    />
                ) : null}
                {Array.isArray(data) &&
                    data.map((item, key) =>
                        key === formIndex ? (
                            <RowForm
                                item={item}
                                key={key}
                                onCancel={() => setFormIndex(false)}
                                onSubmit={onSaveItem}
                                employeeId={employeeId}
                                projectId={projectId}
                                showProject={showProject}
                                showEmployee={showEmployee}
                                showPrice={showPrice}
                                currency={currency}
                                disabled={disabled}
                            />
                        ) : (
                            <Row
                                item={item}
                                key={key}
                                onEdit={() => setFormIndex(key)}
                                showProject={showProject}
                                showEmployee={showEmployee}
                                showPrice={showPrice}
                                disabled={disabled}
                                currency={currency}
                                onDelete={() =>
                                    Alert.alert(
                                        "Smazat položku výkazu",
                                        `Chcete smazat položku ${formatDate(item.date)} ${item.templateName} ${item.description || ""} ?`,
                                        [{ text: "Ne" }, { text: "Ano", onPress: () => onDeleteItem && onDeleteItem(item.id) }]
                                    )
                                }
                            />
                        )
                    )}
            </TableBody>
        </React.Fragment>
    );
};

export const EmployeeExpenseTable = ({ data = [], disabled, employeeId, showPrice, editable = true, onSaveItem, onDeleteItem, currency, ...props }) => {
    return (
        <ExpenseTable
            disabled={disabled}
            employeeId={employeeId}
            showPrice={showPrice}
            editable={editable}
            data={data}
            onSaveItem={onSaveItem}
            onDeleteItem={onDeleteItem}
            currency={currency}
        />
    );
};

export const ProjectExpenseTable = ({ data = [], disabled, projectId, showPrice, editable = true, onSaveItem, onDeleteItem, currency, ...props }) => {
    const [formIndex, setFormIndex] = useState(false);
    useEffect(() => setFormIndex(false), [data, projectId, editable]);

    const submitForm = async (vals) => {
        try {
            if (onSaveItem) {
                await onSaveItem(vals);
            }

            setFormIndex(false);
        } catch (e) {
            console.error(e);
        }
    };

    const deleteItem = async (id) => {
        try {
            if (onDeleteItem) {
                await onDeleteItem(id);
                setFormIndex(false);
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Table>
            <TableHeader>
                <TableCell xs={12} md={2}>
                    Datum
                </TableCell>
                <TableCell xs={12} md={4}>
                    Náklad
                </TableCell>
                <TableCell xs={12} md={2} right>
                    Počet
                    <Typography color="textSecondary">Jednotková cena</Typography>
                </TableCell>
                <TableCell xs={12} md={2} right>
                    Fakturovat klientovi
                </TableCell>
                <TableHeaderActions xs={12} md={2}>
                    {!!editable && (
                        <IconButton onClick={() => setFormIndex(-1)} disabled={disabled}>
                            <AddIcon fontSize="small" />
                        </IconButton>
                    )}
                </TableHeaderActions>
            </TableHeader>

            <TableBody>
                {formIndex === -1 ? (
                    <RowForm
                        item={{}}
                        onCancel={() => setFormIndex(false)}
                        onSubmit={submitForm}
                        projectId={projectId}
                        showEmployee
                        showPrice
                        disabled={disabled}
                        currency={currency}
                    />
                ) : null}
                {Array.isArray(data) &&
                    data.map((item, key) =>
                        key === formIndex ? (
                            <RowForm
                                item={item}
                                key={key}
                                onCancel={() => setFormIndex(false)}
                                onSubmit={submitForm}
                                projectId={projectId}
                                showEmployee
                                showPrice
                                currency={currency}
                                disabled={disabled}
                            />
                        ) : (
                            <TableRow key={key}>
                                <TableCell xs={12} md={2}>
                                    {formatDate(item.date)}
                                    <Typography color="textSecondary">{item.employeeName}</Typography>
                                </TableCell>
                                <TableCell xs={12} md={4}>
                                    {item.templateName}
                                    <Typography color="textSecondary">{item.description}</Typography>
                                </TableCell>
                                <TableCell xs={12} md={2} right>
                                    {formatNum(item.amount)} {item.unit}
                                    <Typography display="block" color="textSecondary">
                                        {formatCurrency(item.unitPrice, currency)}/{item.unit}
                                    </Typography>
                                </TableCell>
                                <TableCell xs={12} md={2} right>
                                    {item.billable ? "Ano" : "Ne"}
                                    <Typography display="block" color="textSecondary">
                                        {formatCurrency(item.billableUnitPrice, currency)}/{item.unit}
                                    </Typography>
                                </TableCell>
                                <TableActions xs={12} md={2}>
                                    {!!editable && (
                                        <React.Fragment>
                                            <IconButton onClick={() => setFormIndex(key)} disabled={disabled}>
                                                <CreateIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton
                                                onClick={() =>
                                                    Alert.alert(
                                                        "Smazat výdaj",
                                                        `Chcete výdaj ${formatDate(item.date)} ${item.projectName} ${item.description || ""} ?`,
                                                        [{ text: "Ne" }, { text: "Ano", onPress: () => deleteItem(item.id) }]
                                                    )
                                                }
                                                disabled={disabled}>
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </React.Fragment>
                                    )}
                                </TableActions>
                                <AttachmentChips
                                    attachments={item.attachments}
                                    url={(row) => `/api/expenses/${row.objectId}/attachments/${row.id}`}
                                    disabled={disabled}
                                />
                            </TableRow>
                        )
                    )}
            </TableBody>
        </Table>
    );
};

const noop = () => null;

export default ({ showLoader = noop, hideLoader = noop, disabled, employeeId, projectId, showPrice, currency, ...props }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState([]);

    const periodRef = usePeriod();
    const [period] = periodRef;

    const showProject = !!employeeId;
    const showEmployee = !!projectId;

    let fetchExpense, saveExpense, deleteExpense;
    if (showProject) {
        fetchExpense = async (page) => await fetchEmployeeExpense(employeeId, period.start, period.end, null, page);
        saveExpense = async (item) => await saveEmployeeExpense(employeeId, item);
        deleteExpense = async (id) => await deleteEmployeeExpense(employeeId, id);
    } else {
        fetchExpense = async (page) => await fetchProjectExpense(projectId, period.start, period.end, null, page);
        saveExpense = async (item) => await saveProjectExpense(projectId, item);
        deleteExpense = async (id) => await deleteProjectExpense(projectId, id);
    }

    const load = async (page) => {
        showLoader();
        try {
            let list = await fetchExpense(page);
            setData(list);
        } catch (e) {
            setData([]);
            console.log(e);
            enqueueSnackbar("Došlo k chybě při načítání dat", { variant: "error" });
        }
        hideLoader();
    };

    const saveItem = async (values) => {
        showLoader();
        try {
            await saveExpense(values);
            load();
        } catch (e) {
            enqueueSnackbar("Došlo k chybě při ukládání dat", { variant: "error" });
        }
        hideLoader();
    };

    const deleteItem = async (id) => {
        showLoader();
        try {
            await deleteExpense(id);
            load();
        } catch (e) {
            enqueueSnackbar("Došlo k chybě při mazání dat", { variant: "error" });
        }
        hideLoader();
    };

    let component;
    if (showProject) {
        component = (
            <EmployeeExpenseTable
                data={(data && data.content) || []}
                disabled={disabled}
                employeeId={employeeId}
                onSaveItem={saveItem}
                onDeleteItem={deleteItem}
                showPrice={showPrice}
                currency={currency}
            />
        );
    } else {
        component = (
            <ProjectExpenseTable
                data={(data && data.content) || []}
                disabled={disabled}
                projectId={projectId}
                onSaveItem={saveItem}
                onDeleteItem={deleteItem}
                showPrice={showPrice}
                currency={currency}
            />
        );
    }

    useEffect(() => {
        load();
    }, [period]);

    return (
        <React.Fragment>
            <PeriodSelector py={3} periodRef={periodRef} disabled={disabled} />
            {component}
            <TablePagination pagingInfo={data && data.pagingInfo} onChangePage={load} disabled={disabled} />
        </React.Fragment>
    );
};

export const ExpenseTemplatesTable = ({ data = [], currency, ...props }) => {
    return (
        <Box {...props}>
            <TableHeader>
                <TableCell xs={6}>Náklad</TableCell>
                <TableCell xs={3} right>
                    Počet
                </TableCell>
                <TableCell xs={3} right>
                    Celkem
                </TableCell>
            </TableHeader>
            <TableBody>
                {Array.isArray(data) &&
                    data.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell xs={6}>{row.name}</TableCell>
                            <TableCell xs={3} right>
                                {formatNum(row.value || 0)} {row.unit}
                            </TableCell>
                            <TableCell xs={3} right>
                                {formatCurrency(row.amount, currency)}
                            </TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Box>
    );
};

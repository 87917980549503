import React from "react";

import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import HomeIcon from "@material-ui/icons/Home";

export default {
    settings: <SettingsIcon />,
    logout: <ExitToAppIcon />,

    home: <HomeIcon />,
};
